import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import { columnsNFT } from '../../component/common/table/TableColumns/nft';
import { NftContent } from '../../component/nft/NftContent';
import { nftGetAllAction } from '../../redux/action/nft';

export const NFTContainer = () => {
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();

  const [pageSizeCurrent, setCurrentPageSize] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [filterValue, setFilterValue] = useState(null);
  const isNftsGetPending = useSelector(state => state.nft.isNftsGetPending);
  const isNftCreatePending = useSelector(state => state.nft.isNftCreatePending);
  const isNftUpdatePending = useSelector(state => state.nft.isNftUpdatePending);

  const nftData = useSelector(state => state.nft.nfts);

  const fetchdata = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(nftGetAllAction(pageIndex, pageSize, filterValue));
      setCurrentPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    setPageCount(Math.ceil(nftData?.total_items / pageSizeCurrent));
  }, [nftData, pageSizeCurrent]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilterValue(filter);
  }, [searchParam]);
  return (
    <div className="">
      <div className="">
        <HeaderTitle pageTitle={'NFTs'} />
        <NftContent
          nftData={nftData}
          columnData={columnsNFT}
          fetchData={fetchdata}
          pageCount={pageCount}
          filterValue={filterValue}
        />
      </div>
      <RingSpinner
        isLoading={isNftsGetPending || isNftCreatePending || isNftUpdatePending}
      />
    </div>
  );
};
