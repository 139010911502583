import { apolloClient } from '../../../service/apolloClient';
import { reduxAction } from '../base';
import {
  TOURNAMENTS_INFO,
  TOURNAMENTS_PENDING,
  TOURNAMENT_CREATE_PENDING,
  TOURNAMENT_CREATE_INFO,
  SINGLE_TOURNAMENT_INFO,
  SINGLE_TOURNAMENT_INFO_PENDING,
  TOURNAMENT_UPDATE,
  TOURNAMENT_UPDATE_PENDING,
  CLEAR_SINGLE_TOURNAMENT,
  TOURNAMENT_GAMES_PENDING,
  TOURNAMENT_GAMES,
  USER_TO_TOURNAMENT,
  USER_TO_TOURNAMENT_PENDING,
  EXPORT_USER_TO_TOURNAMENT_GQL_PENDING,
} from './type';
import {
  GET_ALL_TOURNAMENTS,
  ADD_TOURNAMENT,
  GET_SINGLE_TOURNAMENT,
  UPDATE_TOURNAMENT,
  GET_USER_TO_TOURNAMENTS_GQL,
  GET_GAME_GQL,
  EXPORT_USER_TO_TOURNAMENT_GQL,
} from '../../query/tournaments';
import { toastAction } from '../toastAction';

export const tournamentGetGameAction = () => {
  return dispatch => {
    dispatch(reduxAction(TOURNAMENT_GAMES_PENDING, true));
    apolloClient
      .query({ query: GET_GAME_GQL })
      .then(({ data }) => {
        const { getGames: res } = data;
        dispatch(reduxAction(TOURNAMENT_GAMES, res));
        dispatch(reduxAction(TOURNAMENT_GAMES_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(TOURNAMENT_GAMES_PENDING, false));
      });
  };
};

export const userToTournamentGetAction = (page, limit, value) => {
  return dispatch => {
    dispatch(reduxAction(USER_TO_TOURNAMENT_PENDING, true));
    apolloClient
      .query({
        query: GET_USER_TO_TOURNAMENTS_GQL,
        variables: { page, limit, input: value },
      })
      .then(({ data }) => {
        const { getUserToTournaments: res } = data;

        dispatch(reduxAction(USER_TO_TOURNAMENT, res));
        dispatch(reduxAction(USER_TO_TOURNAMENT_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(USER_TO_TOURNAMENT_PENDING, false));
      });
  };
};

export const tournamentGetAction = (page, limit, value) => {
  return dispatch => {
    dispatch(reduxAction(TOURNAMENTS_PENDING, true));
    apolloClient
      .query({
        query: GET_ALL_TOURNAMENTS,
        variables: { page, limit, input: value },
      })
      .then(({ data }) => {
        const { getTournaments: res } = data;
        dispatch(reduxAction(TOURNAMENTS_INFO, res));
        dispatch(reduxAction(TOURNAMENTS_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(TOURNAMENTS_PENDING, false));
      });
  };
};

export const singleTournamentGetAction = tournament_id => {
  return dispatch => {
    dispatch(reduxAction(SINGLE_TOURNAMENT_INFO_PENDING, true));
    apolloClient
      .query({
        query: GET_SINGLE_TOURNAMENT,
        variables: { id: tournament_id },
      })
      .then(({ data }) => {
        const { getTournament: res } = data;
        dispatch(reduxAction(SINGLE_TOURNAMENT_INFO, res));
        dispatch(reduxAction(SINGLE_TOURNAMENT_INFO_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(SINGLE_TOURNAMENT_INFO, null));

        dispatch(reduxAction(SINGLE_TOURNAMENT_INFO_PENDING, false));
      });
  };
};

export const tournamentUpdateAction = (value, cb) => {
  const { id, values } = value;
  return dispatch => {
    dispatch(reduxAction(TOURNAMENT_UPDATE_PENDING, true));
    apolloClient
      .mutate({
        mutation: UPDATE_TOURNAMENT,
        variables: { id: id, input: values },
      })
      .then(() => {
        dispatch(reduxAction(TOURNAMENT_UPDATE));
        dispatch(reduxAction(TOURNAMENT_UPDATE_PENDING, false));
        if (cb) {
          cb();
        }
        toastAction.success('Tournament updated successfully');
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(TOURNAMENT_UPDATE_PENDING, false));
      });
  };
};

export const tournamentCreateAction = (values, cb) => {
  return dispatch => {
    dispatch(reduxAction(TOURNAMENT_CREATE_PENDING, true));
    apolloClient
      .mutate({ mutation: ADD_TOURNAMENT, variables: { input: values } })
      .then(() => {
        dispatch(reduxAction(TOURNAMENT_CREATE_INFO));
        dispatch(reduxAction(TOURNAMENT_CREATE_PENDING, false));
        if (cb) {
          cb();
        }
        toastAction.success('Tournament created successfully');
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(TOURNAMENT_CREATE_PENDING, false));
      });
  };
};

export const clearTournament = () => {
  return dispatch => {
    dispatch(reduxAction(CLEAR_SINGLE_TOURNAMENT));
  };
};

export const exportUserToTournamentAction = input => {
  const query = EXPORT_USER_TO_TOURNAMENT_GQL;
  return dispatch => {
    dispatch(reduxAction(EXPORT_USER_TO_TOURNAMENT_GQL_PENDING, true));
    apolloClient
      .query({ query, variables: { input } })
      .then(({ data }) => {
        const { exportUserToTournament: res } = data;
        if (res && res.data) {
          const url = `data:image/${res.type};base64,${res.data}`;
          let el = document.createElement('a');
          el.href = url;
          el.download = `UserTournament.${res.type}`;
          el.click();
          el.remove();
        } else {
          toastAction.error('Data not found');
        }
        dispatch(reduxAction(EXPORT_USER_TO_TOURNAMENT_GQL_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(EXPORT_USER_TO_TOURNAMENT_GQL_PENDING, false));
      });
  };
};
