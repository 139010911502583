import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeQueryString } from '../../utils/MakeQueryString';

const schema = yup.object().shape({
  _id: yup.string(),
  status: yup.string(),
  name: yup.string(),
});
const UserPlanFilters = ({ onToggleFilter }) => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uuid: filter?.uuid || undefined,
      name: filter?.name || undefined,
      user: filter?.user || undefined,
      plan: filter?.plan || undefined,
      status: filter?.status || '',
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values.uuid === '') {
        values.uuid = undefined;
      }
      if (values.name === '') {
        values.name = undefined;
      }
      if (values.user === '') {
        values.user = undefined;
      }
      if (values.plan === '') {
        values.plan = undefined;
      }
      if (values.status === '') {
        values.status = undefined;
      }
      navigate({
        search: makeQueryString(values),
      });
      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black px-5">
        <div className="flex justify-between items-center mb-3 mt-5">
          <div className="body-1">UUID :</div>
          <input
            type="text"
            name="uuid"
            placeholder="UUID"
            className="rounded-lg body-1"
            value={formik.values.uuid}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">User :</div>
          <input
            type="text"
            name="user"
            placeholder="User"
            className="rounded-lg body-1"
            value={formik.values.user}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Plan :</div>
          <input
            type="text"
            name="plan"
            placeholder="Plan"
            className="rounded-lg body-1"
            value={formik.values.plan}
            onChange={formik.handleChange}
          />
        </div>

        <br></br>
        <div className="float-right">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2  bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white my-2 ml-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};
UserPlanFilters.propTypes = {
  FilterUser: PropTypes.func,
  onToggleFilter: PropTypes.func,
};
export default UserPlanFilters;
