export const USER_OTP_PENDING = 'USER_OTP_PENDING';
export const USER_OTP_VERIFICATION_PENDING = 'USER_OTP_VERIFICATION_PENDING';

export const USER_INFO = 'USER_INFO';
export const USER_NFT = 'USER_NFT';
export const USER_WALLET = 'USER_WALLET';
export const USER_DISCORD = 'USER_DISCORD';
export const USER_INFO_GET_PENDING = 'USER_INFO_GET_PENDING';

export const USER_REGISTER_STATUS = 'USER_REGISTER_STATUS';

export const USER_REGISTER_PENDING = 'USER_REGISTER_PENDING';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_BOUGHT_EXPERIENCE_INFO = 'USER_BOUGHT_EXPERIENCE_INFO';
export const USER_BOUGHT_EXPERIENCE_GET_PENDING =
  'USER_BOUGHT_EXPERIENCE_GET_PENDING';
export const DEFAULT_PROFILE_PICS = 'DEFAULT_PROFILE_PICS';

export const USER_LOGIN_MODAL = 'USER_LOGIN_MODAL';

export const GET_USER_NFT = 'USER_NFT_GET_PENDING';
export const GET_USER_DISCORD = 'USER_DISCORD_GET_PENDING';
export const GET_USER_WALLET = 'USER_WALLET_GET_PENDING';

export const GET_WALLETS = 'GET_WALLETS';
export const GET_WALLETS_PENDING = 'GET_WALLETS_PENDING';

export const USER_SEND_REWARD_PENDING = 'USER_SEND_REWARD_PENDING';
