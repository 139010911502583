/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AddNftUtilityModal } from '../../../NftUtility/AddNftUtilityModal';
import EditIcon from '../../../../assets/svg/Edit.svg';

export const columnsNftUtility = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Nft Utility Id',
        accessor: '_id',
      },
      {
        Header: 'Creator',
        accessor: 'creator',
      },
      {
        Header: 'Nft',
        accessor: 'nft',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'type',
        accessor: 'type',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const nftUtility = useSelector(state => state.nft.nftAllUtility);
          const [isModal, setIsModal] = useState(false);
          const onToggleModal = () => {
            setIsModal(!isModal);
          };
          return (
            <div className="flex">
              <img
                src={EditIcon}
                alt=""
                onClick={() => setIsModal(true)}
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full mr-1 cursor-pointer"
              />
              {isModal && (
                <AddNftUtilityModal
                  isModal={isModal}
                  onToggleModal={onToggleModal}
                  nftUtilityData={nftUtility}
                  nftUtility={row.original}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
