import { gql } from '@apollo/client';

export const GET_GAME_LEADERBOARD_GQL = gql`
  query getGameLeaderboard(
    $input: GameLeaderboardQueryInput
    $page: Int
    $limit: Int
  ) {
    getGameLeaderboard(input: $input, page: $page, limit: $limit) {
      data {
        _id
        game
        user {
          _id
          name
          username
          thumbnail
          email
        }
        score
      }
      total_items
      page
      limit
    }
  }
`;
