import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardContent from '../../component/Dashboard/DashboardContent';
import { gamesAnalyticsGetAction } from '../../redux/action/gameAnalytics';

const DashboardContainer = () => {
  const dispatch = useDispatch();
  const analytics = useSelector(state => state.gameAnalytics.gamesAnalytics);
  const gameViews = analytics?.find(ele => ele.type === 'GAME_VIEW')?.size || 0;
  const gameOvers = analytics?.find(ele => ele.type === 'GAME_OVER')?.size || 0;
  const ctaClick = analytics?.find(ele => ele.type === 'CTA_CLICK')?.size || 0;
  const users = analytics?.find(ele => ele.type === 'USERS')?.size || 0;

  const onGetGamesAnalytics = val => {
    dispatch(gamesAnalyticsGetAction(val));
  };

  return (
    <DashboardContent
      gameViews={gameViews}
      gameOvers={gameOvers}
      ctaClick={ctaClick}
      users={users}
      onGetGamesAnalytics={onGetGamesAnalytics}
    />
  );
};

export default DashboardContainer;
