import { gql } from '@apollo/client';
import { CORE_CANVAS_ELEMENT_FIELDS } from './canvas';

export const GET_GAMES_GQL = gql`
  query getAllGames($input: GameQueryInput, $page: Int, $limit: Int) {
    getAllGames(input: $input, page: $page, limit: $limit) {
      data {
        name
        _id
        createdAt
        status
        is_private
        url
        type
        game_path
        banner {
          url
          type
        }
        root_game
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_GAME_GQL = gql`
  ${CORE_CANVAS_ELEMENT_FIELDS}
  query getGameById($id: ID!) {
    getGameById(id: $id) {
      _id
      is_active
      banner {
        type
        url
      }
      description
      detail {
        assets
        screens {
          type
          banner {
            type
            url
          }
          btn {
            label
            style
            label_style
            is_visible
          }
          restart_btn {
            label
            style
            label_style
            is_visible
          }
          submit_btn {
            label
            style
            is_visible
          }
          redirect_url
          user_info {
            name
            type
            is_required
            label
            label_style
            placeholder_style
            style
          }
          coupon {
            title
            code
            style
            title_style
            code_style
          }
          thank_you_banner {
            type
            url
          }
          message
          min_score
          max_score
          elements {
            ...CoreCanvasElementFields
          }
        }
        fb_pixel_id
      }
      root_game
      config
      game_path
      name
      category
      position
      type
      tags
      url
      root_game
      is_private
      is_template
      canvas_type
      background_canvas {
        asset_type
        sub_asset_type
        elements {
          ...CoreCanvasElementFields
        }
      }
      author {
        _id
        name
      }
    }
  }
`;
export const GET_GAME_LEADS_GQL = gql`
  query getGameLeads($input: GameLeadsQueryInput!, $page: Int, $limit: Int) {
    getGameLeads(input: $input, page: $page, limit: $limit) {
      data {
        email
        name
        mobile_number
        score
      }
      page
      limit
      total_items
    }
  }
`;

export const EXPORT_GAME_LEADS_GQL = gql`
  query exportGameLeads($input: ExportGameLeadQueryInput) {
    exportGameLeads(input: $input) {
      data
      type
    }
  }
`;

export const UPDATE_GAME_GQL = gql`
  mutation UpdateGame($input: GameUpdateInput!, $id: ID!) {
    updateGame(input: $input, id: $id) {
      _id
    }
  }
`;

export const CREATE_GAME_GQL = gql`
  mutation createGame($input: GameCreateInput!) {
    createGame(input: $input) {
      _id
      name
    }
  }
`;
