/* eslint-disable react/prop-types */
import { useState } from 'react';
import EditIcon from '../../../../assets/svg/Edit.svg';
import MemoryConfigCreateModal from '../../Modal/GameConfigModal/MemoryCreateModal';

export const memoryMatchConfigColumn = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Id',
        accessor: '_id',
      },

      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);

          const onToggleModal = () => {
            setIsModal(!isModal);
          };

          return (
            <div className="">
              <img
                id={row.values._id}
                src={EditIcon}
                alt=""
                onClick={() => onToggleModal()}
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
              />
              {isModal && (
                <MemoryConfigCreateModal
                  isModalOpen={isModal}
                  onToggleModal={onToggleModal}
                  id={row.original._id}
                  actionType="UPDATE"
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
