import React, { useState } from 'react';
import PropType from 'prop-types';
import { AddNftUtilityModal } from './AddNftUtilityModal';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import { Styles } from '../../css/table';
import { columnsNftUtility } from '../common/table/TableColumns/nftUtility';
import TableHeader from '../common/table/TableHeader';
import { TablePagination } from '../common/table/tablePagination';

export const NftUtility = ({
  pageCount,
  nftUtility,
  filterValue,
  fetchData,
}) => {
  const [isModal, setModal] = useState(false);

  const column = React.useMemo(() => columnsNftUtility, []);
  const onToggleModal = () => {
    setModal(!isModal);
  };

  return (
    <div>
      <div className="w-full">
        <HeaderTitle pageTitle={'NFTs Utility'} />

        <div className="flex justify-end mx-20 mt-10"></div>
        <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader FileName={'NftUtility'} modalOpenfunc={onToggleModal} />

          <Styles>
            <TablePagination
              columns={column}
              pageCount={pageCount}
              fetchData={fetchData}
              total_item={nftUtility?.total_items}
              data={nftUtility?.data || []}
              filterValue={filterValue}
              className="rounded"
            />
          </Styles>

          {isModal && (
            <AddNftUtilityModal
              isModal={isModal}
              onToggleModal={onToggleModal}
              nftUtilityData={nftUtility?.data}
            />
          )}
        </div>
      </div>
    </div>
  );
};
NftUtility.propTypes = {
  nftUtility: PropType.object,
  filterValue: PropType.object,
  fetchData: PropType.func,
  pageCount: PropType.number,
};
