import React, { useState } from 'react';
import DeleteIcon from '../../../assets/svg/Delete.svg';
import PropTypes from 'prop-types';

const WinnersContainer = ({ FormikProvider, formik, FieldArray }) => {
  const [teamRank, setTeamRank] = useState(1);
  return (
    <div className="w-full flex justify-between mt-4 pt-4">
      <div className="w-full mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Winners</div>
        </div>
        <FormikProvider value={formik}>
          <FieldArray name="winners">
            {({ push, remove }) => (
              <div>
                {formik.values.winners &&
                  formik.values.winners.length > 0 &&
                  formik.values.winners.map((_, idx) => (
                    <div
                      className="mt-4 mb-4 w-full flex items-center  justify-between"
                      key={idx}
                    >
                      <div className=" w-[95%]">
                        <div className="w-full flex justify-between items-center gap-2">
                          <div className="w-1/2">
                            <label
                              htmlFor="winner-thumbnail-url"
                              className="my-1"
                            >
                              Thumbnail URL
                            </label>
                            <input
                              type="text"
                              id="winner-thumbnail-url"
                              placeholder="Thumbnail URL"
                              name={`winners.${idx}.thumbnail.url`}
                              value={
                                formik.values.winners[idx]?.thumbnail?.url || ''
                              }
                              onChange={e => {
                                formik.setFieldValue(
                                  `winners.${idx}.thumbnail.url`,
                                  e.target.value
                                );
                              }}
                              className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                            />
                          </div>
                          <div className="w-1/2">
                            <label htmlFor="winner-currency" className="my-1">
                              Type
                            </label>
                            <select
                              onChange={e => {
                                formik.setFieldValue(
                                  `winners.${idx}.thumbnail.type`,
                                  e.target.value
                                );
                              }}
                              value={
                                formik.values.winners[idx]?.thumbnail?.type ||
                                'defaultOpt'
                              }
                              name={`winners.${idx}.reward.type`}
                              id="winner-currency"
                              className="w-full rounded-lg border-[#9FA2B4] border-2 text-[#9fa2b4]"
                            >
                              <option value="defaultOpt">
                                Select Thumbnail Type
                              </option>
                              <option value="IMAGE" name="IMAGE">
                                IMAGE
                              </option>
                              <option value="VIDEO" name="VIDEO">
                                VIDEO
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="w-full flex justify-between items-center my-3 gap-2">
                          <div className="w-1/2">
                            <label htmlFor="winner-team-name" className="my-1">
                              Team Name
                            </label>
                            <input
                              type="text"
                              id="winner-team-name"
                              placeholder="Team Name"
                              name={`winners.${idx}.team_name`}
                              value={
                                formik.values.winners[idx]?.team_name || ''
                              }
                              onChange={e => {
                                formik.setFieldValue(
                                  `winners.${idx}.team_name`,
                                  e.target.value
                                );
                              }}
                              className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                            />
                          </div>
                          <div className="w-1/2">
                            <label className="my-1" htmlFor="winner-team-rank">
                              Team Rank
                            </label>
                            <input
                              type="text"
                              placeholder="Rank"
                              id="winner-team-rank"
                              name={`winners.${idx}.rank`}
                              value={formik.values.winners[idx]?.rank || ''}
                              onChange={e => {
                                formik.setFieldValue(
                                  `winners.${idx}.rank`,
                                  e.target.value
                                );
                              }}
                              className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="w-full flex items-center gap-2">
                            <div className="w-full">
                              <label
                                htmlFor="winner-team-amount"
                                className="my-1"
                              >
                                Amount
                              </label>
                              <input
                                type="number"
                                placeholder="Amount"
                                name={`winners.${idx}.reward.amount`}
                                value={
                                  formik.values.winners[idx]?.reward?.amount ||
                                  ''
                                }
                                onChange={e => {
                                  formik.setFieldValue(
                                    `winners.${idx}.reward.amount`,
                                    parseInt(e.target.value)
                                  );
                                }}
                                className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                              />
                            </div>
                            <div className="w-full">
                              <label htmlFor="winner-team-currency-type">
                                Currency Type
                              </label>
                              <select
                                onChange={e => {
                                  formik.setFieldValue(
                                    `winners.${idx}.reward.currency`,
                                    e.target.value
                                  );
                                }}
                                value={
                                  formik.values.winners[idx]?.reward
                                    ?.currency || 'defaultOpt'
                                }
                                name={`winners.${idx}.reward.currency`}
                                className="w-full rounded-lg border-[#9FA2B4] border-2 text-[#9fa2b4]"
                                id="winner-team-currency-type"
                              >
                                <option value="INR" name="INR" selected>
                                  INR
                                </option>
                                <option value="USDC" name="USDC">
                                  USDC
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-[4%]" onClick={() => remove(idx)}>
                        <img
                          src={DeleteIcon}
                          alt=""
                          className="w-fit bg-[#F4F5FF] hover:bg-[#dadbe6] rounded-full p-3 ml-2 cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
                <div className="w-fit ml-auto">
                  <button
                    type="button"
                    onClick={() => {
                      setTeamRank(teamRank + 1);
                      push({
                        team_name: '',
                        rank: `${teamRank}`,
                        reward: {
                          currency: 'INR',
                          amount: 0,
                        },
                      });
                    }}
                    className="ml-auto w-full rounded-lg bg-[#D9D9D9] text-[#9fa2b4] px-3 py-2"
                  >
                    ADD
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
        </FormikProvider>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
    </div>
  );
};

WinnersContainer.propTypes = {
  FormikProvider: PropTypes.object,
  formik: PropTypes.object,
  FieldArray: PropTypes.func,
};

export default WinnersContainer;
