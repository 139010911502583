import {
  LEADERBOARD_INFO,
  GET_LEADERBOARD_PENDING,
} from '../action/leaderboard/type';

const initialPendingState = {
  isLeaderboardGetPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  leaderboards: null,
};

export const leaderboardReducer = (
  state = DEFAULT_STATE,
  { type, payload }
) => {
  switch (type) {
    case LEADERBOARD_INFO:
      return {
        ...state,
        leaderboards: payload,
      };
    case GET_LEADERBOARD_PENDING:
      return {
        ...state,
        isLeaderboardGetPending: payload,
      };
    default: {
      return state;
    }
  }
};
