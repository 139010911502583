import { reduxAction } from '../base';
import { apolloClient } from '../../../service/apolloClient';
import { GET_DISCORD_ROLES } from '../../query/discord_role';
import { DISCORD_ROLES, ROLES_GET_PENDING } from './type';

export const getDiscordRoles = () => {
  return dispatch => {
    dispatch(reduxAction(ROLES_GET_PENDING, true));
    apolloClient
      .query({ query: GET_DISCORD_ROLES })
      .then(({ data }) => {
        const { getDiscordRoles: res } = data;
        dispatch(reduxAction(DISCORD_ROLES, res));
        dispatch(reduxAction(ROLES_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(ROLES_GET_PENDING, false));
      });
  };
};
