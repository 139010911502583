import React from 'react';
import PropType from 'prop-types';
const DashboardCard = ({ image, title, amount, className }) => {
  return (
    <div className={`w-[90%] mx-2 ${className}`}>
      <div className="flex w-full h-full border-[1px] border-slate-400 rounded-md pt-4">
        <div className="w-[30%] h-full flex justify-center items-start">
          <div className="w-16 h-16 rounded-full bg-black flex justify-center items-center">
            <img src={image} alt="icon" className="w-[60%] h-[60%]" />
          </div>
        </div>
        <div className="px-2">
          <div className="body-1">{title}</div>
          <div className="h4-thin font-bold">{amount}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
DashboardCard.propTypes = {
  image: PropType.string,
  title: PropType.string,
  amount: PropType.number,
  className: PropType.string,
};
