import React, { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { leaderboardModalStyle } from '../../../../css/ModalCss/ModalCss';
import { getLeaderboard } from '../../../../redux/action/leaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from '../../../../css/table';
import { TablePagination } from '../../table/tablePagination';
import { LeaderboardColumn } from '../../table/TableColumns/leaderboard';
import { useCallback } from 'react';
import TableHeader from '../../table/TableHeader';
import { CrossIcon } from '../../svg/Icons';
import { exportUserToTournamentAction } from '../../../../redux/action/tournament';

const LeaderboardModal = ({ tournament, isModalOpen, onToggleModal }) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [filterValue, setFilter] = useState({ tournament: null });

  const dispatch = useDispatch();
  const leaderboard = useSelector(state => state.leaderboard.leaderboards);

  const exportUserToTournament = () => {
    dispatch(exportUserToTournamentAction({ tournament: tournament?._id }));
  };
  useEffect(() => {
    if (tournament) {
      setFilter({ tournament: tournament?._id });
    }
  }, [tournament]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.tournament) {
        dispatch(getLeaderboard(filterValue, pageIndex, pageSize));
        setPageSize(pageSize);
      }
    },
    [filterValue]
  );

  useEffect(() => {
    if (leaderboard) {
      setPageCount(Math.ceil(leaderboard?.total_items / pageSize1));
    }
  }, [leaderboard, pageSize1]);

  return (
    <ReactModal
      isOpen={isModalOpen}
      ariaHideApp={false}
      overlayClassName="f-modal-overlay"
      shouldCloseOnOverlayClick={true}
      style={leaderboardModalStyle}
      className=""
    >
      <div className="relative w-full h-full text-black">
        <div className="flex justify-end cursor-pointer p-2 ">
          <div onClick={onToggleModal}>
            <CrossIcon />
          </div>
        </div>
        <div className="p-6">
          <TableHeader
            FileName={`${tournament?.name} - ${tournament?._id}`}
            addButtonEnable={false}
          />
          <Styles>
            <TablePagination
              columns={LeaderboardColumn}
              data={leaderboard?.data || []}
              fetchData={fetchData}
              pageCount={pageCount}
              total_item={leaderboard?.total_item}
              className="rounded py-6"
            />
          </Styles>
        </div>
        <div className="flex justify-end">
          <div
            className="mx-2 p-3 bg-green-600 rounded-md body-2 text-white cursor-pointer"
            onClick={() => exportUserToTournament()}
          >
            Export
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

LeaderboardModal.propTypes = {
  tournament: PropTypes.object,
  isModalOpen: PropTypes.bool,
  onToggleModal: PropTypes.func,
};
export default LeaderboardModal;
