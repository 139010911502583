import { userProfileRoute } from '../../../../route/navigate';

/* eslint-disable react/prop-types */
export const columnsNftFanClub = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'FanClub Id',
        accessor: '_id',
      },
      {
        Header: 'User Id',
        accessor: 'user',
        Cell: ({ row }) => (
          <div
            className="text-[#11B954] cursor-pointer"
            onClick={() =>
              window.open(userProfileRoute(row.values.user._id), '_blank')
            }
          >
            {row.values.user._id}
          </div>
        ),
      },
      {
        Header: 'Username',
        accessor: 'user.username',
      },
      {
        Header: 'Email',
        accessor: 'user.email',
      },
      {
        Header: 'Mobile Number',
        accessor: 'user.mobile_number',
      },
    ],
  },
];
