import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { inlineStyle } from '../../css/ModalCss/ModalCss';
import { CrossIcon } from '../common/svg/Icons';
import NftThumbnail from '../common/Media/NftThumbnail';
const NftShowModal = ({ isModal, onToggleModal, nft }) => {
  return (
    <div>
      <Modal
        isOpen={isModal}
        onRequestClose={onToggleModal}
        style={inlineStyle}
        shouldCloseOnOverlayClick={true}
        className="!w-[50%] bg-white !h-auto"
      >
        <div className="relative w-full text-black py-6">
          <div
            className="absolute right-6 cursor-pointer p-2 "
            onClick={onToggleModal}
          >
            <CrossIcon />
          </div>
          {nft && (
            <div className="flex flex-col mx-10 my-6">
              <div className="w-full flex justify-center items-center h5-thin  p-2">
                NFT Details
              </div>
              <div className="grid grid-cols-12 my-4">
                <div className=" col-span-6 rounded-t-3xl">
                  <NftThumbnail
                    media={nft.media}
                    thumbnailStyle="rounded-lg w-[80%]"
                  />
                </div>
                <div className="col-span-6">
                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 "> Title: </div>
                    <div className="body-1 text-gray-700   mx-2">
                      {nft.title}
                    </div>
                  </div>
                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 ">ID: </div>
                    <div className="body-1 text-gray-700   mx-2">
                      {' '}
                      {nft._id}
                    </div>
                  </div>
                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 "> Amount: </div>
                    <div className="body-1 text-gray-700   mx-2">
                      {nft.price?.amount}
                    </div>
                  </div>
                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 ">
                      {' '}
                      Total Editions :
                    </div>
                    <div className="body-1 text-gray-700   mx-2">
                      {nft.total_editions}
                    </div>
                  </div>
                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 ">
                      {' '}
                      Sold Editions:{' '}
                    </div>
                    <div className="body-1 text-gray-700    mx-2">
                      {nft.sold_editions}
                    </div>
                  </div>
                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 ">Creator Id: </div>
                    <div className="body-1 text-gray-700    mx-2">
                      {' '}
                      {nft.creator}
                    </div>
                  </div>

                  <div className="flex w-full my-2">
                    <div className="body-1 text-gray-700 "> Status :</div>
                    <div className="body-1 text-gray-700    mx-2">
                      {nft.status}
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-2">Description</div>
              <div className="p-2 bg-slate-300 rounded-sm body-1 text-gray-700 ">
                {nft.description}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default NftShowModal;

NftShowModal.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
  nft: PropTypes.object,
};
