import {
  ALL_TRANSACTIONS,
  CREATE_TRANSACTION_PENDING,
  UPDATE_TRANSACTION_PENDING,
  USER_TRANSACTION_GET_PENDING,
} from '../action/transaction/type';

const initialPendingState = {
  isTransactionPending: false,
  isUserTransactionGetPending: false,
  isUpdateTransactionPending: false,
  isCreateTransactionPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  paymentLink: null,
  transactionDetails: null,
  allTransactions: null,
};

export const transactionReducer = (
  state = DEFAULT_STATE,
  { type, payload }
) => {
  switch (type) {
    case ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: payload,
      };
    case USER_TRANSACTION_GET_PENDING:
      return {
        ...state,
        isUserTransactionGetPending: payload,
      };
    case CREATE_TRANSACTION_PENDING:
      return {
        ...state,
        isCreateTransactionPending: payload,
      };
    case UPDATE_TRANSACTION_PENDING:
      return {
        ...state,
        isUpdateTransactionPending: payload,
      };
    default: {
      return state;
    }
  }
};
