import React from 'react';
import PropTypes from 'prop-types';

const LevelsSlotContainer = ({ formik, formikErrors }) => {
  return (
    <div className="w-full flex justify-between mt-4 pt-4">
      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Levels</div>
        </div>
        <input
          type="number"
          name="levels"
          value={formik.values.levels}
          onChange={formik.handleChange}
          placeholder="Enter Levels"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="levels"
        />
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Slots</div>
        </div>
        <input
          type="number"
          name="slots"
          value={formik.values.slots}
          onChange={formik.handleChange}
          placeholder="Enter Slots"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="slots"
        />
        <p className="text-sm text-[#FF0000] capitalize my-1">
          {formikErrors.slots}
        </p>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Filled Slot</div>
        </div>
        <input
          type="number"
          name="filled_slot"
          value={formik.values.filled_slot}
          onChange={formik.handleChange}
          placeholder="Enter Filled Slot"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="slots"
        />
        <p className="text-sm text-[#FF0000] capitalize my-1">
          {formikErrors.filled_slot}
        </p>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
    </div>
  );
};

LevelsSlotContainer.propTypes = {
  formik: PropTypes.object,
  formikErrors: PropTypes.object,
};

export default LevelsSlotContainer;
