import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usersGetAction } from '../../redux/action/users';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import UserGames from './UserGames';

const UserDetail = () => {
  let { userId } = useParams();

  const userDetails = useSelector(state => state.users.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersGetAction({ _id: userId }, 0, 10));
  }, [userId]);
  return (
    <div className="px-0">
      <HeaderTitle pageTitle={`User: ${userId}`} />
      <div className="w-full flex justify-evenly items-center mt-4">
        <div className=" text-start body-2">
          <span className="text-[#a0a2b4] body-2 text-center">Username:</span>{' '}
          {userDetails && userDetails.data[0]?.username}
        </div>
        <div className="text-start body-2">
          <span className="text-[#a0a2b4] body-2 text-center">Email: </span>
          {userDetails && userDetails.data[0]?.email}
        </div>
        <div className="text-start body-2">
          <span className="text-[#a0a2b4] body-2 text-center">
            Mobile Number:{' '}
          </span>
          {userDetails && userDetails.data[0]?.mobile_number}
        </div>
        <div className="text-start body-2">
          <span className="text-[#a0a2b4] body-2 text-center">Plan: </span>
          {userDetails && userDetails.data[0]?.plan}
        </div>
      </div>

      <div>
        <UserGames userId={userId} />
      </div>
    </div>
  );
};

export default UserDetail;
