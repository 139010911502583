import React from 'react';

import PropType from 'prop-types';
import { useSelector } from 'react-redux';

export const HeaderTitle = ({ pageTitle }) => {
  const user = useSelector(state => state.user.user);
  return (
    <div className="flex justify-between items-center p-2 px-4 w-full">
      <h1 className="h5-thin">{pageTitle ? pageTitle : 'Page title'}</h1>
      <div className="flex items-center">
        <div className="border-l-2 border-[#d7dbf8] w-2 h-10"></div>
        <div className="flex items-center ml-5">
          <p className="body-2">{user && user.username}</p>
          <img
            src={user && user.thumbnail}
            alt=""
            className="ml-5 mr-10  rounded-full border-2 border-[#d7dbf8] p-1 cursor-pointer h-10 w-10"
          />
        </div>
      </div>
    </div>
  );
};

HeaderTitle.propTypes = {
  pageTitle: PropType.string,
};
