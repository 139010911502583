import {
  EXPORT_GAME_LEADS_PENDING,
  GAMES,
  GAME_LEADS,
  GET_GAMES_PENDING,
  GET_GAME_LEAD_PENDING,
  UPDATE_GAME_PENDING,
} from '../action/game/type';

const initialPendingState = {
  isGamesGetPending: false,
  isGameLeadsGetPending: false,
  isGameLeadsExportPending: false,
  isGamesUpdatePending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  games: null,
  gameLeads: null,
};

export const gameReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case GAMES:
      return {
        ...state,
        games: payload,
      };
    case GAME_LEADS:
      return {
        ...state,
        gameLeads: payload,
      };
    case GET_GAMES_PENDING:
      return {
        ...state,
        isGamesGetPending: payload,
      };
    case UPDATE_GAME_PENDING:
      return {
        ...state,
        isGamesUpdatePending: payload,
      };
    case GET_GAME_LEAD_PENDING:
      return {
        ...state,
        isGameLeadsGetPending: payload,
      };
    case EXPORT_GAME_LEADS_PENDING:
      return {
        ...state,
        isGameLeadsExportPending: payload,
      };
    default: {
      return state;
    }
  }
};
