import React from 'react';
import PropTypes from 'prop-types';
import { Styles } from '../../css/table';
import { TablePagination } from '../common/table/tablePagination';
import { gameColumn } from '../common/table/TableColumns/game';

const GamesTable = ({ games, pageCount, fetchData }) => {
  return (
    <div className="w-full h-full">
      <Styles>
        <TablePagination
          columns={gameColumn}
          data={games?.data || []}
          total_item={games?.total_items}
          fetchData={fetchData}
          pageCount={pageCount}
          className="rounded"
        />
      </Styles>
    </div>
  );
};
GamesTable.propTypes = {
  games: PropTypes.object,

  onPageLimitChange: PropTypes.func,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
};
export default GamesTable;
