import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToogleComponent from '../../common/Toggle/Toggle';

const EntryFeesGameContainer = ({ formik, formikErrors }) => {
  const [isEntryFees, setIsEntryFees] = useState(false);
  const onEntryFeesToggle = () => {
    let temp = isEntryFees;
    setIsEntryFees(!temp);
    let obj = undefined;
    if (temp) {
      obj = {
        amount: undefined,
        currency: undefined,
      };
    }
    formik.setFieldValue('entry_fees', obj);
  };
  return (
    <div className="w-full flex justify-between mt-4 mb-4 pt-4">
      <div className="w-1/2 mt-2 mx-4">
        <div className="flex gap-6">
          <div className="text-slate-500 h7-thin">Entry Fees</div>
          <ToogleComponent label="" onChange={onEntryFeesToggle} />
        </div>
        {isEntryFees && (
          <div className="flex items-center gap-1">
            <input
              type="number"
              name="entry_fees.amount"
              value={formik.values.entry_fees?.amount}
              onChange={formik.handleChange}
              placeholder="Enter Entry Fees"
              className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
              id="entry_fees"
            />
            <select
              type="select"
              name="entry_fees.currency"
              defaultValue={formik.values.entry_fees?.currency}
              value={formik.values.entry_fees?.currency || 'defaultOpt'}
              onChange={formik.handleChange}
              placeholder="Enter Amount"
              className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
              id="entry_fees"
            >
              <option value="">Select Currency</option>

              <option value="INR" name="INR">
                INR
              </option>
              <option value="USDC" name="USDC">
                USDC
              </option>
            </select>
          </div>
        )}
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
      <div className="w-1/2 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Game Status</div>
        </div>
        <select
          type="select"
          name="status"
          defaultValue={formik.values.status}
          value={formik.values.status || 'defaultOpt'}
          onChange={formik.handleChange}
          placeholder="Enter Game Status"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="game_status"
        >
          <option value="defaultOpt">Select Game Status</option>
          <option value="UPCOMING" name="UPCOMING">
            UPCOMING
          </option>
          <option value="COMPLETED" name="COMPLETED">
            COMPLETED
          </option>
          <option value="PAUSED" name="PAUSED">
            PAUSED
          </option>
          <option value="START" name="START">
            START
          </option>
        </select>
        <p className="text-sm text-[#FF0000] capitalize my-1">
          {formikErrors.status}
        </p>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
    </div>
  );
};

EntryFeesGameContainer.propTypes = {
  formik: PropTypes.object,
  formikErrors: PropTypes.object,
};

export default EntryFeesGameContainer;
