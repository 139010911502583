import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';
import DiscordContent from '../../component/discord/DiscordContent';
import { getDiscordRoles } from '../../redux/action/discord';

const DiscordContainer = () => {
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [filterValue, setFilter] = useState(null);

  const discordRoles = useSelector(state => state.discord.discord_roles);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(getDiscordRoles(pageIndex, pageSize, filterValue));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    setPageCount(Math.ceil(discordRoles?.total_items / pageSize1));
  }, [discordRoles, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilter(filter);
  }, [searchParam]);

  return (
    <div>
      <HeaderTitle pageTitle={'Discord roles'} />
      <DiscordContent
        discordRoles={discordRoles}
        pageCount={pageCount}
        fetchData={fetchData}
      />
    </div>
  );
};

export default DiscordContainer;
