import { gql } from '@apollo/client';

export const GET_PLANS_GQL = gql`
  query getPlans($input: PlansInputQuery, $page: Int, $limit: Int) {
    getPlans(input: $input, page: $page, limit: $limit) {
      data {
        _id
        name
        description
        price {
          currency
          amount
        }
        features {
          name
          type
        }
        validity
        createdAt
      }
      total_items
      page
      limit
    }
  }
`;
export const GET_PLAN_BY_ID_GQL = gql`
  query getPlan($id: ID!) {
    getPlan(id: $id) {
      _id
      name
      description
      price {
        currency
        amount
      }
      features {
        name
        description
        type
        limit
      }
      validity
    }
  }
`;
export const UPDATE_PLAN_GQL = gql`
  mutation updatePlan($id: ID!, $input: PlanUpdateInput!) {
    updatePlan(id: $id, input: $input) {
      _id
    }
  }
`;

export const CREATE_PLAN_GQL = gql`
  mutation createPlan($input: PlanCreateInput) {
    createPlan(input: $input) {
      _id
      name
    }
  }
`;
