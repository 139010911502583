import React from 'react';
import RingLoader from 'react-spinners/RingLoader';
import PropTypes from 'prop-types';

const RingSpinner = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="z-[100] fixed translate-x-[-50%]  top-0 left-2/4 m-auto bg-col-black-0_7 h-[100vh] w-[100vw] rounded flex justify-center items-center">
          <div className="flex">
            <RingLoader color="#29CC97" loading={isLoading} size={100} />
          </div>
        </div>
      )}
    </>
  );
};

RingSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

export default RingSpinner;
