import { Link } from 'react-router-dom';
import { userProfileRoute } from '../../../../route/navigate';
import usePermission from '../../../../hooks/usePermision';
import { useState } from 'react';
import EditIcon from '../../../../assets/svg/Edit.svg';
import CreateUserModal from '../../Modal/UserModal/CreateUserModal';

/* eslint-disable react/prop-types */

export const columnUser = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'User ID',
        accessor: '_id',
        Cell: ({ row }) => (
          <Link
            to={userProfileRoute(row.values._id)}
            className="text-[#11B954] body-2 cursor-pointer"
            target="_blank"
          >
            {row.values._id}
          </Link>
        ),
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile_number',
      },

      {
        Header: 'Plan',
        accessor: 'plan',
      },
      {
        Header: 'Verified',
        accessor: 'is_verified',
        Cell: ({ row }) => (
          <button
            className={`rounded-xl px-2 py-1 caption ${
              row.values.is_verified ? 'bg-[#3751FF]' : 'bg-[#F12B2C]'
            } text-[#ffffff] uppercase`}
          >
            {row.values.is_verified ? 'Verified' : 'Not Verified'}
          </button>
        ),
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        Cell: ({ row }) => (
          <button
            className={`rounded-xl px-2 py-1 caption ${
              row.values.is_active ? 'bg-[#29CC97]' : 'bg-[#F12B2C]'
            } text-[#ffffff] uppercase`}
          >
            {row.values.is_active ? 'active' : 'inactive'}
          </button>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);
          const [hasAccess] = usePermission();

          const onToggleModal = () => {
            setIsModal(!isModal);
          };

          return (
            <div className="">
              {hasAccess && (
                <>
                  <img
                    id={row.values._id}
                    src={EditIcon}
                    alt=""
                    onClick={() => onToggleModal()}
                    className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
                  />
                  {isModal && (
                    <CreateUserModal
                      isModalOpen={isModal}
                      onToggleModal={onToggleModal}
                      userId={row.original._id}
                      actionType="UPDATE"
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
    ],
  },
];
