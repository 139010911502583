import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import WalletButton from './WalletButton';
const schema = yup.object().shape({
  balance: yup.object({ currency: yup.string(), amount: yup.number() }),
  deposit: yup.object({ currency: yup.string(), amount: yup.number() }),
  earnings: yup.object({ currency: yup.string(), amount: yup.number() }),
  crypto: yup.object({
    balance: yup.object({ currency: yup.string(), amount: yup.number() }),
    earnings: yup.object({ currency: yup.string(), amount: yup.number() }),
  }),
});
const WalletEditForm = ({ onUpdateWallet, wallet }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currency: undefined,
      amount: undefined,
      balance: wallet.balance || undefined,
      earnings: wallet.earnings || undefined,
      crypto: wallet.crypto || undefined,
      deposit: wallet.deposit || undefined,
    },
    validationSchema: schema,
    onSubmit: val => {
      if (val.balance) val.balance.currency = 'INR';
      if (val.earnings) val.earnings.currency = 'INR';
      if (val.deposit) val.deposit.currency = 'INR';
      if (val.crypto?.balance) val.crypto.balance.currency = 'INR';
      if (val.crypto?.earnings) val.crypto.earnings.currency = 'INR';
      delete val.crypto.address;
      onUpdateWallet(val);
    },
  });
  const { errors, touched } = formik;
  return (
    <div>
      <div className="flex justify-evenly">
        <form className="flex flex-col gap-6" onSubmit={formik.handleSubmit}>
          <div className="flex col-span-2">
            <WalletButton
              name="balance.amount"
              title={'Balance'}
              formik={formik}
              touched={touched.balance}
              errors={errors.balance}
              value={formik.values.balance?.amount}
            />
            {/* deposit */}
            <WalletButton
              name="deposit.amount"
              title={'Deposit'}
              formik={formik}
              touched={touched.deposit}
              errors={errors.deposit}
              value={formik.values.deposit?.amount}
            />
          </div>

          <div className="flex col-span-2">
            <WalletButton
              name="earnings.amount"
              title={'Earnings'}
              formik={formik}
              touched={touched.earnings}
              errors={errors.earnings}
              value={formik.values.earnings?.amount}
            />

            {/* crypto balance*/}
            <WalletButton
              name="crypto.balance.amount"
              title={'Crypto balance'}
              formik={formik}
              touched={touched.crypto?.balance}
              errors={errors.crypto?.balance}
              value={formik.values.crypto.balance?.amount}
            />
          </div>
          {/* crypto earnings*/}
          <div className="flex col-span-2">
            <WalletButton
              name="crypto.earnings.amount"
              title={'Crypto earnings'}
              formik={formik}
              touched={touched.earnings?.balance}
              errors={errors.earnings?.balance}
              value={formik.values.crypto.earnings?.amount}
            />
          </div>

          <div className="">
            <button className="px-4 py-2 bg-[#3750FE] text-white rounded w-full btn-text">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WalletEditForm;

WalletEditForm.propTypes = {
  onUpdateWallet: PropTypes.func,
  wallet: PropTypes.object,
};
