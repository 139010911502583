export const assets = [
  {
    _id: '12345',
    type: 'BOOSTER',
    status: 'ACTIVE',
    is_active: true,
    title: '1.5x Earning Boost',
    short_description: '2 Tournaments',
    description:
      'More powerful your avatar higher are the earnings. Get booster to boost power of your avatar & earn 1.5X in tournaments. Valid Upto 2 tournaments.',
    media: {
      url: 'https://rhym.s3.ap-south-1.amazonaws.com/creator/rhym/booster/booster.mp4',
      type: 'VIDEO',
    },
    price: {
      currency: 'INR',
      amount: 100,
    },

    cover: {
      url: 'https://rhym.s3.ap-south-1.amazonaws.com/creator/rhym/booster/booster.png',
      type: 'IMAGE',
    },
  },
];
