import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeQueryString } from '../../utils/MakeQueryString';

const schema = yup.object().shape({
  user: yup.string(),
  transaction: yup.string(),
});
const AssetBuyerFilter = ({ onToggleFilter }) => {
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user: filter?.user || undefined,
      transaction: filter?.transaction || undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values.user === '') {
        values.user = undefined;
      }
      if (values.transaction === '') {
        values.transaction = undefined;
      }
      navigate({
        search: makeQueryString(values),
      });
      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black px-5">
        <div className="flex justify-between items-center mb-3 mt-5">
          <div className="body-1">User ID :</div>
          <input
            type="text"
            name="user"
            placeholder="User ID"
            defaultValue={formik.values.user}
            className="rounded-lg body-1"
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Transaction :</div>
          <input
            type="text"
            name="transaction"
            placeholder="transaction ID"
            defaultValue={formik.values.transaction}
            className="rounded-lg body-1"
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

AssetBuyerFilter.propTypes = {
  filterBuyer: PropTypes.func,
  onToggleFilter: PropTypes.func,
};
export default AssetBuyerFilter;
