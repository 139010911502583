import { reduxAction } from '../base';
import { apolloClient } from '../../../service/apolloClient';
import {
  GET_ALL_TRANSACTION_GQL,
  CREATE_TRANSACTION_GQL,
  UPDATE_TRANSACTION_GQL,
} from '../../query/transaction';
import {
  ALL_TRANSACTIONS,
  CREATE_TRANSACTION_PENDING,
  UPDATE_TRANSACTION_PENDING,
  USER_TRANSACTION_GET_PENDING,
} from './type';
export const getAllTransaction = (page, limit, value) => {
  const query = GET_ALL_TRANSACTION_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_TRANSACTION_GET_PENDING, true));
    apolloClient
      .query({ query, variables: { page, limit, input: value } })
      .then(({ data }) => {
        const { getTransactions: res } = data;

        dispatch(reduxAction(ALL_TRANSACTIONS, res));
        dispatch(reduxAction(USER_TRANSACTION_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(USER_TRANSACTION_GET_PENDING, false));
      });
  };
};

export const createTransaction = value => {
  const mutation = CREATE_TRANSACTION_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_TRANSACTION_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(() => {
        dispatch(reduxAction(CREATE_TRANSACTION_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(CREATE_TRANSACTION_PENDING, false));
      });
  };
};
export const updateTransaction = (id, input, cb) => {
  const mutation = UPDATE_TRANSACTION_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_TRANSACTION_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id, input } })
      .then(({ data }) => {
        if (data) {
          if (cb) {
            cb();
          }
        }
        dispatch(reduxAction(UPDATE_TRANSACTION_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(UPDATE_TRANSACTION_PENDING, false));
      });
  };
};
