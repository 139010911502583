import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeQueryString } from '../../utils/MakeQueryString';
import { TournamentType } from '../../constants/tournament_constant';

const schema = yup.object().shape({
  game: yup.string(),
  status: yup.string(),
  name: yup.string(),
  _id: yup.string(),
});

const TournamentFilter = ({ onToggleFilter }) => {
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);
  const navigate = useNavigate();
  const games = TournamentType;
  const status = ['COMPLETED', 'UPCOMING', 'PAUSED', 'START'];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      game: filter?.game || '',
      status: filter?.status || '',
      name: filter?.name || '',
      _id: filter?._id || '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const filterValues = {
        game: values.game,
        status: values.status,
        name: values.name,
        _id: values._id,
      };
      if (filterValues.game === '') {
        filterValues.game = undefined;
      }
      if (filterValues.status === '') {
        filterValues.status = undefined;
      }
      if (filterValues.name === '') {
        filterValues.name = undefined;
      }
      if (filterValues._id === '') {
        filterValues._id = undefined;
      }
      navigate({
        search: makeQueryString(filterValues),
      });

      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black">
        <div className="flex">
          <select
            type="select"
            name="game"
            defaultValue={formik.values.game}
            placeholder="Game Name"
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="game-name"
          >
            <option name="" value="">
              Select Game
            </option>
            {games.map((value, index) => {
              return (
                <option
                  value={value}
                  id={index}
                  key={index}
                  className="text-[#FE6125] h6-thin-sm"
                >
                  {value}
                </option>
              );
            })}
          </select>

          <select
            type="select"
            name="status"
            placeholder="Tournament Status"
            value={formik.values.id}
            onChange={formik.handleChange}
            className={` w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm`}
            id="tournament-status"
          >
            <option name="" value="">
              Select Status
            </option>
            {status.map((value, index) => {
              return (
                <option
                  value={value}
                  id={index}
                  key={index}
                  className="text-[#FE6125] h6-thin-sm"
                >
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex">
          <input
            type="text"
            name="name"
            value={formik.values.name}
            placeholder="Name"
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="name"
          />

          <input
            type="text"
            name="_id"
            value={formik.values._id}
            placeholder="tournament Id"
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="_id"
          />
        </div>
        <div className="w-full flex justify-end items-end">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

TournamentFilter.propTypes = {
  onToggleFilter: PropTypes.func,
};

export default TournamentFilter;
