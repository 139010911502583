import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { inlineStyle } from '../../../../css/ModalCss/ModalCss';
import { CrossIcon } from '../../svg/Icons';
import { transaction_type } from '../../../../constants/transaction_constants';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getAllTransaction,
  updateTransaction,
} from '../../../../redux/action/transaction';

const TransactionEditModal = ({ isModal, onToggleModal, transactions }) => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);
  const [id, setid] = useState(transactions?._id);

  const onUpdate = () => {
    if ([...searchParam].length === 0) {
      if (userId) {
        dispatch(getAllTransaction(0, 8, { author: { _id: userId } }));
      } else {
        dispatch(getAllTransaction(0, 8));
      }
    } else {
      if (filter.author || userId) {
        filter.author = {
          _id: filter.author || userId,
        };
      }

      dispatch(getAllTransaction(0, 8, filter));
    }
    onToggleModal();
  };

  const formik = useFormik({
    initialValues: {
      nft: transactions?.nft,
      amount: transactions?.amount,
      transaction_type: transactions?.transaction_type,
      description: transactions?.description,
      status: transactions?.status,
    },
    onSubmit: values => {
      dispatch(updateTransaction(id, values, onUpdate));
    },
  });

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={onToggleModal}
      ariaHideApp={false}
      style={inlineStyle}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <div className="relative w-full">
          <div
            className="absolute right-0 cursor-pointer p-2 "
            onClick={onToggleModal}
          >
            <CrossIcon />
          </div>

          <div className="flex justify-center items-center text-black">
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <div className="w-full text-center h5-thin">Edit transaction</div>
              <div className="w-full flex flex-col justify-center items-center mt-10">
                <div className="w-full flex justify-evenly">
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Nft</div>
                    </div>
                    <input
                      type="text"
                      name="nft"
                      defaultValue={formik.values.nft}
                      onChange={formik.handleChange}
                      placeholder="Nft id"
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="nft"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  </div>
                  {/* price */}
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">
                        Transaction ID
                      </div>
                    </div>
                    <input
                      type="text"
                      name="id"
                      readOnly
                      defaultValue={id}
                      onChange={e => setid(e.target.value)}
                      placeholder="Enter transaction id"
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="transaction_id"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  </div>
                </div>
                {/* total_editions */}
                <div className="w-full flex justify-evenly mt-4 pt-4">
                  <div className="w-1/3 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Amount</div>
                    </div>
                    <input
                      type="number"
                      name="amount"
                      defaultValue={formik.values.amount}
                      onChange={formik.handleChange}
                      placeholder="Enter amount"
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="price"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  </div>
                  <div className="w-1/3 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">
                        Transaction type
                      </div>
                    </div>
                    <select
                      type="select"
                      name="transaction_type"
                      defaultValue={formik.values.transaction_type}
                      onChange={formik.handleChange}
                      placeholder="Type Here"
                      className="w-full border-[1px] border-slate-300 text-black rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                      id="transaction_type"
                    >
                      {transaction_type &&
                        transaction_type.map(type => {
                          return (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          );
                        })}
                    </select>
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  </div>
                  {/* Status */}
                  <div className="w-1/3 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">STATUS</div>
                    </div>
                    <select
                      type="select"
                      name="status"
                      defaultValue={formik.values.status}
                      onChange={formik.handleChange}
                      placeholder="Type Here"
                      className="w-full border-[1px] border-slate-300 text-black rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                      id="status"
                    >
                      <option value="PENDING">PENDING</option>
                      <option value="FAILED">FAILED</option>
                      <option value="COMPLETED">COMPLETED</option>
                      <option value="INPROGRESS">INPROGRESS</option>
                    </select>
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  </div>
                </div>
                {/* desc */}
                <div className="w-full flex justify-evenly mt-4 pt-4">
                  <div className="w-full mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Description</div>
                    </div>
                    <textarea
                      type="text"
                      name="description"
                      defaultValue={formik.values.description}
                      onChange={formik.handleChange}
                      placeholder="Type Here"
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4  focus:ring-0 outline-0 body-1 body-1-sm"
                      id="description"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  </div>
                </div>
              </div>
              <div className="m-4 h-auto w-full flex justify-center items-center mt-10 ">
                <div className="mx-40 w-[80%] p-0.5 bg-gradient-to-r from-[#C537AE] via-[#8217DE] to-[#ff5800] rounded-lg cursor-pointer">
                  <button
                    className="bg-blue-400 hover:bg-blue-500 w-full p-2 md:p-3 rounded-lg px-5 md:px-10  btn-text"
                    type="submit"
                  >
                    update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

TransactionEditModal.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
  transactions: PropTypes.object,
};

export default TransactionEditModal;
