import React from 'react';
import PropTypes from 'prop-types';
const WalletButton = ({ name, title, formik, touched, errors, value }) => {
  return (
    <div className="flex flex-col">
      <div>{title}</div>
      <div className="flex">
        <div className="col-span-1 mx-2">
          <label className="caption">Currency</label>
          <div className="">
            <select
              name="balance.currency"
              onChange={formik.handleChange}
              className="rounded"
            >
              <option value="INR">INR</option>
              <option value="USDC">USDC</option>
            </select>
          </div>
          {touched?.currency && errors?.currency && (
            <div className="text-[#F83131] caption">
              {errors.balance.currency}
            </div>
          )}
        </div>
        <div className="col-span-1 w-full mx-2">
          <label className="caption">Amount</label>
          <div>
            <input
              type="number"
              name={name}
              value={value}
              onChange={formik.handleChange}
              placeholder="Enter Amount"
              className="rounded"
            />
            {touched?.amount && errors?.amount && (
              <div className="text-[#F83131] caption">{errors?.amount}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletButton;
WalletButton.propTypes = {
  name: PropTypes.string,
  title: PropTypes.func,
  formik: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  value: PropTypes.number,
};
