import { SOCIALS_INFO, GET_ALL_SOCIALS_PENDING } from '../action/socials/type';

const initialPendingState = {
  isGetAllSocialPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  socials: null,
};

export const socialsReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case SOCIALS_INFO:
      return {
        ...state,
        socials: payload,
      };

    case GET_ALL_SOCIALS_PENDING:
      return {
        ...state,
        isGetAllSocialPending: payload,
      };

    default:
      return state;
  }
};
