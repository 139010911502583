import {
  USER_PLANS,
  GET_USER_PLANS_PENDING,
  USER_PLAN,
  GET_USER_PLAN_PENDING,
  UPDATE_USER_PLAN,
  UPDATE_USER_PLAN_PENDING,
  CREATE_USER_PLAN,
  CREATE_USER_PLAN_PENDING,
} from '../action/userPlan/type';

const initialPendingState = {
  isUserPlansGetPending: false,
  isUserPlanGetPending: false,
  isUserPlaneUpdatePending: false,
  iseUserPlanCreatePending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  userPlans: null,
  userPlan: null,
  updateUserPlan: null,
  createUser: null,
};

export const userPlans = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case USER_PLANS:
      return {
        ...state,
        userPlans: payload,
      };
    case GET_USER_PLANS_PENDING:
      return {
        ...state,
        isUserPlansGetPending: payload,
      };
    case USER_PLAN:
      return {
        ...state,
        userPlan: payload,
      };
    case GET_USER_PLAN_PENDING:
      return {
        ...state,
        isUserPlanGetPending: payload,
      };
    case UPDATE_USER_PLAN:
      return {
        ...state,
        updateUserPlan: payload,
      };
    case UPDATE_USER_PLAN_PENDING:
      return {
        ...state,
        isUserPlanUpdatePending: payload,
      };
    case CREATE_USER_PLAN:
      return {
        ...state,
        createUser: payload,
      };
    case CREATE_USER_PLAN_PENDING:
      return {
        ...state,
        isUserPlanCreatePending: payload,
      };
    default: {
      return state;
    }
  }
};
