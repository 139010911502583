import { gql } from '@apollo/client';

export const GET_CREATORS_GQL = gql`
  query getAllCreators {
    getAllCreators {
      _id
      name
      description
      thumbnail
      banner
      is_active
      social_links {
        type
        link
      }
      createdAt
      type
    }
  }
`;

export const CREATE_NEW_CREATOR_GQL = gql`
  mutation CreateCreator($input: CreatorInput!) {
    createCreator(input: $input) {
      _id
      name
      description
      thumbnail
      banner
      type
      social_links {
        type
        link
      }
    }
  }
`;
export const UPDATE_CREATOR_GQL = gql`
  mutation UpdateCreator($id: ID!, $input: CreatorUpdateInput) {
    updateCreator(id: $id, input: $input) {
      _id
      name
      description
      social_links {
        type
        link
      }
      thumbnail
      is_mask
      banner
      author {
        _id
        name
        thumbnail
      }
      type
      is_active
      createdAt
    }
  }
`;
