import PropTypes from 'prop-types';
import DateFilter from './DateFilter';
import GameEngagement from './Engagement/GameEngagement';
import GameLeadTable from './GameLeads/GameLeadTable';

const GameAnalyticsContent = ({
  gameEngagement,
  gameLeads,
  onGameLeadLoadMore,
  onExportGameLeads,
}) => {
  return (
    <section className="">
      <DateFilter />
      <GameEngagement gameEngagement={gameEngagement} />

      <GameLeadTable
        onGameLeadLoadMore={onGameLeadLoadMore}
        onExportGameLeads={onExportGameLeads}
        leads={gameLeads}
      />
    </section>
  );
};

GameAnalyticsContent.propTypes = {
  gameEngagement: PropTypes.array,
  gameLeads: PropTypes.object,
  onGameLeadLoadMore: PropTypes.func,
  onExportGameLeads: PropTypes.func,
};
export default GameAnalyticsContent;
