import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jigsawPuzzleConfigsGetAction } from '../../../redux/action/gameConfig';
import JigsawPuzzleCreateModal from '../../common/Modal/GameConfigModal/JigsawPuzzleCreateModal';
import TableHeader from '../../common/table/TableHeader';
import JigsawPuzzleFilter from './JigsawPuzzleFilters';
import JigsawPuzzleTable from './JigsawPuzzleTable';
import { useSearchParams } from 'react-router-dom';

const JIgsawPuzzleContent = () => {
  const [filterValue, setFilterValue] = useState(null);
  const [isFilter, setFilter] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [isModal, setIsModal] = useState(false);

  const dispatch = useDispatch();

  const [searchParam] = useSearchParams();

  const { jigsawPuzzleConfigs } = useSelector(state => state.gameConfig);

  const onToggleModal = () => {
    setIsModal(!isModal);
  };
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      dispatch(jigsawPuzzleConfigsGetAction(filterValue, pageIndex, pageSize));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  const onToggleFilter = () => {
    setFilter(!isFilter);
  };
  useEffect(() => {
    if (jigsawPuzzleConfigs)
      setPageCount(Math.ceil(jigsawPuzzleConfigs?.total_items / pageSize1));
  }, [jigsawPuzzleConfigs, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    setFilterValue(filter);
  }, [searchParam]);
  return (
    <div className="">
      <TableHeader
        FileName={'Puzzle'}
        modalOpenfunc={onToggleModal}
        addButtonEnable={true}
        onToggleFilter={onToggleFilter}
      />
      <div
        className={`absolute w-[23rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
          isFilter ? 'block' : 'hidden'
        }`}
      >
        <JigsawPuzzleFilter onToggleFilter={onToggleFilter} />
      </div>
      <JigsawPuzzleTable fetchData={fetchData} pageCount={pageCount} />
      {isModal && (
        <JigsawPuzzleCreateModal
          isModalOpen={isModal}
          onToggleModal={onToggleModal}
        />
      )}
    </div>
  );
};

export default JIgsawPuzzleContent;
