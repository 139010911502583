import React, { useEffect } from 'react';
import { LoginModal } from './LoginModal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RingSpinner from '../common/Spinner/RingSpinner';
import { verifyAdminAction } from '../../redux/action/user';
export const LoginContent = ({ isModal, onToggleModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.user.user);
  const isUserRegistered = useSelector(state => state.user.isUserRegistered);

  const isOtpPending = useSelector(state => state.user.isOtpPending);
  const isOtpVerificationPending = useSelector(
    state => state.user.isOtpVerificationPending
  );

  const onRedirectCreatorRoute = () => {
    navigate('/games');
  };
  const onVerifyAdmin = async data => {
    if (data) {
      await dispatch(verifyAdminAction(data, onRedirectCreatorRoute));
    }
  };

  useEffect(() => {
    if (user !== null && isUserRegistered) navigate('/dashboard');
  }, [user]);

  return (
    <div>
      {isUserRegistered === null && (
        <LoginModal
          isModal={isModal}
          onToggleModal={onToggleModal}
          onVerifyAdmin={onVerifyAdmin}
        />
      )}
      <RingSpinner isLoading={isOtpPending || isOtpVerificationPending} />
    </div>
  );
};

LoginContent.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
};
