import React, { useEffect } from 'react';
import { useTable, useRowSelect, usePagination } from 'react-table';
import PropType from 'prop-types';

export function TablePagination({
  columns,
  data,
  fetchData,
  filterValue,
  pageCount: controlledPageCount,
  total_item,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      autoResetPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,

    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [...columns]);
    }
  );

  useEffect(() => {
    gotoPage(0);
  }, [filterValue, pageSize]);
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [pageIndex, pageSize, fetchData]);

  return (
    <>
      <div>
        <table {...getTableProps()} className="w-full h-full">
          <thead>
            {headerGroups &&
              headerGroups.map((headerGroups, idx) => (
                <tr
                  className="w-full"
                  key={idx}
                  {...headerGroups.getHeaderGroupProps()}
                >
                  {idx != 0 && (
                    <th
                      className={`
                    text-[#a0a2b4] body-2 text-center
                  `}
                    >
                      S No.
                    </th>
                  )}
                  {headerGroups?.headers?.map((column, idx) => (
                    <th
                      key={idx}
                      {...column.getHeaderProps()}
                      className={`
                    text-[#a0a2b4] body-2 text-center
                  `}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()} className="relative overflow-y">
            {rows &&
              rows.map((row, idx) => {
                prepareRow(row);
                return (
                  <tr
                    key={idx}
                    {...row.getRowProps()}
                    className="border-t-2 border-b-2 w-full text-center"
                  >
                    <td className={'text-start body-2'}>
                      {pageIndex * pageSize + (idx + 1)}
                    </td>
                    {row.cells.map((cell, idx) => {
                      return (
                        <td
                          key={idx}
                          {...cell.getCellProps()}
                          className={`
                        text-start
                        ${cell.column.id === 'status' && 'text-center'}
                        body-2
                      `}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex flex-col p-0  justify-end">
          <div className="flex justify-end items-start">
            Showing <span className="text-gray-600 mx-2">{rows.length}</span> of{' '}
            {total_item} results
          </div>

          <div className="pagination flex justify-center items-center">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {controlledPageCount}
              </strong>{' '}
            </span>
            <span className="mx-2">
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                className="rounded-md mr-3"
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              className="rounded-md"
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

TablePagination.propTypes = {
  columns: PropType.array,
  data: PropType.array,
  modalOpenfunc: PropType.func,
  creatorSetFunc: PropType.func,
  fetchData: PropType.func,
  filterValue: PropType.object,
  pageCount: PropType.number,
  total_item: PropType.number,
};
