import React, { useEffect } from 'react';
import { LoginContent } from '../../component/Login/LoginContent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
const HomeContainer = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/games');
    }
  }, [isAuthenticated]);
  return <LoginContent isModal={true} />;
};
export default HomeContainer;
