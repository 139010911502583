/* eslint-disable react/prop-types */
import { useState } from 'react';
import EditIcon from '../../../../assets/svg/Edit.svg';
import CreatorModal from '../../Modal/CreatorModal/CreatorModal';
import { formatDate } from '../../../../utils/DateFormat';
import { Link } from 'react-router-dom';
import { creatorNftRoute } from '../../../../route/navigate';
export const columnCreator = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Id',
        accessor: '_id',
        Cell: ({ row }) => (
          <div className="flex items-center">
            <Link
              to={creatorNftRoute(row.values._id)}
              className="text-[#11B954] body-2 cursor-pointer"
              target="_self"
              rel="noreferrer"
            >
              <p className="body-1 ">{row.values._id}</p>
            </Link>
          </div>
        ),
      },
      {
        Header: 'Thumbnail',
        accessor: 'thumbnail',
        Cell: ({ row }) => (
          <a
            href={row.values.thumbnail}
            className="text-[#11B954] body-2"
            target="_blank"
            rel="noreferrer"
          >
            Click
          </a>
        ),
      },
      {
        Header: 'Banner',
        accessor: 'banner',
        Cell: ({ row }) => (
          <a
            href={row.values.thumbnail}
            className="text-[#11B954] body-2"
            target="_blank"
            rel="noreferrer"
          >
            Click
          </a>
        ),
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ row }) => <p>{formatDate(row.values.createdAt)}</p>,
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        Cell: ({ row }) => (
          <button
            className={`rounded-xl px-5 py-1 ${
              row.values.is_active ? 'bg-[#29CC97]' : 'bg-[#F12B2C]'
            } text-[#ffffff] uppercase`}
          >
            {row.values.is_active ? 'active' : 'inactive'}
          </button>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);
          return (
            <div className="">
              <img
                src={EditIcon}
                alt=""
                onClick={() => setIsModal(true)}
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
              />
              {isModal && (
                <CreatorModal
                  isModalOpen={isModal}
                  setIsModalOpen={setIsModal}
                  creator={row.original}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
