import React from 'react';
import PropType from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import FilterLogo from '../../../assets/svg/FilterLogo.svg';
import usePermission from '../../../hooks/usePermision';
const TableHeader = ({
  FileName,
  modalOpenfunc,
  onToggleFilter,
  addButtonEnable = true,
  featureEnable = true,
}) => {
  const [searchParam] = useSearchParams();
  const [hasAccess] = usePermission();
  return (
    <div>
      <div className="flex justify-between items-center pb-5">
        <p className="h6-thin"> {FileName}</p>
        {featureEnable && (
          <div className="flex items-center justify-center">
            {/* <div className="flex ml-5 cursor-pointer hover:bg-[#d7dbf8] p-2 rounded-lg">
              <img src={SortLogo} alt="Sort Logo" className="w-4 h-4" />
              <div className=" body-2">Sort</div>
            </div> */}
            <div
              className="relative ml-5 flex items-center cursor-pointer hover:bg-[#d7dbf8] p-2 rounded-lg"
              onClick={onToggleFilter}
            >
              <span
                className={`${
                  [...searchParam].length > 0
                    ? 'animate-ping bg-[#11B954] top-0 right-0 absolute'
                    : ' '
                }  inline-flex h-2 w-2 rounded-full  opacity-75 `}
              ></span>
              <img src={FilterLogo} alt="Filter Logo" />
              <span className="ml-3 body-2">Filter</span>
            </div>
            {addButtonEnable && hasAccess && (
              <div>
                <button
                  type="submit"
                  onClick={modalOpenfunc}
                  className="ml-5 rounded-lg bg-[#3751FF] body-2 px-5 py-2 text-[#ffffff] "
                >
                  Add {FileName}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  FileName: PropType.string,
  modalOpenfunc: PropType.func,
  onToggleFilter: PropType.func,
  addButtonEnable: PropType.bool,
  featureEnable: PropType.bool,
};

export default TableHeader;
