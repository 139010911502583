import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import PlansContent from '../../component/Plans/PlansContent';
import { plansGetAction } from '../../redux/action/plan';

const PlansContainer = () => {
  const [filterValue, setFilter] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const dispatch = useDispatch();
  const isPlansGetPending = useSelector(state => state.plans.isPlansGetPending);
  const [searchParam] = useSearchParams();

  const { plans } = useSelector(state => state.plans);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(plansGetAction(filterValue, pageIndex, pageSize));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (plans) setPageCount(Math.ceil(plans?.total_items / pageSize1));
  }, [plans, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilter(filter);
  }, [searchParam]);
  return (
    <>
      <PlansContent pageCount={pageCount} fetchData={fetchData} />
      <RingSpinner isLoading={isPlansGetPending} />
    </>
  );
};

export default PlansContainer;
