import { gql } from '@apollo/client';

export const GET_USER_TO_ASSETS_GQL = gql`
  query GetUserToAssets(
    $input: UserToAssetQueryInput
    $page: Int
    $limit: Int
  ) {
    getUserToAssets(input: $input, page: $page, limit: $limit) {
      data {
        asset
        _id
        detail {
          tournament_remaining
        }
        is_active
        createdAt
        user {
          name
          username
          _id
          is_verified
          mobile_number
          email
        }
        transaction
        is_active
        createdAt
      }
      total_items
      page
      limit
    }
  }
`;

export const EXPORT_ASSET_BUYER_GQL = gql`
  query ExportUserToAsset($input: ExportUserToAssetQueryInput) {
    exportUserToAsset(input: $input) {
      data
      type
    }
  }
`;
