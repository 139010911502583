import React, { memo } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import PropTypes from 'prop-types';

const MoonSpinner = ({ isLoading, color = '#ffffff', style, size = 20 }) => {
  return (
    <>
      {isLoading && (
        <div className={`w-full flex items-center justify-center ${style}`}>
          <MoonLoader color={color} loading={isLoading} size={size} />
        </div>
      )}
    </>
  );
};

export default memo(MoonSpinner);
MoonSpinner.propTypes = {
  isLoading: PropTypes.bool,
  style: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};
