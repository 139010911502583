import { apolloClient } from '../../../service/apolloClient';
import {
  EXPORT_ASSET_BUYER_GQL,
  GET_USER_TO_ASSETS_GQL,
} from '../../query/assets';

import { reduxAction } from '../base';
import { toastAction } from '../toastAction';

import {
  EXPORT_ASSET_BUYER_GQL_PENDING,
  GET_USER_TO_ASSETS,
  GET_USER_TO_ASSETS_PENDING,
} from './type';

export const userToAssetsGetAction = (page, limit, input) => {
  const query = GET_USER_TO_ASSETS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_USER_TO_ASSETS_PENDING, true));
    apolloClient
      .query({ query, variables: { input, page, limit } })
      .then(({ data }) => {
        const { getUserToAssets: res } = data;

        dispatch(reduxAction(GET_USER_TO_ASSETS, res));
        dispatch(reduxAction(GET_USER_TO_ASSETS_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(GET_USER_TO_ASSETS_PENDING, false));
      });
  };
};

export const exportAssetBuyersAction = input => {
  return dispatch => {
    const query = EXPORT_ASSET_BUYER_GQL;
    dispatch(reduxAction(EXPORT_ASSET_BUYER_GQL_PENDING, true));
    apolloClient
      .query({ query, variables: { input } })
      .then(({ data }) => {
        const { exportUserToAsset: res } = data;

        if (res && res.data) {
          const url = `data:image/${res.type};base64,${res.data}`;
          let el = document.createElement('a');
          el.href = url;
          el.download = `AssetsHolder.${res.type}`;
          el.click();
          el.remove();
        } else {
          toastAction.error('Data not found');
        }
        dispatch(reduxAction(EXPORT_ASSET_BUYER_GQL_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(EXPORT_ASSET_BUYER_GQL_PENDING, false));
      });
  };
};
