import { useEffect, useState } from 'react';
import { toastAction } from '../../redux/action/toastAction';
import { getCurrentDate, getPreviousMonthDate } from '../../utils/DateFormat';
import PropType from 'prop-types';
import MoonSpinner from '../common/Spinner/MoonSpinner';
import { useSelector } from 'react-redux';

const DateFilter = ({ onGetGamesAnalytics }) => {
  const [dateFilter, setDateFilter] = useState();
  const { isGamesAnalyticsGetPending } = useSelector(
    state => state.gameAnalytics
  );
  const onChangeDate = (type, val) => {
    let date = { ...dateFilter };
    if (type === 'END_DATE') {
      date.endDate = val;
    } else {
      date.startDate = val;
    }
    setDateFilter(date);
  };
  const onSubmit = () => {
    if (!dateFilter.startDate || !dateFilter.endDate) {
      toastAction.error(
        'Please Select Start Date and End Date before apply filter'
      );
      return;
    }
    onGetGamesAnalytics(dateFilter);
  };

  useEffect(() => {
    const endDate = getCurrentDate();
    const startDate = getPreviousMonthDate();
    setDateFilter({ startDate, endDate });
    onGetGamesAnalytics({ endDate, startDate });
  }, []);
  return (
    <div className="flex justify-end my-8 w-[88%] mx-auto ">
      <div className="">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4 ">
            <p className="caption">Start Date</p>
            <input
              className="rounded border-2 h-[55%]"
              type="date"
              value={dateFilter?.startDate || ''}
              onChange={({ target }) =>
                onChangeDate('START_DATE', target.value)
              }
            />
          </div>
          <div className="col-span-4 ">
            <p className="caption">End Date</p>
            <input
              className="rounded border-2 h-[55%]"
              type="date"
              value={dateFilter?.endDate || ''}
              onChange={({ target }) => onChangeDate('END_DATE', target.value)}
            />
          </div>
          <div className="col-span-2 flex  items-center">
            <button
              className="caption w-full bg-[#537FE7] py-2 px-4 rounded text-white h-[55%] mt-2"
              onClick={onSubmit}
            >
              {isGamesAnalyticsGetPending === true ? (
                <MoonSpinner isLoading={isGamesAnalyticsGetPending} />
              ) : (
                'Apply'
              )}
            </button>
          </div>
          {/* <div className="col-span-2 flex items-center">
            <button
              className="caption w-full py-2 px-4 rounded text-white bg-[#BDCDD6] h-[55%] mt-2"
              onClick={onClearFilter}
            >
              Clear
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DateFilter;

DateFilter.propTypes = {
  onGetGamesAnalytics: PropType.func,
};
