import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { makeQueryString } from '../../../utils/MakeQueryString';

const schema = yup.object().shape({
  _id: yup.string(),
  name: yup.string(),
});
const SpinTheWheelFilter = ({ onToggleFilter }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: undefined,
      name: undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values._id === '') {
        values._id = undefined;
      }
      if (values.name === '') {
        values.name = undefined;
      }

      navigate({
        search: makeQueryString(values),
      });
      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black px-5">
        <div className="flex justify-between items-center mb-3 mt-5">
          <div className="body-1">ID :</div>
          <input
            type="text"
            name="_id"
            placeholder="ID"
            className="rounded-lg body-1"
            value={formik.values._id}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">name :</div>
          <input
            type="text"
            name="name"
            placeholder="name"
            className="rounded-lg body-1"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </div>

        <div className="float-right">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2  bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white my-2 ml-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

SpinTheWheelFilter.propTypes = {
  FilterUser: PropTypes.func,
  onToggleFilter: PropTypes.func,
};
export default SpinTheWheelFilter;
