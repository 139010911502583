/* eslint-disable react/prop-types */

import { formatDate } from '../../../../utils/DateFormat';
import EditIcon from '../../../../assets/svg/Edit.svg';
import { useState } from 'react';
import WalletEditModal from '../../Modal/WalletEditModal/WalletEditModal';

export const columnUpdateWallet = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Id',
        accessor: '_id',
      },
      {
        Header: 'Username',
        accessor: 'user.username',
      },
      {
        Header: 'Contact',
        accessor: 'user.mobile_number',
      },
      {
        Header: 'Fiat Balance',
        accessor: 'balance.amount',
      },
      {
        Header: 'Fiat Earnings',
        accessor: 'earnings.amount',
      },

      {
        Header: 'Crypto Balance',
        accessor: 'crypto',
        Cell: ({ row }) => (
          <div className="">
            {row.values.crypto?.balance?.amount + ' '}

            <span className="text-slate-600 caption">
              {row.values.crypto?.balance?.currency}
            </span>
          </div>
        ),
      },
      {
        Header: 'Crypto Earning',
        accessor: 'crypto.balance',
        Cell: ({ row }) => (
          <div>
            {row.values.crypto?.earnings.amount + ' '}

            <span className="text-slate-600 caption">
              {row.values.crypto?.earnings.currency}
            </span>
          </div>
        ),
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: ({ row }) => <p>{formatDate(row.values.createdAt)}</p>,
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);
          const onToggleModal = () => {
            setIsModal(!isModal);
          };
          return (
            <div className="">
              <img
                src={EditIcon}
                onClick={() => setIsModal(true)}
                alt=""
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full  cursor-pointer"
              />
              {isModal && (
                <WalletEditModal
                  isModal={isModal}
                  onToggleModal={onToggleModal}
                  wallet={row.original}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
