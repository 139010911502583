import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeQueryString } from '../../utils/MakeQueryString';

const schema = yup.object().shape({
  _id: yup.string(),

  game_type: yup.string(),
});
const GameAssetsFilters = ({ onToggleFilter }) => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: filter?._id || undefined,

      game_type: filter?.game_type || undefined,
      asset_type: filter?.asset_type || undefined,
      sub_asset_type: filter?.sub_asset_type || undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values._id === '') {
        values._id = undefined;
      }

      if (values.type === '') {
        values.game_type = undefined;
      }

      navigate({
        search: makeQueryString(values),
      });
      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black px-5">
        <div className="flex justify-between items-center mb-3 mt-5">
          <div className="body-1">ID :</div>
          <input
            type="text"
            name="_id"
            placeholder="user assets ID"
            className="rounded-lg body-1"
            value={formik.values._id}
            onChange={formik.handleChange}
          />
        </div>

        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Game Type :</div>
          <input
            type="text"
            name="game_type"
            placeholder="type"
            className="rounded-lg body-1"
            value={formik.values.game_type}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Asset Type :</div>
          <input
            type="text"
            name="asset_type"
            placeholder="type"
            className="rounded-lg body-1"
            value={formik.values.asset_type}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Sub Asset Type :</div>
          <input
            type="text"
            name="sub_asset_type"
            placeholder="type"
            className="rounded-lg body-1"
            value={formik.values.sub_asset_type}
            onChange={formik.handleChange}
          />
        </div>
        <div className="float-right">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2  bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white my-2 ml-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

GameAssetsFilters.propTypes = {
  FilterUser: PropTypes.func,
  onToggleFilter: PropTypes.func,
};
export default GameAssetsFilters;
