import React from 'react';
import PropTypes from 'prop-types';
import { Styles } from '../../css/table';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';

import { columnAssetsBuyer } from '../common/table/TableColumns/assetsBuyer';
import TableHeader from '../common/table/TableHeader';
import AssetBuyerFilter from './AssetBuyerFilter';
import { useState } from 'react';
import { TablePagination } from '../common/table/tablePagination';

const AssetBuyerContent = ({
  fetchData,
  pageCount,
  filterValue,
  onExportUserToAsset,
  usersAssets,
}) => {
  const [filter, setFilter] = useState(false);
  const onToggleFilter = () => {
    setFilter(!filter);
  };
  const column = React.useMemo(() => columnAssetsBuyer, []);

  return (
    <div>
      <div className="w-full">
        <HeaderTitle pageTitle={'Assets buyer'} />
        <div className="flex justify-end mx-20 mt-10"></div>
        <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader
            FileName={'Assets buyer'}
            onToggleFilter={onToggleFilter}
          />
          <div
            className={`absolute w-[25rem] mt-2 p-2 bg-slate-400 backdrop-blur-sm rounded-md right-0 z-10 ${
              filter ? 'block' : 'hidden'
            }`}
          >
            <AssetBuyerFilter onToggleFilter={onToggleFilter} />
          </div>

          <Styles>
            <TablePagination
              columns={column}
              data={usersAssets?.data || []}
              fetchData={fetchData}
              filterValue={filterValue}
              total_item={usersAssets?.total_items}
              pageCount={pageCount}
              className="rounded"
            />
          </Styles>

          <div className="w-full mx-auto flex">
            <div className=" w-full flex justify-end items-end">
              <button
                className="body-2 text-white bg-gray-400 rounded-lg px-4 py-2"
                onClick={onExportUserToAsset}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AssetBuyerContent.propTypes = {
  usersAssets: PropTypes.object,
  onExportUserToAsset: PropTypes.func,
  pageCount: PropTypes.number,
  filterValue: PropTypes.object,
  fetchData: PropTypes.func,
};

export default AssetBuyerContent;
