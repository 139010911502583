import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { WalletUpateStyle } from '../../../../css/ModalCss/ModalCss';
import WalletEditForm from './WalletEditForm';
import { CrossIcon } from '../../svg/Icons';
import { useDispatch } from 'react-redux';
import { updateWalletAction } from '../../../../redux/action/wallet';

const WalletEditModal = ({ isModal, onToggleModal, wallet }) => {
  const dispatch = useDispatch();
  const onReload = () => {
    window.location.reload();
  };
  const walletId = wallet?._id;
  const onUpdateWallet = data => {
    dispatch(updateWalletAction(data, walletId, onReload));
  };
  return (
    <Modal
      isOpen={isModal}
      onRequestClose={onToggleModal}
      ariaHideApp={false}
      style={WalletUpateStyle}
      shouldCloseOnOverlayClick={true}
    >
      <div className="text-black relative">
        <div
          className="absolute right-0 cursor-pointer p-2 "
          onClick={onToggleModal}
        >
          <CrossIcon />
        </div>
        <div className="text-center h5-thin">Wallet</div>
        <div className="mt-8">
          <WalletEditForm onUpdateWallet={onUpdateWallet} wallet={wallet} />
        </div>
      </div>
    </Modal>
  );
};
WalletEditModal.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
  wallet: PropTypes.object,
};

export default WalletEditModal;
