import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { memoryMatchConfigsGetAction } from '../../../redux/action/gameConfig';
import MemoryConfigCreateModal from '../../common/Modal/GameConfigModal/MemoryCreateModal';
import TableHeader from '../../common/table/TableHeader';
import MemoryMatchFilter from './MemoryMatchFilters';
import MemoryMatchTable from './MemoryMatchTable';
import { useSearchParams } from 'react-router-dom';

const MemoryMatchContent = () => {
  const [filterValue, setFilterValue] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [isModal, setIsModal] = useState(false);
  const [isFilter, setFilter] = useState(false);

  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();

  const { memoryMatchConfigs } = useSelector(state => state.gameConfig);

  const onToggleModal = () => {
    setIsModal(!isModal);
  };
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      dispatch(memoryMatchConfigsGetAction(filterValue, pageIndex, pageSize));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  const onToggleFilter = () => {
    setFilter(!isFilter);
  };
  useEffect(() => {
    if (memoryMatchConfigs)
      setPageCount(Math.ceil(memoryMatchConfigs?.total_items / pageSize1));
  }, [memoryMatchConfigs, pageSize1]);
  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);

    setFilterValue(filter);
  }, [searchParam]);
  return (
    <div className="">
      <TableHeader
        FileName={'Memory'}
        modalOpenfunc={onToggleModal}
        onToggleFilter={onToggleFilter}
        addButtonEnable={true}
      />
      <div
        className={`absolute w-[23rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
          isFilter ? 'block' : 'hidden'
        }`}
      >
        <MemoryMatchFilter onToggleFilter={onToggleFilter} />
      </div>
      <MemoryMatchTable fetchData={fetchData} pageCount={pageCount} />
      {isModal && (
        <MemoryConfigCreateModal
          isModalOpen={isModal}
          onToggleModal={onToggleModal}
        />
      )}
    </div>
  );
};

export default MemoryMatchContent;
