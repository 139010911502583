import React from 'react';
import PropTypes from 'prop-types';

const TransactionField = ({ heading, field, onClickFunction }) => {
  return (
    <div className="w-4/5 mx-auto">
      <div className="mb-1 body-1">{heading}</div>
      <div
        className={`border-2 px-3 py-2 text-[#11B954] ${
          onClickFunction && 'cursor-pointer'
        }`}
        onClick={onClickFunction}
      >
        {field ? field : 'NULL'}
      </div>
    </div>
  );
};

TransactionField.propTypes = {
  heading: PropTypes.string,
  field: PropTypes.any,
  onClickFunction: PropTypes.func,
};

export default TransactionField;
