import React, { useEffect } from 'react';
import CreatorContainer from './CreatorContainer';
import { useDispatch, useSelector } from 'react-redux';
import { creatorsGetAction } from '../../redux/action/creator';
import RingSpinner from '../../component/common/Spinner/RingSpinner';

const CreatorView = () => {
  const dispatch = useDispatch();
  const isCreatorsGetPending = useSelector(
    state => state.creators.isCreatorsGetPending
  );
  const isCreatorCreatePending = useSelector(
    state => state.creators.isCreatorCreatePending
  );
  const isCreatorUpdatePending = useSelector(
    state => state.creators.isCreatorUpdatePending
  );

  useEffect(() => {
    dispatch(creatorsGetAction());
  }, [dispatch]);

  return (
    <div className="">
      <CreatorContainer />
      <RingSpinner
        isLoading={
          isCreatorsGetPending ||
          isCreatorCreatePending ||
          isCreatorUpdatePending
        }
      />
    </div>
  );
};

export default CreatorView;
