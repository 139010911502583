import { combineReducers } from 'redux';
import { creatorsReducer } from './creator';
import { discordReducer } from './discord_role';
import { nftReducer } from './nft';
import { userReducer } from './user';
import { usersReducer } from './users';
import { transactionReducer } from './transaction';
import { socialsReducer } from './socials';
import { userToAssetsReducer } from './assets';
import { tournamentsReducer } from './tournaments';
import { dashboardReducer } from './dashboard';
import { leaderboardReducer } from './leaderboard';
import { gameReducer } from './game';
import { gameAnalyticsReducer } from './gameAnalytics';
import { gameConfigReducer } from './gameConfig';
import { gameAssets } from './gameAssets';
import { plans } from './plan';
import { userPlans } from './userPlan';
const appReducer = combineReducers({
  creators: creatorsReducer,
  nft: nftReducer,
  user: userReducer,
  users: usersReducer,
  socials: socialsReducer,
  discord: discordReducer,
  transaction: transactionReducer,
  tournaments: tournamentsReducer,
  assets: userToAssetsReducer,
  dashboard: dashboardReducer,
  leaderboard: leaderboardReducer,
  game: gameReducer,
  gameAnalytics: gameAnalyticsReducer,
  gameConfig: gameConfigReducer,
  gameAssets: gameAssets,
  plans: plans,
  userPlans: userPlans,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
