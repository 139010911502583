import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeQueryString } from '../../utils/MakeQueryString';

const schema = yup.object().shape({
  _id: yup.string(),
  username: yup.string(),
  mobile_number: yup.string(),
  email: yup.string().email(),
});
const UserFilters = ({ onToggleFilter }) => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: filter?._id || undefined,
      username: filter?.username || undefined,
      mobile_number: filter?.mobile_number || undefined,
      email: filter?.email || undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values._id === '') {
        values._id = undefined;
      }
      if (values.username === '') {
        values.username = undefined;
      }
      if (values.mobile_number === '') {
        values.mobile_number = undefined;
      }
      if (values.email === '') {
        values.email = undefined;
      }
      navigate({
        search: makeQueryString(values),
      });
      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black px-5">
        <div className="flex justify-between items-center mb-3 mt-5">
          <div className="body-1">User ID :</div>
          <input
            type="text"
            name="_id"
            placeholder="user ID"
            className="rounded-lg body-1"
            value={formik.values._id}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Username :</div>
          <input
            type="text"
            name="username"
            placeholder="username"
            className="rounded-lg body-1"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Mobile :</div>
          <input
            type="text"
            name="mobile_number"
            placeholder="number"
            className="rounded-lg body-1"
            value={formik.values.mobile_number}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="body-1">Email :</div>
          <input
            type="email"
            name="email"
            placeholder="email"
            className="rounded-lg body-1"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </div>
        <div className="float-right">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2  bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white my-2 ml-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

UserFilters.propTypes = {
  FilterUser: PropTypes.func,
  onToggleFilter: PropTypes.func,
};
export default UserFilters;
