/* eslint-disable react/prop-types */
import { useState } from 'react';
import { formatDate } from '../../../../utils/DateFormat';
import EditIcon from '../../../../assets/svg/Edit.svg';
import CreatePlanModal from '../../Modal/PlanModal/CreatePlanModal';
import usePermission from '../../../../hooks/usePermision';

export const planColumn = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Id',
        accessor: '_id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Validity',
        accessor: 'validity',
      },
      {
        Header: 'Create At',
        accessor: 'createdAt',
        Cell: ({ row }) => {
          return (
            <>
              <div>{formatDate(row.values.createdAt)}</div>
            </>
          );
        },
      },

      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);
          const [hasAccess] = usePermission();

          const onToggleModal = () => {
            setIsModal(!isModal);
          };

          return (
            <div className="">
              {hasAccess && (
                <>
                  <img
                    id={row.values._id}
                    src={EditIcon}
                    alt=""
                    onClick={() => onToggleModal()}
                    className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
                  />
                  {isModal && (
                    <CreatePlanModal
                      isModalOpen={isModal}
                      onToggleModal={onToggleModal}
                      planId={row.original._id}
                      actionType="UPDATE"
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
    ],
  },
];
