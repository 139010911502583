import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { inlineStyle } from '../../../../css/ModalCss/ModalCss';
import TransactionField from './TransactionField';
import { CrossIcon } from '../../svg/Icons';
import { formatDate } from '../../../../utils/DateFormat';
import { userProfileRoute } from '../../../../route/navigate';

const TransactionDetailsModal = ({ isModal, onToggleModal, transaction }) => {
  const onClickUserProfile = () => {
    window.open(userProfileRoute(transaction.author._id), '_blank');
  };
  return (
    <Modal
      isOpen={isModal}
      onRequestClose={onToggleModal}
      ariaHideApp={false}
      style={inlineStyle}
      shouldCloseOnOverlayClick={true}
    >
      <div className="text-black relative">
        <div
          className="absolute right-0 cursor-pointer p-2 "
          onClick={onToggleModal}
        >
          <CrossIcon />
        </div>
        <div className="text-center h5-thin">Transaction Details</div>
        <div className="grid grid-cols-3 gap-y-10 mt-5 mb-5">
          <TransactionField heading="Nft id" field={transaction?.nft} />
          <TransactionField heading="Transaction id" field={transaction?._id} />
          <TransactionField heading="Amount" field={transaction?.amount} />
          <TransactionField
            heading="Description"
            field={transaction?.description}
          />
          <TransactionField
            heading="Transaction type"
            field={transaction?.transaction_type}
          />
          {transaction?.detail?.upi && (
            <TransactionField heading="UPI" field={transaction?.detail?.upi} />
          )}
          <TransactionField
            heading="Author id"
            onClickFunction={onClickUserProfile}
            field={transaction?.author?._id}
          />
          <TransactionField
            heading="Author name"
            field={transaction?.author?.name}
          />
          <TransactionField heading="Status" field={transaction?.status} />
          <TransactionField
            heading="Updated at"
            field={formatDate(transaction?.updatedAt)}
          />
          <TransactionField heading="Bid" field={transaction?.bid} />
          <TransactionField
            heading="Created at"
            field={formatDate(transaction?.updatedAt)}
          />
        </div>
      </div>
    </Modal>
  );
};

TransactionDetailsModal.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
  transaction: PropTypes.object,
};
export default TransactionDetailsModal;
