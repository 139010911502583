import React from 'react';
import PropTypes from 'prop-types';

const NftThumbnail = ({ media, thumbnailStyle }) => {
  return (
    <div className="">
      {media?.type === 'VIDEO' ? (
        <video
          loop
          autoPlay
          muted
          className={`object-fill w-full min-h-auto ${thumbnailStyle}`}
        >
          <source src={media?.url} type="video/mp4" />
          video format does not support
        </video>
      ) : (
        <img
          className={`object-fill w-full min-h-auto ${thumbnailStyle}`}
          src={media?.url}
          alt="nft"
        />
      )}
    </div>
  );
};

NftThumbnail.propTypes = {
  media: PropTypes.object,
  thumbnailStyle: PropTypes.string,
};
export default NftThumbnail;
