import PropTypes from 'prop-types';
import EngagementCard from './EngagementCard';

const GameEngagement = ({ gameEngagement }) => {
  const gameViews = gameEngagement?.find(ele => ele.type === 'GAME_VIEW');
  const gameOver = gameEngagement?.find(ele => ele.type === 'GAME_OVER');
  const ctaClick = gameEngagement?.find(ele => ele.type === 'CTA_CLICK');
  const engagment = gameEngagement?.find(ele => ele.type === 'ENGAGEMENT');
  const users = gameEngagement?.find(ele => ele.type === 'USERS');

  return (
    <div className="">
      <div className="flex flex-row justify-evenly p-4">
        <EngagementCard data={users} title={'Users'} />
        <EngagementCard data={gameViews} title={'Game Views'} />
        <EngagementCard data={gameOver} title={'Game Over'} />
        <EngagementCard data={ctaClick} title={'CTA Click'} />
        <EngagementCard data={engagment} title={'Avg Spend Time (S)'} />
      </div>
    </div>
  );
};

GameEngagement.propTypes = {
  gameEngagement: PropTypes.array,
};
export default GameEngagement;
