import {
  PLANS,
  GET_PLANS_PENDING,
  PLAN,
  GET_PLAN_PENDING,
  UPDATE_PLAN,
  UPDATE_PLAN_PENDING,
  CREATE_PLAN,
  CREATE_PLAN_PENDING,
} from '../action/plan/type';

const initialPendingState = {
  isPlansGetPending: false,
  isPlanGetPending: false,
  isUpdatePlanPending: false,
  isCreatePlanPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  plans: null,
  plan: null,
  newPlan: null,
};

export const plans = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case PLANS:
      return {
        ...state,
        plans: payload,
      };
    case GET_PLANS_PENDING:
      return {
        ...state,
        isPlansGetPending: payload,
      };
    case PLAN:
      return {
        ...state,
        plan: payload,
      };
    case GET_PLAN_PENDING:
      return {
        ...state,
        isPlanGetPending: payload,
      };
    case UPDATE_PLAN:
      return {
        ...state,
        plan: payload,
      };
    case UPDATE_PLAN_PENDING:
      return {
        ...state,
        isUpdatePlanPending: payload,
      };
    case CREATE_PLAN:
      return {
        ...state,
        newPlan: payload,
      };
    case CREATE_PLAN_PENDING:
      return {
        ...state,
        isCreatePlanPending: payload,
      };
    default: {
      return state;
    }
  }
};
