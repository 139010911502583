import React from 'react';
import UserContainer from './UserContainer';

const UserView = () => {
  return (
    <div>
      <UserContainer />
    </div>
  );
};

export default UserView;
