import React from 'react';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import DashboardCard from './DashboardCard';
import PropType from 'prop-types';

import NFTLogo from '../../assets/svg/NFTLogo.svg';
import DateFilter from './DateFilter';
const DashboardContent = ({
  gameViews,
  gameOvers,
  ctaClick,
  users,
  onGetGamesAnalytics,
}) => {
  return (
    <div className="w-full h-[90vh] ">
      <HeaderTitle pageTitle="Dashboard" />
      <div className="mt-10 h-full">
        <div className="flex flex-col justify-center items-center self-center mx-auto w-[90%] border-2 rounded-md h-[70%]">
          <DateFilter onGetGamesAnalytics={onGetGamesAnalytics} />
          <div className="w-[80%] mx-auto mt-8">
            <div className="grid grid-cols-4 w-full gap-8">
              <DashboardCard
                image={NFTLogo}
                title={'Game Session'}
                amount={gameViews}
                className="col-span-2"
              />
              <DashboardCard
                image={NFTLogo}
                title={'Users Visited'}
                amount={users}
                className="col-span-2"
              />

              <DashboardCard
                image={NFTLogo}
                title={'Completed Session'}
                amount={gameOvers}
                className="col-span-2"
              />

              <DashboardCard
                image={NFTLogo}
                title={'CTA Click'}
                amount={ctaClick}
                className="col-span-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
DashboardContent.propTypes = {
  gameViews: PropType.number,
  gameOvers: PropType.number,
  ctaClick: PropType.number,
  users: PropType.number,
  onGetGamesAnalytics: PropType.func,
};
