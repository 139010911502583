import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import { NftUtility } from '../../component/NftUtility/NftUtility';
import { useQuery } from '../../hooks/useQuery';
import { nftGetAllNftUtiliy } from '../../redux/action/nft';

export const NftUtilityContainer = () => {
  const dispatch = useDispatch();
  const queryParams = useQuery();
  let NftId = queryParams.get('nftUtilityId');

  const [searchParam] = useSearchParams();

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [filterValue, setFilterValue] = useState(null);

  const nftUtility = useSelector(state => state.nft.nftAllUtility);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(nftGetAllNftUtiliy(pageIndex, pageSize, filterValue));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (nftUtility)
      setPageCount(Math.ceil(nftUtility?.total_items / pageSize1));
  }, [nftUtility, pageSize1]);
  /*eslint-disable*/
  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    filter.is_regex = true;
    if (NftId != null) {
      filter.nft = NftId;
    }
    console.log(filter);
    setFilterValue(filter);
  }, [searchParam]);

  const isNftUtilityGetPending = useSelector(
    state => state.nft.isNftUtilityGetPending
  );
  return (
    <>
      <NftUtility
        fetchData={fetchData}
        pageCount={pageCount}
        nftUtility={nftUtility}
        filterValue={filterValue}
      />
      <RingSpinner isLoading={isNftUtilityGetPending} />
    </>
  );
};
