import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import GameAnalyticsContent from '../../component/GameAnalytics/GameAnalyticsContent';
import {
  exportGameLeadsAction,
  gameLeadsGetAction,
} from '../../redux/action/game';
import { gameEngagementGetAction } from '../../redux/action/gameAnalytics';

const GameAnalyticsContainer = () => {
  const [leadPage, setLeadPage] = useState(0);
  const limit = 4;
  const dispatch = useDispatch();
  const { gameLeads, isGameLeadsGetPending, isGameLeadsExportPending } =
    useSelector(state => state.game);
  const { gameId } = useParams();
  const [searchParam] = useSearchParams();
  const { gameEngagement } = useSelector(state => state.gameAnalytics);
  const startDate = searchParam.get('startDate');
  const endDate = searchParam.get('endDate');
  const onGetGameEngagement = () => {
    if (gameId) {
      let query = { gameId };
      if (startDate && endDate) {
        query.startDate = startDate;
        query.endDate = endDate;
      }
      dispatch(gameEngagementGetAction(query));
    }
  };

  const onGetGameLeads = () => {
    if (gameId) {
      let query = { game: gameId };
      if (startDate && endDate) {
        query.startDate = startDate;
        query.endDate = endDate;
      }
      dispatch(gameLeadsGetAction(query, leadPage, limit));
    }
  };
  const onGameLeadLoadMore = () => {
    if (isGameLeadsGetPending === false && gameLeads?.currentData?.length > 0) {
      setLeadPage(leadPage + 1);
    }
  };
  const onExportGameLeads = () => {
    if (gameId) {
      let query = { game: gameId };
      if (startDate && endDate) {
        query.startDate = startDate;
        query.endDate = endDate;
      }
      dispatch(exportGameLeadsAction(query));
    }
  };
  useEffect(() => {
    onGetGameEngagement();
    setLeadPage(0);
  }, [searchParam]);
  useEffect(() => {
    onGetGameLeads();
  }, [leadPage, searchParam]);
  return (
    <>
      <GameAnalyticsContent
        gameEngagement={gameEngagement}
        onGameLeadLoadMore={onGameLeadLoadMore}
        onExportGameLeads={onExportGameLeads}
        gameLeads={gameLeads}
      />
      <RingSpinner
        isLoading={isGameLeadsGetPending || isGameLeadsExportPending}
      />
    </>
  );
};

export default GameAnalyticsContainer;
