import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import RhymFull from '../../assets/svg/RhymFull.svg';
import OtpForm from './OtpForm';
const schema = yup.object().shape({
  mobile_number: yup.number().test('len', 'Must be Atleast 10 Digits', val => {
    if (val) return val.toString().length === 10;
  }),
});

const MobileForm = ({ onVerifyAdmin }) => {
  const formik = useFormik({
    initialValues: {
      mobile_number: undefined,
      country_code: '+91',
      otp1: undefined,
      otp2: undefined,
      otp3: undefined,
      otp4: undefined,
      otp5: undefined,
      otp6: undefined,
    },
    validationSchema: schema,
    onSubmit: val => {
      const otp =
        val.otp1 + val.otp2 + val.otp3 + val.otp4 + val.otp5 + val.otp6;
      const body = {
        mobile_number: val.mobile_number,
        country_code: val.country_code,
        otp: parseInt(otp),
      };
      onVerifyAdmin(body);
    },
  });

  const formikError =
    formik.values.mobile_number === undefined || formik.errors.mobile_number;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-8 lg:mx-[5.2rem] mt-12 ">
        <div className="w-full flex justify-center items-center my-8">
          <img src={RhymFull} alt="icon" />
        </div>
        <div className="h4-thin h2-sm-thin ">Log In Rhym Admin Portal</div>
        <div className="caption flex justify-center my-6">
          Enter your mobile number and otp below
        </div>
        <h3 className="caption caption-sm text-[#FE6125] mt-12 ">Phone no.</h3>
        <div className="flex flex-wrap mt-4 w-96 lg:w-full">
          <input
            type="tel"
            data-testid="login-screen-country-code"
            className=" border-[1px] border-slate-300  outline-0 focus:ring-0 pb-0.5 px-2 w-12 lg:pb-2 text-left  text-base lg:text-lg font-['aribau-regular']"
            value="+91"
            name="country-code"
            autoComplete="off"
            disabled={'disabled'}
          />

          <input
            type="tel"
            data-testid="login-screen-mobile-number"
            className="after:  border-[1px] border-slate-300 focus:ring-0 outline-0 pb-0.5 lg:pb-2 w-64 lg:w-96 text-left px-2 text-base lg:text-lg font-['aribau-regular'] placeholder-[#FFFFFF4D]"
            placeholder="Type Here"
            name="mobile_number"
            autoComplete="off"
            value={formik.values.mobile_number || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            required
          />
        </div>
        <div className="h-[1px] lg:w-[450px] w-[325px] bg-[#ffffff66]"></div>
        <div className="mt-3 h-[1px]">
          <p>{formikError}</p>
        </div>
        <div className="">
          <OtpForm
            onHandleChange={formik.handleChange}
            formikValues={formik.values}
            formikError={formik.errors}
          />
        </div>
      </div>
    </form>
  );
};

MobileForm.propTypes = {
  onVerifyAdmin: PropTypes.func,
};

export default MobileForm;
