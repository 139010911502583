import { DISCORD_ROLES, ROLES_GET_PENDING } from '../action/discord/type';

const initialPendingState = {
  isDiscordsGetPending: null,
  isDiscordCreatePending: null,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  discord_roles: null,
};

export const discordReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case DISCORD_ROLES:
      return {
        ...state,
        discord_roles: payload,
      };
    case ROLES_GET_PENDING:
      return {
        ...state,
        isDiscordsGetPending: payload,
      };
    default: {
      return state;
    }
  }
};
