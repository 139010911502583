export const LeaderboardColumn = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Id',
        accessor: '_id',
      },
      {
        Header: 'game',
        accessor: 'game',
      },
      {
        Header: 'score',
        accessor: 'score',
      },
      {
        Header: 'username',
        accessor: 'user.username',
      },
      {
        Header: 'email',
        accessor: 'user.email',
      },
      {
        Header: 'user Id',
        accessor: 'user._id',
      },
    ],
  },
];
