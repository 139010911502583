import React, { useEffect, useState, useCallback } from 'react';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import TournamentsContent from '../../component/tournaments/TournamentsContent';
import { tournamentGetAction } from '../../redux/action/tournament';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';
import { columnTournament } from '../../component/common/table/TableColumns/tournaments';

const TournamentContainer = () => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [filterValue, setFilter] = useState(null);
  const [searchParam] = useSearchParams();

  const { isTournamentsGetPending } = useSelector(state => state.tournaments);
  const tournaments = useSelector(state => state.tournaments?.tournaments);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(tournamentGetAction(pageIndex, pageSize, filterValue));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (tournaments) {
      setPageCount(Math.ceil(tournaments?.total_items / pageSize1));
    }
  }, [tournaments, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilter(filter);
  }, [searchParam]);

  return (
    <>
      <HeaderTitle pageTitle={'Tournaments'} />
      <TournamentsContent
        tournaments={tournaments}
        columnData={columnTournament}
        fetchData={fetchData}
        pageCount={pageCount}
        filterValue={filterValue}
      />
      <RingSpinner isTournamentsGetPending={isTournamentsGetPending} />
    </>
  );
};

export default TournamentContainer;
