export const routeType = {
  HOME_VIEW: 'HOME_VIEW',
  CREATOR_VIEW: 'CREATOR_VIEW',
  NFT_VIEW: 'NFT_VIEW',
  NFT_UTILITY_VIEW: 'NFT_UTILITY_VIEW',
  ASSET_VIEW: 'ASSET_VIEW',
  TRANSACTION_VIEW: 'TRANSACTION_VIEW',
  NFT_BUYER_VIEW: 'NFT_BUYER_VIEW',
  ASSET_BUYER_VIEW: 'ASSET_BUYER_VIEW',
  SOCIAL_VIEW: 'SOCIAL_VIEW',
  DISCORD_ROLE_VIEW: 'DISCORD_ROLE_VIEW',
  USER_VIEW: 'USER_VIEW',
  USER_DETAIL_VIEW: 'USER_DETAIL_VIEW',
  TOURNAMENT_VIEW: 'TOURNAMENT_VIEW',
  TOURNAMENT_FORM: 'TOURNAMENT_FORM',
  WALLET_VIEW: 'WALLET_VIEW',
  DASHBOARD_VIEW: 'DASHBOARD_VIEW',
  GAME_VIEW: 'GAME_VIEW',
  GAME_ANALYTICS_VIEW: 'GAME_ANALYTICS_VIEW',
  GAME_CONFIG: 'GAME_CONFIG',
  GAME_ASSETS: 'GAME_ASSETS',
  PLAN_VIEW: 'PLAN_VIEW',
  USER_PLAN_VIEW: 'USER_PLAN_VIEW',
};
