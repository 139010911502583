import React, { useCallback, useEffect, useState } from 'react';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';

import SocialTable from '../../component/socials/SocialTable';
import { useDispatch, useSelector } from 'react-redux';
import { socialsGetAction } from '../../redux/action/socials';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import { useSearchParams } from 'react-router-dom';

const SocialContainer = () => {
  const dispatch = useDispatch();

  const [searchParam] = useSearchParams();
  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [filterValue, setFilter] = useState(null);

  const socials = useSelector(state => state.socials.socials);

  const isGetAllSocialPending = useSelector(
    state => state.socials.isGetAllSocialPending
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      dispatch(socialsGetAction(pageIndex, pageSize, filterValue));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (socials) {
      setPageCount(Math.ceil(socials?.total_items / pageSize1));
    }
  }, [socials, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilter(filter);
  }, [searchParam]);

  return (
    <div className={`h-screen w-[80vw]`}>
      <HeaderTitle pageTitle={'Socials'} />
      <div className="mt-10">
        <SocialTable
          socials={socials}
          fetchData={fetchData}
          pageCount={pageCount}
          featureState={true}
          filterValue={filterValue}
          addButtonState={true}
        />
      </div>
      <RingSpinner isLoading={isGetAllSocialPending} />
    </div>
  );
};

export default SocialContainer;
