import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const usePermission = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    if (user) {
      const editAccess =
        user?.mobile_number === process.env.REACT_APP_ADMIN_MOBILE_NUMBER;
      setHasAccess(editAccess);
    }
  }, [user]);
  return [hasAccess];
};

export default usePermission;
