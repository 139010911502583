import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Styles } from '../../css/table';
import { TablePagination } from '../common/table/tablePagination';
import { gameAssetsColumn } from '../common/table/TableColumns/gameAssets';

const GameAssetsTable = ({ pageCount, fetchData }) => {
  const { gameAssets } = useSelector(state => state.gameAssets);
  return (
    <div className="w-full h-full">
      <Styles>
        <TablePagination
          columns={gameAssetsColumn}
          data={gameAssets?.data || []}
          total_item={gameAssets?.total_items}
          fetchData={fetchData}
          pageCount={pageCount}
          className="rounded"
        />
      </Styles>
    </div>
  );
};
GameAssetsTable.propTypes = {
  onPageLimitChange: PropTypes.func,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
};
export default GameAssetsTable;
