import React, { useState } from 'react';
import CreatorModal from '../../component/common/Modal/CreatorModal/CreatorModal';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';
import { CreatorTable } from '../../component/creators/CreatorTable';
import TableHeader from '../../component/common/table/TableHeader';
const CreatorContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className={`h-screen w-full`}>
        <HeaderTitle pageTitle={'Creators'} />
        <div className="mt-10">
          <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
            <TableHeader FileName={'Creators'} modalOpenfunc={setIsModalOpen} />
            <CreatorTable modalOpenfunc={setIsModalOpen} />
          </div>
        </div>
      </div>
      <CreatorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default CreatorContainer;
