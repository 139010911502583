import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { createGameModalStyle } from '../../../../css/ModalCss/ModalCss';
import { CrossIcon } from '../../svg/Icons';
import PropTypes from 'prop-types';
import { toastAction } from '../../../../redux/action/toastAction';
import { useDispatch } from 'react-redux';
import {
  assessmentConfigCreateAction,
  assessmentConfigGetAction,
  assessmentConfigUpdateAction,
} from '../../../../redux/action/gameConfig';
import JsonEditor from '../../Editor/JsonEditor';

const AssessmentConfigCreateModal = ({
  isModalOpen,
  onToggleModal,
  id,
  actionType = 'CREATE',
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(undefined);
  const [editorJson, setEditorJson] = useState(undefined);
  const [error, setError] = useState(undefined);

  const onUpdate = () => {
    dispatch(assessmentConfigUpdateAction(id, data, onToggleModal));
  };
  const onCreate = () => {
    dispatch(assessmentConfigCreateAction(data, onToggleModal));
  };
  const onCallBack = value => {
    let temp = { ...value };
    delete temp['_id'];
    delete temp['root_game'];
    setData(temp);
    var str = JSON.stringify(temp, undefined, 4);
    setEditorJson(str);
    document.getElementById('assessment_config_json_editor').innerHTML = str;
  };
  const onGet = () => {
    dispatch(assessmentConfigGetAction(id, onCallBack));
  };
  const onChangeCreateGame = inputData => {
    let temp;
    setEditorJson(inputData);
    try {
      temp = JSON.parse(inputData);
      setError(undefined);
    } catch (err) {
      const errMsg = JSON.stringify(err?.message || err);
      setError(errMsg);
    }
    setData(temp);
  };
  const onSubmit = () => {
    if (error) {
      toastAction.error('Please resolve the error');
      return;
    }
    if (actionType === 'UPDATE' && id) {
      onUpdate();
      return;
    }
    onCreate();
  };

  useEffect(() => {
    if (id && actionType === 'UPDATE') {
      onGet();
    }
  }, [id]);
  return (
    <ReactModal
      isOpen={isModalOpen}
      ariaHideApp={false}
      overlayClassName="f-modal-overlay"
      shouldCloseOnOverlayClick={true}
      style={createGameModalStyle}
      className=""
    >
      <div className="relative w-full h-full text-black">
        <div className="flex w-full justify-between">
          <div className="h5-thin">Create Assessment</div>
          <div className="flex justify-end cursor-pointer p-2 ">
            <div onClick={() => onToggleModal()}>
              <CrossIcon />
            </div>
          </div>
        </div>
        <div className="mt-4">
          {error && <p className="caption text-red-400">{error}</p>}
          <p className="rounded-lg">
            <JsonEditor code={editorJson} onChange={onChangeCreateGame} />
          </p>
        </div>
        <div className="w-full flex justify-end mt-4">
          <div
            onClick={() => onToggleModal()}
            className="cursor-pointer p-2 px-4 border-[1px] border-black body-2 bg-slate-200 rounded-lg mx-2"
          >
            close
          </div>
          <div
            className="cursor-pointer p-2 px-4 bg-green-800 rounded-lg body-2 text-white"
            onClick={onSubmit}
          >
            {actionType === 'CREATE' ? 'create' : 'update'}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

AssessmentConfigCreateModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onToggleModal: PropTypes.func,
  id: PropTypes.string,
  actionType: PropTypes.string,
};
export default AssessmentConfigCreateModal;
