export const inlineStyle = {
  overlay: {
    backgroundColor: '#12121273',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    width: 'min(70%, 80vw)',
    height: 'min(80%,700px)',
    borderRadius: '15px',
    border: 0,
    margin: 'auto',
  },
};

export const createGameModalStyle = {
  overlay: {
    backgroundColor: '#12121273',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    width: 'min(60%, 40vw)',
    height: 'min(90%,750px)',
    borderRadius: '15px',
    border: 0,
    margin: 'auto',
  },
};
export const leaderboardModalStyle = {
  overlay: {
    backgroundColor: '#12121273',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    width: 'min(80%, 90vw)',
    height: 'min(95%,900px)',
    borderRadius: '15px',
    border: 0,
    margin: 'auto',
  },
};
export const deleteModalStyle = {
  overlay: {
    backgroundColor: '#12121273',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    width: '20%',
    height: '40%',
    borderRadius: '15px',
    border: 0,
    margin: 'auto',
  },
};

export const walletStyle = {
  overlay: {
    backgroundColor: '#12121273',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    width: '40%',
    height: '500px',
    borderRadius: '15px',
    border: 0,
    margin: 'auto',
  },
};

export const WalletUpateStyle = {
  overlay: {
    backgroundColor: '#12121273',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#ffffff',
    width: '75%',
    height: '500px',
    borderRadius: '15px',
    border: 0,
    margin: 'auto',
  },
};
