export const NFT_HOLDER_PENDING = 'NFT_HOLDER_PENDING';
export const ASSET_HOLDER_PENDING = 'ASSET_HOLDER_PENDING';
export const INACTIVE_ASSET_PENDING = 'INACTIVE_PENDING';
export const PENDING_WITHDRAWAL_PENDING = 'PENDING_WITHDRAWAL_PENDING';

export const GET_NFT_HOLDER = 'GET_NFT_HOLDER';
export const GET_ASSET_HOLDER = 'GET_ASSET_HOLDER';
export const GET_INACTIVE_ASSET = 'GET_INACTIVE_ASSET';

export const GET_PENDING_WITHDRAWAL = 'GET_PENDING_WITHDRAWAL';
