import React from 'react';
import PropTypes from 'prop-types';

const ThumbnailContainer = ({ formik, formikErrors }) => {
  return (
    <div className="my-5">
      <h1 className="text-slate-500 h5-thin ml-4">Thumbnail</h1>
      <div className="w-full flex justify-between">
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Thumbnail Banner</div>
          </div>
          <input
            type="text"
            name="thumbnail.banner.url"
            value={formik.values?.thumbnail?.banner?.url}
            onChange={formik.handleChange}
            placeholder="Enter Thumbnail Banner (Large Device)"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="thumbnail_banner"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.banner?.url}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Type</div>
          </div>
          <select
            type="select"
            name="thumbnail.banner.type"
            defaultValue={formik.values?.thumbnail?.banner?.type}
            value={formik.values.thumbnail?.banner?.type}
            onChange={formik.handleChange}
            placeholder="Enter Thumbnail Banner Type"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="thumbnail_banner_type"
          >
            <option value="undefined" name="">
              Select Type
            </option>
            <option value="IMAGE" name="IMAGE">
              IMAGE
            </option>
            <option value="VIDEO" name="VIDEO">
              VIDEO
            </option>
          </select>
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.banner?.type}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
      </div>
      <div className="w-full flex justify-between mt-4 pt-4">
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">
              Thumbnail Banner &nbsp;
              <span className="text-sm">(SM)</span>
            </div>
          </div>
          <input
            type="text"
            name="thumbnail.banner_sm.url"
            value={formik.values?.thumbnail?.banner_sm?.url}
            onChange={formik.handleChange}
            placeholder="Enter Thumbnail Banner (Small Device)"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="thumbnail_banner"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.banner_sm?.url}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Type</div>
          </div>
          <select
            type="select"
            name="thumbnail.banner_sm.type"
            defaultValue={formik.values.thumbnail.banner_sm?.type}
            value={formik.values.thumbnail.banner_sm?.type}
            onChange={formik.handleChange}
            placeholder="Enter Thumbnail Banner Type"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="thumbnail_banner_type"
          >
            <option value="undefined" name="">
              Select Type
            </option>
            <option value="IMAGE" name="IMAGE">
              IMAGE
            </option>
            <option value="VIDEO" name="VIDEO">
              VIDEO
            </option>
          </select>
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.banner_sm?.type}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
      </div>

      {/* Completed Banner */}
      <div className="w-full flex justify-between mt-4 pt-4">
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Completed Banner</div>
          </div>
          <input
            type="text"
            name="thumbnail.completed_banner.url"
            value={formik.values.thumbnail.completed_banner?.url}
            onChange={formik.handleChange}
            placeholder="Enter Completed Banner (Large Device)"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="completed_banner"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.completed_banner?.url}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Type</div>
          </div>
          <select
            type="select"
            name="thumbnail.completed_banner.type"
            defaultValue={
              formik.values.thumbnail.completed_banner?.type || undefined
            }
            value={formik.values.thumbnail.completed_banner?.type}
            onChange={formik.handleChange}
            placeholder="Enter Completed Banner Type"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="completed_banner_type"
          >
            <option value="undefined" name="">
              Select Type
            </option>
            <option value="IMAGE" name="IMAGE">
              IMAGE
            </option>
            <option value="VIDEO" name="VIDEO">
              VIDEO
            </option>
          </select>
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.completed_banner?.type}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
      </div>
      <div className="w-full flex justify-between mt-4 pt-4">
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">
              Completed Banner &nbsp;
              <span className="text-sm">(SM)</span>
            </div>
          </div>
          <input
            type="text"
            name="thumbnail.completed_banner_sm.url"
            value={formik.values.thumbnail.completed_banner_sm?.url}
            onChange={formik.handleChange}
            placeholder="Enter Completed Banner (Small Device)"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="completed_banner"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.completed_banner_sm?.url}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/2 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Type</div>
          </div>
          <select
            type="select"
            name="thumbnail.completed_banner_sm.type"
            defaultValue={
              formik.values.thumbnail.completed_banner_sm?.type || undefined
            }
            value={formik.values.thumbnail?.completed_banner_sm?.type}
            onChange={formik.handleChange}
            placeholder="Enter Completed Banner Type"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="completed_banner_type"
          >
            <option value="undefined" name="">
              Select Type
            </option>
            <option value="IMAGE" name="IMAGE">
              IMAGE
            </option>
            <option value="VIDEO" name="VIDEO">
              VIDEO
            </option>
          </select>
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors?.thumbnail?.completed_banner_sm?.type}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
      </div>
    </div>
  );
};

ThumbnailContainer.propTypes = {
  formik: PropTypes.object,
  formikErrors: PropTypes.object,
};

export default ThumbnailContainer;
