import {
  ASSESSMENT_CONFIGS,
  GET_GAME_CONFIGS_PENDING,
  GET_GAME_CONFIG_PENDING,
  JIGSAW_PUZZLE_CONFIGS,
  MEMORY_MATCH_CONFIGS,
  SKILL_CONFIGS,
  SPIN_THE_WHEEL_CONFIGS,
} from '../action/gameConfig/type';

const initialPendingState = {
  isConfigsGetPending: false,
  isConfigGetPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  skillConfigs: null,
  assessmentConfigs: null,
  memoryMatchConfigs: null,
  jigsawPuzzleConfigs: null,
  spinTheWheelConfigs: null,
};

export const gameConfigReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case ASSESSMENT_CONFIGS:
      return {
        ...state,
        assessmentConfigs: payload,
      };
    case SKILL_CONFIGS:
      return {
        ...state,
        skillConfigs: payload,
      };
    case MEMORY_MATCH_CONFIGS:
      return {
        ...state,
        memoryMatchConfigs: payload,
      };
    case JIGSAW_PUZZLE_CONFIGS: {
      return {
        ...state,
        jigsawPuzzleConfigs: payload,
      };
    }
    case SPIN_THE_WHEEL_CONFIGS: {
      return {
        ...state,
        spinTheWheelConfigs: payload,
      };
    }
    case GET_GAME_CONFIGS_PENDING:
      return {
        ...state,
        isConfigsGetPending: payload,
      };
    case GET_GAME_CONFIG_PENDING:
      return {
        ...state,
        isConfigGetPending: payload,
      };

    default: {
      return state;
    }
  }
};
