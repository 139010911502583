export const GET_GAME_CONFIGS_PENDING = 'GET_GAME_CONFIGS_PENDING';
export const GET_GAME_CONFIG_PENDING = 'GET_GAME_CONFIG_PENDING';
export const CREATE_GAME_CONFIG_PENDING = 'CREATE_GAME_CONFIG_PENDING';
export const UPDATE_GAME_CONFIG_PENDING = 'UPDATE_GAME_CONFIG_PENDING';

export const SKILL_CONFIGS = 'SKILL_CONFIGS';
export const ASSESSMENT_CONFIGS = 'ASSESSMENT_CONFIGS';

export const MEMORY_MATCH_CONFIGS = 'MEMORY_MATCH_CONFIGS';
export const JIGSAW_PUZZLE_CONFIGS = 'JIGSAW_PUZZLE_CONFIGS';
export const SPIN_THE_WHEEL_CONFIGS = 'SPIN_THE_WHEEL_CONFIGS';
