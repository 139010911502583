import { apolloClient } from '../../../service/apolloClient';
import {
  CREATE_ASSESSMENT_CONFIG_GQL,
  CREATE_JIGSAW_PUZZLE_CONFIG_GQL,
  CREATE_MEMORY_MATCH_CONFIG_GQL,
  CREATE_SKILL_CONFIG_GQL,
  CREATE_SPIN_THE_WHEEL_CONFIG_GQL,
  GET_ASSESSMENT_CONFIGS_GQL,
  GET_ASSESSMENT_CONFIG_GQL,
  GET_JIGSAW_PUZZLE_CONFIGS_GQL,
  GET_JIGSAW_PUZZLE_CONFIG_GQL,
  GET_MEMORY_MATCH_CONFIGS_GQL,
  GET_MEMORY_MATCH_CONFIG_GQL,
  GET_SKILL_CONFIGS_GQL,
  GET_SKILL_CONFIG_GQL,
  GET_SPIN_THE_WHEEL_CONFIGS_GQL,
  GET_SPIN_THE_WHEEL_CONFIG_GQL,
  UPDATE_ASSESSMENT_CONFIG_GQL,
  UPDATE_JIGSAW_PUZZLE_CONFIG_GQL,
  UPDATE_MEMORY_MATCH_CONFIG_GQL,
  UPDATE_SKILL_CONFIG_GQL,
  UPDATE_SPIN_THE_WHEEL_CONFIG_GQL,
} from '../../query/gameConfig';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import {
  ASSESSMENT_CONFIGS,
  CREATE_GAME_CONFIG_PENDING,
  GET_GAME_CONFIGS_PENDING,
  GET_GAME_CONFIG_PENDING,
  JIGSAW_PUZZLE_CONFIGS,
  MEMORY_MATCH_CONFIGS,
  SKILL_CONFIGS,
  SPIN_THE_WHEEL_CONFIGS,
  UPDATE_GAME_CONFIG_PENDING,
} from './type';

export const skillConfigsGetAction = (value, page, limit) => {
  const query = GET_SKILL_CONFIGS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getConfigs: res } = data;
        dispatch(reduxAction(SKILL_CONFIGS, res));
      })
      .catch(err => {
        toastAction.error(err);
      });
  };
};
export const skillConfigGetAction = (configId, cb) => {
  const query = GET_SKILL_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: configId },
      })
      .then(({ data }) => {
        const { getConfig: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      });
  };
};

export const skillConfigCreateAction = (value, cb) => {
  const mutation = CREATE_SKILL_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createGameConfig: res } = data;
        if (!res) return;
        toastAction.success('Config created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, false)));
  };
};
export const skillConfigUpdateAction = (id, data, cb) => {
  const mutation = UPDATE_SKILL_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: data } })
      .then(({ data }) => {
        const { updateGameConfig: res } = data;
        if (!res) return;
        toastAction.success('Config updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, false)));
  };
};
export const assessmentConfigsGetAction = (value, page, limit) => {
  const query = GET_ASSESSMENT_CONFIGS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getAssessmentConfigs: res } = data;
        dispatch(reduxAction(ASSESSMENT_CONFIGS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, false)));
  };
};
export const assessmentConfigGetAction = (configId, cb) => {
  const query = GET_ASSESSMENT_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIG_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: configId },
      })
      .then(({ data }) => {
        const { getAssessmentConfig: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIG_PENDING, false)));
  };
};
export const assessmentConfigCreateAction = (value, cb) => {
  const mutation = CREATE_ASSESSMENT_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createAssessmentConfig: res } = data;
        if (!res) return;
        toastAction.success('Config created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, false)));
  };
};
export const assessmentConfigUpdateAction = (id, data, cb) => {
  const mutation = UPDATE_ASSESSMENT_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: data } })
      .then(({ data }) => {
        const { updateAssessmentConfig: res } = data;
        if (!res) return;
        toastAction.success('Config updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, false)));
  };
};
export const memoryMatchConfigsGetAction = (value, page, limit) => {
  const query = GET_MEMORY_MATCH_CONFIGS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getMemoryMatchConfigs: res } = data;
        dispatch(reduxAction(MEMORY_MATCH_CONFIGS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, false)));
  };
};
export const memoryMatchConfigGetAction = (id, cb) => {
  const query = GET_MEMORY_MATCH_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIG_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: id },
      })
      .then(({ data }) => {
        const { getMemoryMatchConfig: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIG_PENDING, false)));
  };
};
export const memoryMatchConfigCreateAction = (value, cb) => {
  const mutation = CREATE_MEMORY_MATCH_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createMemoryMatchConfig: res } = data;
        if (!res) return;
        toastAction.success('Config created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, false)));
  };
};

export const memoryMatchConfigUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_MEMORY_MATCH_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id, input: value } })
      .then(({ data }) => {
        const { updateMemoryMatchConfig: res } = data;
        if (!res) return;
        toastAction.success('Config updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, false)));
  };
};

export const jigsawPuzzleConfigCreateAction = (value, cb) => {
  const mutation = CREATE_JIGSAW_PUZZLE_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createJigsawPuzzleConfig: res } = data;
        if (!res) return;
        toastAction.success('Config created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, false)));
  };
};

export const jigsawPuzzleConfigUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_JIGSAW_PUZZLE_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id, input: value } })
      .then(({ data }) => {
        const { updateJigsawPuzzleConfig: res } = data;
        if (!res) return;
        toastAction.success('Config updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, false)));
  };
};

export const jigsawPuzzleConfigGetAction = (id, cb) => {
  const query = GET_JIGSAW_PUZZLE_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIG_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: id },
      })
      .then(({ data }) => {
        const { getJigsawPuzzleConfig: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIG_PENDING, false)));
  };
};

export const jigsawPuzzleConfigsGetAction = (value, page, limit) => {
  const query = GET_JIGSAW_PUZZLE_CONFIGS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getJigsawPuzzleConfigs: res } = data;
        dispatch(reduxAction(JIGSAW_PUZZLE_CONFIGS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, false)));
  };
};

export const spinTheWheelConfigCreateAction = (value, cb) => {
  const mutation = CREATE_SPIN_THE_WHEEL_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createSpinTheWheelConfig: res } = data;
        if (!res) return;
        toastAction.success('Config created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_CONFIG_PENDING, false)));
  };
};

export const spinTheWheelConfigUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_SPIN_THE_WHEEL_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id, input: value } })
      .then(({ data }) => {
        const { updateSpinTheWheelConfig: res } = data;
        if (!res) return;
        toastAction.success('Config updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_CONFIG_PENDING, false)));
  };
};

export const spinTheWheelConfigGetAction = (id, cb) => {
  const query = GET_SPIN_THE_WHEEL_CONFIG_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIG_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: id },
      })
      .then(({ data }) => {
        const { getSpinTheWheelConfig: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIG_PENDING, false)));
  };
};

export const spinTheWheelConfigsGetAction = (value, page, limit) => {
  const query = GET_SPIN_THE_WHEEL_CONFIGS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getSpinTheWheelConfigs: res } = data;
        dispatch(reduxAction(SPIN_THE_WHEEL_CONFIGS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_CONFIGS_PENDING, false)));
  };
};
