import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import UserContent from '../../component/users/UserContent';
import { usersGetAction } from '../../redux/action/users';

const UserContainer = () => {
  const [filterValue, setFilter] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const dispatch = useDispatch();
  const isUsersGetPending = useSelector(state => state.users.isUsersGetPending);
  const [searchParam] = useSearchParams();

  const users = useSelector(state => state.users.users);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(usersGetAction(filterValue, pageIndex, pageSize));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (users) setPageCount(Math.ceil(users?.total_items / pageSize1));
  }, [users, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilter(filter);
  }, [searchParam]);

  return (
    <>
      <UserContent pageCount={pageCount} fetchData={fetchData} />
      <RingSpinner isLoading={isUsersGetPending} />
    </>
  );
};

export default UserContainer;
