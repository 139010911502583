import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query GetUsers($input: UserInputQuery, $page: Int, $limit: Int) {
    getUsers(input: $input, page: $page, limit: $limit) {
      data {
        _id
        mobile_number
        name
        username
        thumbnail
        is_verified
        email
        is_active
        plan
      }
      total_items
      page
      limit
    }
  }
`;
