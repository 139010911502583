import { GAME_ASSETS, GET_GAME_ASSETS_PENDING } from '../action/gameAsset/type';

const initialPendingState = {
  isGameAssetsGetPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  gamesAssets: null,
};

export const gameAssets = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case GAME_ASSETS:
      return {
        ...state,
        gameAssets: payload,
      };
    case GET_GAME_ASSETS_PENDING:
      return {
        ...state,
        isGameAssetsGetPending: payload,
      };
    default: {
      return state;
    }
  }
};
