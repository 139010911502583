import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToogleComponent from '../../common/Toggle/Toggle';

const PrizePoolContainer = ({ formik, formikErrors }) => {
  const [isPrizePool, setIsPrizePool] = useState(false);
  const onPrizePoolToggle = () => {
    let temp = isPrizePool;
    setIsPrizePool(!temp);
    let obj = undefined;
    if (temp) {
      obj = {
        amount: undefined,
        currency: undefined,
      };
    }
    formik.setFieldValue('prize_pool', obj);
  };
  return (
    <div className="my-5">
      <div className="flex gap-6">
        <h1 className="text-slate-500 h5-thin ml-4">Prize Pool</h1>
        <ToogleComponent label="" onChange={onPrizePoolToggle} />
      </div>
      {isPrizePool && (
        <div className="w-full flex justify-evenly">
          <div className="w-1/3 mt-2 mx-4">
            <div className="flex justify-between items-center">
              <div className="text-slate-500 h7-thin">Amount</div>
            </div>
            <input
              type="number"
              name="prize_pool.amount"
              value={formik.values.prize_pool?.amount}
              onChange={formik.handleChange}
              placeholder="Enter Amount"
              className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
              id="prize_pool_amount"
            />
            <p className="text-sm text-[#FF0000] capitalize my-1">
              {formikErrors?.prize_pool?.amount}
            </p>
            <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
          </div>
          <div className="w-1/3 mt-2 mx-4">
            <div className="flex justify-between items-center">
              <div className="text-slate-500 h7-thin">Currency</div>
            </div>
            <select
              type="select"
              name="prize_pool.currency"
              value={formik.values.prize_pool?.currency || 'defaultOpt'}
              onChange={formik.handleChange}
              placeholder="Enter Currency"
              className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
              id="slots"
            >
              <option value="undefined">Select Currency</option>
              <option value="INR" name="INR">
                INR
              </option>
              <option value="USCD" name="USCD">
                USCD
              </option>
            </select>
            <p className="text-sm text-[#FF0000] capitalize my-1">
              {formikErrors?.prize_pool?.currency}
            </p>
            <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
          </div>
          <div className="w-1/3 mt-2 mx-4">
            <div className="flex justify-between items-center">
              <div className="text-slate-500 h7-thin">Text</div>
            </div>
            <input
              type="text"
              name="prize_pool.text"
              value={formik.values.prize_pool?.text}
              onChange={formik.handleChange}
              placeholder="Enter Text"
              className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
              id="prize_pool_text"
            />
            <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
          </div>
        </div>
      )}
    </div>
  );
};

PrizePoolContainer.propTypes = {
  formik: PropTypes.object,
  formikErrors: PropTypes.object,
};

export default PrizePoolContainer;
