import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Styles } from '../../css/table';
import { columnsNftFanClub } from '../common/table/TableColumns/nftFanclub';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import TableHeader from '../common/table/TableHeader';

import BuyerFilter from './BuyerFilter';
import { TablePagination } from '../common/table/tablePagination';

const BuyersContent = ({
  nftId,
  fetchData,
  pageCount,
  filterValue,
  onClickExport,
  fanClubFilter,
}) => {
  const nftBuyer = useSelector(state => state.user.UserNft);
  const [isFilter, setIsFilter] = useState(false);
  const [isModal, setModal] = useState(false);

  const onToggleModal = () => {
    setModal(!isModal);
  };

  const onToggleFilter = () => {
    setIsFilter(!isFilter);
  };

  return (
    <div className="w-[80vw]">
      <HeaderTitle pageTitle={'Fan club'} />
      <div className="flex justify-end mx-20 mt-4"></div>
      <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
        <TableHeader
          FileName={'Fan club'}
          modalOpenfunc={onToggleModal}
          onToggleFilter={onToggleFilter}
        />
        <div
          className={`absolute w-90 mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
            isFilter ? 'block' : 'hidden'
          }`}
        >
          <BuyerFilter FilterTransaction={fanClubFilter} nftId={nftId} />
        </div>

        <Styles>
          <TablePagination
            columns={columnsNftFanClub}
            data={nftBuyer?.data || []}
            fetchData={fetchData}
            pageCount={pageCount}
            filterValue={filterValue}
            total_item={nftBuyer?.total_items}
            className="rounded"
          />
        </Styles>

        <div className="w-full mx-auto flex">
          <div className="w-full flex justify-end items-end">
            <button
              className="body-2 bg-gray-400 text-white rounded-lg px-4 py-2"
              onClick={onClickExport}
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyersContent;
BuyersContent.propTypes = {
  nftId: PropTypes.string,
  fetchData: PropTypes.func,
  filterValue: PropTypes.object,
  pageCount: PropTypes.number,
  onClickExport: PropTypes.func,
  fanClubFilter: PropTypes.func,
};
