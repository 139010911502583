import {
  CREATORS_INFO,
  FEATURED_CREATORS_INFO,
  CREATORS_GET_PENDING,
  FEATURED_CREATORS_GET_PENDING,
  CREATOR_CREATE_PENDING,
  CREATOR_UPDATE_PENDING,
} from '../action/creator/type';

const initialPendingState = {
  isCreatorsGetPending: false,
  isCreatorCreatePending: false,
  isFeaturedCreatorGetPending: false,
  isCreatorUpdatePending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  creators: null,
  featuredCreator: null,
};

export const creatorsReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case CREATORS_INFO:
      return {
        ...state,
        creators: payload,
      };

    case FEATURED_CREATORS_INFO:
      return {
        ...state,
        featuredCreator: payload,
      };
    case CREATORS_GET_PENDING:
      return {
        ...state,
        isCreatorsGetPending: payload,
      };
    case FEATURED_CREATORS_GET_PENDING:
      return {
        ...state,
        isFeaturedCreatorGetPending: payload,
      };

    case CREATOR_CREATE_PENDING:
      return {
        ...state,
        isCreatorCreatePending: payload,
      };
    case CREATOR_UPDATE_PENDING:
      return {
        ...state,
        isCreatorUpdatePending: payload,
      };

    default: {
      return state;
    }
  }
};
