import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

const schema = yup.object().shape({
  user: yup.string(),
  nft: yup.string(),
});
const BuyerFilter = ({ FilterTransaction, nftId }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_regex: true,
      user: undefined,
      nft: nftId,
    },
    validationSchema: schema,
    onSubmit: values => {
      FilterTransaction(values);
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black">
        <div className="flex">
          <input
            type="text"
            name="user"
            placeholder="user Id"
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="user"
          />
        </div>

        <div className="w-full flex justify-end items-end">
          <div className="m-2 cursor-pointer">
            <div className="bg-blue-400 px-2 py-1 rounded-lg hover:bg-blue-500">
              <button type="submit">submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

BuyerFilter.propTypes = {
  FilterTransaction: PropTypes.func,
  nftId: PropTypes.string,
};
export default BuyerFilter;
