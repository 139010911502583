import { gql } from '@apollo/client';
import { CORE_CANVAS_ELEMENT_FIELDS } from './canvas';

export const GET_MEMORY_MATCH_CONFIGS_GQL = gql`
  query GetMemoryMatchConfigs(
    $input: MemoryMatchConfigQueryInput
    $page: Int
    $limit: Int
  ) {
    getMemoryMatchConfigs(input: $input, page: $page, limit: $limit) {
      data {
        _id
        category
        assets {
          asset {
            url
          }
          type
        }
        description
        layout
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_MEMORY_MATCH_CONFIG_GQL = gql`
  ${CORE_CANVAS_ELEMENT_FIELDS}
  query GetMemoryMatchConfig($id: ID!) {
    getMemoryMatchConfig(id: $id) {
      _id
      title
      pairs {
        first_content {
          asset {
            url
            type
          }
        }
        second_content {
          asset {
            url
            type
          }
        }
      }
      assets {
        asset {
          type
          url
        }
        type
      }
      elements {
        ...CoreCanvasElementFields
      }
    }
  }
`;

export const CREATE_MEMORY_MATCH_CONFIG_GQL = gql`
  mutation CreateMemoryMatchConfig($input: MemoryMatchConfigCreateInput) {
    createMemoryMatchConfig(input: $input) {
      _id
    }
  }
`;

export const UPDATE_MEMORY_MATCH_CONFIG_GQL = gql`
  mutation UpdateMemoryMatchConfig(
    $id: ID!
    $input: MemoryMatchConfigUpdateInput
  ) {
    updateMemoryMatchConfig(id: $id, input: $input) {
      _id
    }
  }
`;

export const CREATE_JIGSAW_PUZZLE_CONFIG_GQL = gql`
  mutation CreateJigsawPuzzleConfig($input: JigsawPuzzleConfigCreateInput) {
    createJigsawPuzzleConfig(input: $input) {
      _id
      assets {
        asset {
          url
          type
        }
        type
      }
      description
      timer
      title
    }
  }
`;

export const GET_JIGSAW_PUZZLE_CONFIG_GQL = gql`
  ${CORE_CANVAS_ELEMENT_FIELDS}
  query GetJigsawPuzzleConfig($id: ID!) {
    getJigsawPuzzleConfig(id: $id) {
      _id
      assets {
        asset {
          url
          type
        }
        type
      }
      description
      title
      timer
      elements {
        ...CoreCanvasElementFields
      }
    }
  }
`;
export const UPDATE_JIGSAW_PUZZLE_CONFIG_GQL = gql`
  mutation UpdateJigsawPuzzleConfig(
    $id: ID!
    $input: JigsawPuzzleConfigUpdateInput
  ) {
    updateJigsawPuzzleConfig(id: $id, input: $input) {
      _id
      assets {
        asset {
          url
          type
        }
        type
      }
      category
      description
      timer
      title
    }
  }
`;

export const GET_JIGSAW_PUZZLE_CONFIGS_GQL = gql`
  query GetJigsawPuzzleConfigs(
    $input: JigsawPuzzleQueryInput
    $page: Int
    $limit: Int
  ) {
    getJigsawPuzzleConfigs(input: $input, page: $page, limit: $limit) {
      data {
        _id
        assets {
          asset {
            url
            type
          }
          type
        }
        category
        description
        timer
        title
      }
      limit
      page
      total_items
    }
  }
`;
export const GET_ASSESSMENT_CONFIGS_GQL = gql`
  query GetAssessmentConfigs(
    $input: AssessmentQueryInput
    $page: Int
    $limit: Int
  ) {
    getAssessmentConfigs(input: $input, page: $page, limit: $limit) {
      data {
        _id
        type
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_ASSESSMENT_CONFIG_GQL = gql`
  ${CORE_CANVAS_ELEMENT_FIELDS}
  query GetAssessmentConfig($id: ID!) {
    getAssessmentConfig(id: $id) {
      _id
      slides {
        _id
        title
        background {
          url
          type
        }
        options {
          _id
          value {
            type
            asset {
              url
              type
            }
            content
          }
          index
          is_correct
        }
        type
        btn {
          label
        }
        elements {
          ...CoreCanvasElementFields
        }
      }
      product_reco_screen {
        elements {
          ...CoreCanvasElementFields
        }
        products {
          asset {
            type
            url
          }
          title
          description
          cta_btn {
            label
            is_visible
            style
            type
            redirect_url
          }
        }
        logic {
          product
          conditions {
            question
            options
          }
        }
      }
      type
    }
  }
`;

export const CREATE_ASSESSMENT_CONFIG_GQL = gql`
  mutation CreateAssessmentConfig($input: AssessmentConfigInput) {
    createAssessmentConfig(input: $input) {
      _id
    }
  }
`;
export const UPDATE_ASSESSMENT_CONFIG_GQL = gql`
  mutation UpdateAssessmentConfig(
    $input: AssessmentConfigUpdateInput
    $id: ID
  ) {
    updateAssessmentConfig(input: $input, id: $id) {
      _id
    }
  }
`;
export const GET_SKILL_CONFIGS_GQL = gql`
  query GetConfigs($input: GameConfigQueryInput, $page: Int, $limit: Int) {
    getConfigs(input: $input, page: $page, limit: $limit) {
      data {
        _id
        root_game
        title
        description
      }
      total_items
      page
      limit
    }
  }
`;
export const GET_SKILL_CONFIG_GQL = gql`
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      _id
      root_game
      title
      description
      assets {
        type
        sub_type
        game_file_path
        upload {
          upload_file {
            url
            extension
          }
          height
          width
          is_upload
        }
        preview {
          url
          position {
            top
            bottom
            left
            right
          }
          height
          width
        }
      }
    }
  }
`;

export const CREATE_SKILL_CONFIG_GQL = gql`
  mutation CreateGameConfig($input: GameConfigCreateInput) {
    createGameConfig(input: $input) {
      _id
    }
  }
`;

export const UPDATE_SKILL_CONFIG_GQL = gql`
  mutation UpdateGameConfig($input: GameConfigUpdateInput, $id: ID!) {
    updateGameConfig(input: $input, id: $id) {
      _id
    }
  }
`;

export const CREATE_SPIN_THE_WHEEL_CONFIG_GQL = gql`
  mutation createSpinTheWheelConfig($input: SpinTheWheelConfigCreateInput) {
    createSpinTheWheelConfig(input: $input) {
      _id
    }
  }
`;

export const GET_SPIN_THE_WHEEL_CONFIG_GQL = gql`
  ${CORE_CANVAS_ELEMENT_FIELDS}
  query getSpinTheWheelConfig($id: ID!) {
    getSpinTheWheelConfig(id: $id) {
      _id
      sections {
        asset {
          url
          type
        }
        type
        probability
        background {
          style
        }
        content
      }
      description
      title
      start_btn {
        label
        style
      }
      assets {
        type
        asset {
          url
          type
        }
      }
      elements {
        ...CoreCanvasElementFields
      }
    }
  }
`;
export const UPDATE_SPIN_THE_WHEEL_CONFIG_GQL = gql`
  mutation updateSpinTheWheelConfig(
    $id: ID!
    $input: SpinTheWheelConfigUpdateInput
  ) {
    updateSpinTheWheelConfig(id: $id, input: $input) {
      _id
    }
  }
`;

export const GET_SPIN_THE_WHEEL_CONFIGS_GQL = gql`
  query getSpinTheWheelConfigs(
    $input: SpinTheWheelQueryInput
    $page: Int
    $limit: Int
  ) {
    getSpinTheWheelConfigs(input: $input, page: $page, limit: $limit) {
      data {
        _id
        description

        title
      }
      limit
      page
      total_items
    }
  }
`;
