/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { gameAnalyticsRoute } from '../../../../route/navigate';
import EditIcon from '../../../../assets/svg/Edit.svg';
import GameAssetsModal from '../../Modal/GameAssetsModal/GameAssetsModal';

export const gameAssetsColumn = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Id',
        accessor: '_id',
        Cell: ({ row }) => (
          <Link
            to={gameAnalyticsRoute(row.values._id)}
            className="text-[#11B954] body-2 cursor-pointer"
            target="_target"
          >
            {row.values._id}
          </Link>
        ),
      },
      {
        Header: 'Asset Type',
        accessor: 'asset_type',
      },
      {
        Header: 'Sub Asset Type',
        accessor: 'sub_asset_type',
      },
      {
        Header: 'Game Type',
        accessor: 'game_type',
      },

      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);

          const onToggleModal = () => {
            setIsModal(!isModal);
          };

          return (
            <div className="">
              <img
                id={row.values._id}
                src={EditIcon}
                alt=""
                onClick={() => onToggleModal()}
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
              />
              {isModal && (
                <GameAssetsModal
                  isModalOpen={isModal}
                  onToggleModal={onToggleModal}
                  id={row.original._id}
                  actionType="UPDATE"
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
