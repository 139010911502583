import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropType from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import GamesContent from '../Games/GamesContent';
import { gamesGetAction } from '../../redux/action/game';

const UserGames = ({ userId }) => {
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const games = useSelector(state => state.game.games);
  const [filterValue, setFilterValue] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageSizeCurrent, setPageSize] = useState(1);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      if (filterValue?.is_regex != undefined) {
        dispatch(gamesGetAction(filterValue, pageIndex, pageSize));
      }
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    setPageCount(Math.ceil(games?.total_items / pageSizeCurrent));
  }, [games, pageSizeCurrent]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    filter.author = {
      _id: userId,
    };
    setFilterValue(filter);
  }, [searchParam]);

  return (
    <GamesContent
      games={games}
      fetchData={fetchData}
      pageCount={pageCount}
      featureState={false}
      addBtnEnable={false}
      headerEnable={false}
    />
  );
};

UserGames.propTypes = {
  userId: PropType.string,
};
export default UserGames;
