import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import TableHeader from '../common/table/TableHeader';
import UserFilters from './UserFilters';
import UserTable from './UserTable';

const UserContent = ({ fetchData, pageCount }) => {
  const [isFilter, setFilter] = useState(false);
  const onToggleFilter = () => {
    setFilter(!isFilter);
  };
  return (
    <div className="w-full">
      <HeaderTitle pageTitle={'Users'} />
      <div className="mt-10">
        <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader
            FileName="Users"
            addButtonEnable={false}
            onToggleFilter={onToggleFilter}
          />
          <div
            className={`absolute w-[23rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
              isFilter ? 'block' : 'hidden'
            }`}
          >
            <UserFilters onToggleFilter={onToggleFilter} />
          </div>
          <UserTable fetchData={fetchData} pageCount={pageCount} />
        </div>
      </div>
    </div>
  );
};
UserContent.propTypes = {
  onPageLimitChange: PropTypes.func,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
};
export default UserContent;
