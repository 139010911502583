// import { apiClient } from '../../../service/apiClient';
import { reduxAction } from '../base';
import {
  CREATORS_INFO,
  CREATORS_GET_PENDING,
  CREATOR_CREATE_PENDING,
  CREATOR_UPDATE_PENDING,
} from './type';
import { apolloClient } from '../../../service/apolloClient';
import {
  GET_CREATORS_GQL,
  CREATE_NEW_CREATOR_GQL,
  UPDATE_CREATOR_GQL,
} from '../../query/creator';

export const creatorsGetAction = () => {
  return dispatch => {
    dispatch(reduxAction(CREATORS_GET_PENDING, true));
    apolloClient
      .query({ query: GET_CREATORS_GQL })
      .then(({ data }) => {
        const { getAllCreators: res } = data;
        dispatch(reduxAction(CREATORS_INFO, res));
        dispatch(reduxAction(CREATORS_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(CREATORS_GET_PENDING, false));
      });
  };
};

export const creatorCreateAction = value => {
  const mutation = CREATE_NEW_CREATOR_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATOR_CREATE_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(() => {
        dispatch(reduxAction(CREATOR_CREATE_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(CREATOR_CREATE_PENDING, false));
      });
  };
};
export const creatorUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_CREATOR_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATOR_UPDATE_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: value } })
      .then(({ data }) => {
        dispatch(reduxAction(CREATOR_UPDATE_PENDING, false));
        if (data) {
          if (cb) {
            cb();
          }
        }
      })
      .catch(() => {
        dispatch(reduxAction(CREATOR_UPDATE_PENDING, false));
      });
  };
};
