import PropTypes from 'prop-types';

const EngagementCard = ({ data, title }) => {
  const getTime = time => {
    if (!time) {
      return 0;
    }
    return Math.round(time / 1000);
  };
  const item =
    data?.type === 'ENGAGEMENT' ? getTime(data?.time) : data?.size || 0;
  return (
    <div className="py-4 px-8 shadow rounded border">
      <div className="flex items-center flex-col">
        <div className="">
          <p className="h6-thin text-gray-500">{title}</p>
        </div>
        <div className="h4-thin">{item}</div>
      </div>
    </div>
  );
};

EngagementCard.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

export default EngagementCard;
