import React, { useState } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import { inlineStyle } from '../../css/ModalCss/ModalCss';
import { CrossIcon } from '../common/svg/Icons';
import * as yup from 'yup';
import { nftCreateNftUtility } from '../../redux/action/nft';
import { useDispatch, useSelector } from 'react-redux';
import PropType from 'prop-types';

const schema = yup.object().shape({
  nft: yup.string().required('nft is required'),
  creator: yup.string().required('creator is required'),
  title: yup.string().required('title is required'),
  short_description: yup.string().required('short_description is required'),
  description: yup.string().required('description is required'),
  thumbnail: yup.object({
    url: yup.string().required('thumbnail url is required'),
    type: yup.string().required('thumbnail type is required'),
  }),
  cover: yup.object({
    url: yup.string().required('cover url is required'),
    type: yup.string().required('cover type is required'),
  }),
});

export const AddNftUtilityModal = ({
  isModal,
  onToggleModal,
  nftUtilityData,
  nftUtility,
}) => {
  const dispatch = useDispatch();
  const nfts = useSelector(state => state.nft.nfts);
  const [nft_creator, setNftCreator] = useState(nftUtility?.creator);

  const onChangeNftId = nftID => {
    nftUtilityData.forEach(item => {
      if (item.nft === nftID) {
        setNftCreator(item.creator);
      }
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nft: nftUtility?._id || nftUtilityData[0]?._id,
      creator: nftUtility?.creator || nftUtilityData[0]?.creator,
      title: nftUtility?.title || undefined,
      short_description: nftUtility?.short_description || undefined,
      description: nftUtility?.description || undefined,
      thumbnail: {
        url: nftUtility?.thumbnail?.url || undefined,
        type: 'IMAGE',
      },
      cover: {
        url: nftUtility?.cover?.url || undefined,
        type: 'IMAGE',
      },
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch(nftCreateNftUtility(values));
    },
  });

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={onToggleModal}
      ariaHideApp={false}
      style={inlineStyle}
      shouldCloseOnOverlayClick={true}
      nftUtilityData={nftUtilityData}
    >
      <div>
        <div className="relative w-full"></div>
        <div
          className="absolute right-0 cursor-pointer p-4"
          onClick={onToggleModal}
        >
          <CrossIcon />
        </div>
        <div className="flex justify-center items-center">
          <form onSubmit={formik.handleSubmit} className="w-full text-black">
            <div className="w-full flex flex-col justify-center items-center mt-20">
              <div className="w-full flex justify-evenly">
                <div className="w-1/2 mt-2 mx-4">
                  <div className="flex justify-between items-center">
                    <div className="text-slate-500 h7-thin">NFT</div>
                  </div>
                  <select
                    type="select"
                    name="nft"
                    placeholder="Type Here"
                    value={formik.values.nft}
                    onChange={formik.handleChange}
                    className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                    id="nft"
                  >
                    {nfts &&
                      nfts.map((value, index) => {
                        return (
                          <option
                            key={index}
                            value={value._id}
                            onClick={() => onChangeNftId(value._id)}
                            className="text-[#FE6125] h6-thin-sm"
                          >
                            {value.title}
                          </option>
                        );
                      })}
                  </select>

                  {formik.errors.nft && formik.touched.nft && (
                    <div className="text-red-500 caption my-0">
                      {formik.errors.nft}
                    </div>
                  )}
                </div>
                {/* Creator */}
                <div className="w-1/2 mt-2 mx-4">
                  <div className="flex justify-between items-center">
                    <div className="text-slate-500 h7-thin">Creator</div>
                  </div>
                  <input
                    type="text"
                    name="creator"
                    placeholder="Type Here"
                    value={nft_creator}
                    onChange={formik.handleChange}
                    className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                    id="creator"
                  />
                  <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  {formik.errors.creator && formik.touched.creator && (
                    <div className="text-red-500 caption my-0">
                      {formik.errors.creator}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-evenly mt-16">
                <div className="w-1/2 mt-2 mx-4">
                  <div className="flex justify-between items-center">
                    <div className="text-slate-500 h7-thin">Title</div>
                  </div>
                  <input
                    type="text"
                    name="title"
                    placeholder="Type Here"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                    id="title"
                  />
                  <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  {formik.errors.title && formik.touched.title && (
                    <div className="text-red-500 caption my-2">
                      {formik.errors.title}
                    </div>
                  )}
                </div>
                <div className="w-1/2 mt-2 mx-4">
                  <div className="flex justify-between items-center">
                    <div className="text-slate-500 h7-thin">
                      Short Description
                    </div>
                  </div>
                  <input
                    type="text"
                    name="short_description"
                    placeholder="Type Here"
                    value={formik.values.short_description}
                    onChange={formik.handleChange}
                    className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                    id="short_description"
                  />
                  <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  {formik.errors.short_description &&
                    formik.touched.short_description && (
                      <div className="text-red-500 caption my-2">
                        {formik.errors.short_description}
                      </div>
                    )}
                </div>
              </div>
              <div className="w-full flex justify-evenly mt-16">
                <div className="w-1/2 mt-2 mx-4">
                  <div className="flex justify-between items-center">
                    <div className="text-slate-500 h7-thin">ThumbnailUrl</div>
                  </div>
                  <input
                    type="text"
                    name="thumbnail.url"
                    placeholder="Type Here"
                    value={formik.values.thumbnail.url}
                    onChange={formik.handleChange}
                    className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                    id="thumbnail.url"
                  />
                  <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  {formik.errors.thumbnail?.url &&
                    formik.touched.thumbnail?.url && (
                      <div className="text-red-500 caption my-2">
                        {formik.errors.thumbnail?.url}
                      </div>
                    )}
                </div>
                <div className="w-1/2 mt-2 mx-4">
                  <div className="flex justify-between items-center">
                    <div className="text-slate-500 h7-thin">coverUrl</div>
                  </div>
                  <input
                    type="text"
                    name="cover.url"
                    placeholder="Type Here"
                    value={formik.values.cover.url}
                    onChange={formik.handleChange}
                    className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                    id="cover.url"
                  />
                  <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                  {formik.errors.cover?.url && formik.touched.cover?.url && (
                    <div className="text-red-500 caption my-2">
                      {formik.errors.cover?.url}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-1/2 mt-2 mx-4">
                <div className="flex justify-between items-center">
                  <div className="text-slate-500 h7-thin">Description</div>
                </div>
                <input
                  type="text"
                  name="description"
                  placeholder="Type Here"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                  id="description"
                />
                <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                {formik.errors.description && formik.touched.description && (
                  <div className="text-red-500 caption my-2">
                    {formik.errors.description}
                  </div>
                )}
              </div>
            </div>
            <div className="m-4 h-auto w-full flex justify-center items-center mt-10 ">
              <div className="mx-40 w-[80%] p-0.5 bg-gradient-to-r from-[#C537AE] via-[#8217DE] to-[#ff5800] rounded-lg cursor-pointer">
                <button
                  className="bg-blue-400 hover:bg-blue-500 w-full p-2 md:p-3 rounded-lg px-5 md:px-10  btn-text"
                  type="submit"
                >
                  submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

AddNftUtilityModal.propTypes = {
  isModal: PropType.bool,
  onToggleModal: PropType.func,
  nftUtilityData: PropType.array,
  nftUtility: PropType.object,
};
