/* eslint-disable react/prop-types */
import EditIcon from '../../../../assets/svg/Edit.svg';
import { useState } from 'react';
import NftAddModal from '../../../nft/NftAddModal';
import NftShowModal from '../../../nft/NftShowModal';
import { Link } from 'react-router-dom';
import { buyerRoute, nftUtilityRoute } from '../../../../route/navigate';

export const columnsNFT = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Nft Id',
        accessor: '_id',
        Cell: ({ row }) => (
          <div className="flex items-center">
            <Link
              to={nftUtilityRoute(row.values._id)}
              className="text-[#11B954] body-2 cursor-pointer"
              target="_self"
              rel="noreferrer"
            >
              <p className="body-1">{row.values._id}</p>
            </Link>
          </div>
        ),
      },
      {
        Header: 'Name',
        accessor: 'title',
      },
      {
        Header: 'Creator',
        accessor: 'creator',
      },
      {
        Header: 'Total Editions',
        accessor: 'total_editions',
      },
      {
        Header: 'Sold Editions',
        accessor: 'sold_editions',
      },
      {
        Header: 'Buyer',
        accessor: 'links',
        Cell: ({ row }) => (
          <Link
            to={buyerRoute(row.values._id)}
            className="text-[#11B954] body-2 cursor-pointer"
            target="_self"
          >
            Click
          </Link>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <button
            className={`rounded-xl px-5 py-1 ${
              row.values.status == 'ACTIVE'
                ? 'bg-[#29CC97]'
                : row.values.status == 'SOLD_OUT'
                ? 'bg-[#867e02]'
                : row.values.status == 'COMING_SOON'
                ? 'bg-[#2cad4d]'
                : 'bg-[#F12B2C]'
            } text-[#ffffff] uppercase`}
          >
            {row.values.status}
          </button>
        ),
      },
      {
        Header: 'View',
        accessor: 'view',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);

          const onToggleModal = () => {
            setIsModal(!isModal);
          };

          return (
            <div className="flex">
              <div
                className="text-[orange] cursor-pointer hover:text-[red]"
                onClick={() => setIsModal(true)}
              >
                view
              </div>
              <NftShowModal
                isModal={isModal}
                onToggleModal={onToggleModal}
                nft={row.original}
              />
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);

          const onToggleModal = () => {
            setIsModal(!isModal);
          };
          return (
            <div className="">
              <img
                src={EditIcon}
                alt=""
                onClick={() => setIsModal(true)}
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full  cursor-pointer"
              />
              {isModal && (
                <NftAddModal
                  isModal={isModal}
                  onToggleModal={onToggleModal}
                  nft={row.original}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
