export const transaction_type = [
  'SELL',
  'BUY',
  'SEND',
  'DONATE',
  'AIRDROP',
  'GATE_ACCESS',
  'NFT_BUY',
  'NFT_BIDDING',
  'TOURNAMENT_REGISTER',
  'WITHDRAWAL',
  'REWARD',
  'ASSET_BUY',
];
