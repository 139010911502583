import { gql } from '@apollo/client';

export const GET_CREATOR_NFT_GQL = gql`
  query getCreatorNft($creatorId: ID!) {
    getCreatorNft(creatorId: $creatorId) {
      _id
      title
      description
      total_editions
      sold_editions
      media {
        url
        type
      }
      status
    }
  }
`;

export const CREATE_NFT_GQL = gql`
  mutation Mutation($input: CreateNftInput!) {
    createNft(input: $input) {
      _id
      title
    }
  }
`;

export const GET_ALL_NFT_GQL = gql`
  query GetNfts($input: NftInputQuery, $page: Int, $limit: Int) {
    getNfts(input: $input, page: $page, limit: $limit) {
      data {
        _id
        title
        description
        total_editions
        sold_editions
        creator
        status
        price {
          currency
          amount
        }
        media {
          url
          type
        }
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_USER_NFT_GQL = gql`
  query getUserNft {
    getUserNft {
      _id
      nft {
        _id
        title
        media {
          type
          url
        }
      }
    }
  }
`;

export const CREATE_NFT_UTILITIES_GQL = gql`
  mutation CreateNftUtility($input: CreateNftUtilityInput!) {
    createNftUtility(input: $input) {
      _id
      nft
    }
  }
`;

export const GET_NFT_UTILITY_GQL = gql`
  query GetAllNftUtilities($page: Int, $limit: Int, $input: UtilityInputQuery) {
    getAllNftUtilities(page: $page, limit: $limit, input: $input) {
      data {
        _id
        nft
        creator
        title
        type
        short_description
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_NFT_UTILITIES = gql`
  query getNftUtilities($nft: ID!) {
    getNftUtilities(nft: $nft) {
      _id
      title
      description
      short_description
      type
      nft
      before_purchase_title
      after_purchase_title
      cover {
        type
        url
      }
      thumbnail {
        type
        url
      }
    }
  }
`;

export const GET_NFT_FANCLUB_GQL = gql`
  query getNftFanClub($nftId: ID!) {
    getNftFanClub(nftId: $nftId) {
      _id
      user {
        _id
        name
        email
        mobile_number
        username
        thumbnail
      }
      nft {
        theme
      }
    }
  }
`;

export const UPDATE_NFT_GQL = gql`
  mutation UpdateNft($id: ID!, $input: NftUpdatesInput) {
    updateNft(id: $id, input: $input) {
      title
      description
    }
  }
`;

export const DELETE_NFT_GQL = gql`
  mutation DeleteNft($id: ID!) {
    deleteNft(id: $id) {
      title
      _id
      description
    }
  }
`;
export const EXPORT_NFT_BUYER_GQL = gql`
  query ExportUserToNft($input: ExportUserToNftQueryInput) {
    exportUserToNft(input: $input) {
      data
      type
    }
  }
`;
