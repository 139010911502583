import React from 'react';
import { Styles } from '../../css/table';
import TableHeader from '../common/table/TableHeader';
import { columnDiscord } from '../common/table/TableColumns/discord';
import PropType from 'prop-types';
import { TablePagination } from '../common/table/tablePagination';
const DiscordContent = ({ discordRoles, fetchData, pageCount }) => {
  return (
    <div>
      <div className="w-full">
        <div className="flex justify-end mx-20 mt-10"></div>
        <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader FileName={'Discord Role'} modalOpenfunc={null} />

          <Styles>
            <TablePagination
              columns={columnDiscord}
              data={discordRoles?.data || []}
              total_item={discordRoles?.total_items}
              fetchData={fetchData}
              pageCount={pageCount}
              className="rounded"
            />
          </Styles>
        </div>
      </div>
    </div>
  );
};
DiscordContent.propTypes = {
  discordRoles: PropType.object,
  fetchData: PropType.func,
  pageCount: PropType.number,
};
export default DiscordContent;
