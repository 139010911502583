import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import { gameAssetsGetAction } from '../../redux/action/gameAsset';
import GameAssetsContent from '../../component/GameAssets/GameAssetsContent';

const GameAssetsContainer = () => {
  const [filterValue, setFilter] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const dispatch = useDispatch();

  const isGameAssetsGetPending = useSelector(
    state => state.gameAssets.isGameAssetsGetPending
  );

  const [searchParam] = useSearchParams();
  const { gameAssets } = useSelector(state => state.gameAssets);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      dispatch(gameAssetsGetAction(filterValue, pageIndex, pageSize));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (gameAssets)
      setPageCount(Math.ceil(gameAssets?.total_items / pageSize1));
  }, [gameAssets, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);

    setFilter(filter);
  }, [searchParam]);

  return (
    <>
      <GameAssetsContent pageCount={pageCount} fetchData={fetchData} />
      <RingSpinner isLoading={isGameAssetsGetPending} />
    </>
  );
};

export default GameAssetsContainer;
