import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
const JsonEditor = ({ code, onChange }) => {
  return (
    <AceEditor
      placeholder="Placeholder Text"
      mode="json"
      theme="github"
      width="100%"
      name="jsonEditor"
      onChange={onChange}
      fontSize={12}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={code}
      className="min-h-[600px]"
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
};

JsonEditor.propTypes = {
  code: PropTypes.object,
  onChange: PropTypes.func,
};
export default JsonEditor;
