import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { createGameModalStyle } from '../../../../css/ModalCss/ModalCss';
import { CrossIcon } from '../../svg/Icons';
import PropTypes from 'prop-types';
import { toastAction } from '../../../../redux/action/toastAction';
import { useDispatch } from 'react-redux';

import JsonEditor from '../../Editor/JsonEditor';
import {
  userGetByIdAction,
  userUpdateAction,
} from '../../../../redux/action/user';

const CreateUserModal = ({
  isModalOpen,
  onToggleModal,
  userId,
  actionType = 'CREATE',
}) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(undefined);
  const [editorJson, setEditorJson] = useState(undefined);
  const [error, setError] = useState(undefined);

  const onUpdateGame = () => {
    dispatch(userUpdateAction(userId, user, onToggleModal));
  };
  // eslint-disable-next-line no-unused-vars
  const onCreateGame = value => {};
  const onCallBack = data => {
    let temp = { ...data };
    delete temp['_id'];
    setUser(temp);
    var str = JSON.stringify(temp, undefined, 4);
    setEditorJson(str);
    document.getElementById('game_json_editor').innerHTML = str;
  };
  const onGetGame = () => {
    dispatch(userGetByIdAction(userId, onCallBack));
  };
  const onChangeCreateGame = inputData => {
    let data;
    setEditorJson(inputData);
    try {
      data = JSON.parse(inputData);
      setError(undefined);
    } catch (err) {
      const errMsg = JSON.stringify(err?.message || err);
      setError(errMsg);
    }
    setUser(data);
  };
  const onSubmit = () => {
    if (error) {
      toastAction.error('Please resolve the error');
      return;
    }
    if (actionType === 'UPDATE' && userId) {
      onUpdateGame();
      return;
    }
    onCreateGame(userId);
  };

  useEffect(() => {
    if (userId && actionType === 'UPDATE') {
      onGetGame();
    }
  }, [userId]);
  return (
    <ReactModal
      isOpen={isModalOpen}
      ariaHideApp={false}
      overlayClassName="f-modal-overlay"
      shouldCloseOnOverlayClick={true}
      style={createGameModalStyle}
      className=""
    >
      <div className="relative w-full h-full text-black">
        <div className="flex w-full justify-between">
          <div className="h5-thin">
            {actionType === 'UPDATE' ? 'Update' : 'Create'} User
          </div>
          <div className="flex justify-end cursor-pointer p-2 ">
            <div onClick={() => onToggleModal()}>
              <CrossIcon />
            </div>
          </div>
        </div>
        <div className="mt-4">
          {error && <p className="caption text-red-400">{error}</p>}
          <p className="rounded-lg">
            <JsonEditor code={editorJson} onChange={onChangeCreateGame} />
          </p>
        </div>
        <div className="w-full flex justify-end mt-4">
          <div
            onClick={() => onToggleModal()}
            className="cursor-pointer p-2 px-4 border-[1px] border-black body-2 bg-slate-200 rounded-lg mx-2"
          >
            close
          </div>
          <div
            className="cursor-pointer p-2 px-4 bg-green-800 rounded-lg body-2 text-white"
            onClick={onSubmit}
          >
            {actionType === 'CREATE' ? 'create' : 'update'}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

CreateUserModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onToggleModal: PropTypes.func,
  userId: PropTypes.string,
  actionType: PropTypes.string,
};
export default CreateUserModal;
