import { gql } from '@apollo/client';

export const GET_ALL_TRANSACTION_GQL = gql`
  query getTransactions(
    $page: Int
    $limit: Int
    $input: TransactionInputQuery
  ) {
    getTransactions(page: $page, limit: $limit, input: $input) {
      data {
        amount
        _id
        nft
        description
        transaction_type
        input_type
        author {
          _id
          name
        }
        status
        updatedAt
        bid {
          bid_status
        }
        detail {
          upi
        }
        is_active
        createdAt
      }
      total_items
      page
      limit
    }
  }
`;

export const CREATE_TRANSACTION_GQL = gql`
  mutation Mutation($input: createTransactionInput) {
    createTransaction(input: $input) {
      payment_link
    }
  }
`;

export const UPDATE_TRANSACTION_GQL = gql`
  mutation UpdateTransaction($id: ID!, $input: TransactionUpdateInput) {
    updateTransaction(id: $id, input: $input) {
      _id
      author {
        name
        thumbnail
        _id
      }
      input_type
      transaction_type
      nft
      amount
      description
      status
      bid {
        bid_status
      }
      is_active
    }
  }
`;
