import { gql } from '@apollo/client';

export const WALLET_UPDATE = gql`
  mutation UpdateWallet($input: WalletUpdateInput!, $updateWalletId: ID!) {
    updateWallet(input: $input, id: $updateWalletId) {
      _id
      balance {
        amount
        currency
      }
      user
    }
  }
`;
