import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Styles } from '../../../css/table';
import { TablePagination } from '../../common/table/tablePagination';
import { memoryMatchConfigColumn } from '../../common/table/TableColumns/memoryMatchConfig';

const MemoryMatchTable = ({ pageCount, fetchData }) => {
  const { memoryMatchConfigs } = useSelector(state => state.gameConfig);

  return (
    <div className="w-full h-full">
      <Styles>
        <TablePagination
          columns={memoryMatchConfigColumn}
          data={memoryMatchConfigs?.data || []}
          total_item={memoryMatchConfigs?.total_items}
          fetchData={fetchData}
          pageCount={pageCount}
          className="rounded"
        />
      </Styles>
    </div>
  );
};
MemoryMatchTable.propTypes = {
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
};
export default MemoryMatchTable;
