import React, { useState } from 'react';
import NftAddModal from './NftAddModal';
import { Styles } from '../../css/table';
import TableHeader from '../common/table/TableHeader';
import PropType from 'prop-types';
import { TablePagination } from '../common/table/tablePagination';

export const NftContent = ({
  nftData,
  addButtonState,
  featureState,
  columnData,
  pageCount,
  filterValue,
  fetchData,
}) => {
  const [isModal, setModal] = useState(false);
  const onToggleModal = () => {
    setModal(!isModal);
  };

  return (
    <div className="w-full">
      <div className="flex justify-end mx-20 mt-10"></div>
      <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
        <TableHeader
          FileName={'Nft'}
          modalOpenfunc={onToggleModal}
          addButtonEnable={addButtonState}
          featureEnable={featureState}
        />

        <Styles>
          <TablePagination
            columns={columnData}
            data={nftData?.data || []}
            pageCount={pageCount}
            filterValue={filterValue}
            total_item={nftData?.total_items}
            fetchData={fetchData}
            className="rounded"
          />
        </Styles>

        {isModal && (
          <NftAddModal isModal={isModal} onToggleModal={onToggleModal} />
        )}
      </div>
    </div>
  );
};

NftContent.propTypes = {
  nftData: PropType.object,
  featureState: PropType.bool,
  addButtonState: PropType.bool,
  columnData: PropType.array,
  filterValue: PropType.object,
  fetchData: PropType.func,
  pageCount: PropType.number,
};
