import { apolloClient } from '../../../service/apolloClient';
import { GET_GAME_LEADERBOARD_GQL } from '../../query/leaderboard';
import { reduxAction } from '../base';
import { LEADERBOARD_INFO, GET_LEADERBOARD_PENDING } from './type';

export const getLeaderboard = (value, page, limit) => {
  return dispatch => {
    dispatch(reduxAction(GET_LEADERBOARD_PENDING, true));
    apolloClient
      .query({
        query: GET_GAME_LEADERBOARD_GQL,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getGameLeaderboard: res } = data;

        dispatch(reduxAction(LEADERBOARD_INFO, res));
        dispatch(reduxAction(GET_LEADERBOARD_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(GET_LEADERBOARD_PENDING, false));
      });
  };
};
