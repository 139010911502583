import { PLAY_RHYM_URL } from '../constants/constant';

export const userProfileRoute = userId => {
  if (userId) {
    return `/users/${userId}`;
  }
  return `/users/:userId`;
};

export const nftUtilityRoute = nftUtilityId => {
  if (nftUtilityId) {
    return `/nftUtility?nftUtilityId=${nftUtilityId}`;
  }
  return `/nftUtility`;
};

export const buyerRoute = buyerId => {
  if (buyerId) {
    return `/buyer/${buyerId}`;
  }
  return `/buyer/:buyerId`;
};

export const creatorNftRoute = creatorID => {
  if (creatorID) {
    return `/nft?creator=${creatorID}`;
  }
  return `/nft`;
};

export const gameAnalyticsRoute = gameId => {
  if (gameId) {
    return `/game/analytics/${gameId}`;
  }
  return `/game/analytics/:gameId`;
};

export const getGameAnalyticsRoute = gameId => {
  return PLAY_RHYM_URL + `/game/analytics?game=${gameId}`;
};
