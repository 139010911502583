import { gql } from '@apollo/client';

// fragment
export const CORE_CANVAS_ELEMENT_FIELDS = gql`
  fragment CoreCanvasElementFields on CanvasElement {
    _id
    backgroundColor
    backgroundOpacity
    borderRadius
    borderStyle
    borderWidth
    borderColor
    height
    width
    x
    y
    rotation
    opacity
    type
    src
    text
  }
`;
