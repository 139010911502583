import {
  GAMES_ANALYTICS,
  GAMES_ANALYTICS_GET_PENDING,
  GAME_ENGAGEMENT,
  GET_GAME_ENGAGEMENT_PENDING,
} from '../action/gameAnalytics/type';

const initialPendingState = {
  isGameEngagementGetPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  gameEngagement: null,
  gamesAnalytics: null,
};

export const gameAnalyticsReducer = (
  state = DEFAULT_STATE,
  { type, payload }
) => {
  switch (type) {
    case GAME_ENGAGEMENT:
      return {
        ...state,
        gameEngagement: payload,
      };
    case GAMES_ANALYTICS:
      return {
        ...state,
        gamesAnalytics: payload,
      };

    case GET_GAME_ENGAGEMENT_PENDING:
      return {
        ...state,
        isGameEngagementGetPending: payload,
      };
    case GAMES_ANALYTICS_GET_PENDING:
      return {
        ...state,
        isGamesAnalyticsGetPending: payload,
      };
    default: {
      return state;
    }
  }
};
