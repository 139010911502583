import { useEffect, useState } from 'react';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import AssessmentContent from './Assessment/AssessmentContent';
import JIgsawPuzzleContent from './JigsawPuzzle/JigsawPuzzleContent';
import MemoryMatchContent from './MemoryMatch/MemoryMatchContent';
import SkillContent from './Skill/SkillContent';
import { useNavigate } from 'react-router-dom';
import SpinTheWheelContent from './SpinTheWheel/SpinTheWheelContent';

const TabContent = () => {
  const [currentTab, setCurrentTab] = useState('Skill');
  const tabs = [
    'Skill',
    'Assessment',
    'Memory Match',
    'Jigsaw Puzzle',
    'Spin The Wheel',
  ];
  const navigate = useNavigate();
  const renderTabContainer = () => {
    switch (currentTab) {
      case 'Skill':
        return <SkillContent />;
      case 'Assessment':
        return <AssessmentContent />;
      case 'Memory Match':
        return <MemoryMatchContent />;
      case 'Jigsaw Puzzle':
        return <JIgsawPuzzleContent />;
      case 'Spin The Wheel':
        return <SpinTheWheelContent />;
    }
  };
  const onClearSearchQuery = () => {
    navigate({
      search: '',
    });
  };
  useEffect(() => {
    onClearSearchQuery();
  }, [currentTab]);
  return (
    <div className="w-full">
      <HeaderTitle pageTitle={currentTab} />
      <div className="w-full flex justify-center items-center mt-8">
        {tabs.map((tab, index) => {
          return (
            <div
              className={`h-8 w-40  flex justify-center items-center mx-2   cursor-pointer rounded-lg ${
                currentTab == tab
                  ? 'bg-slate-700 text-white'
                  : 'bg-slate-300 hover:bg-slate-200'
              }`}
              key={index}
              id={tab}
              onClick={() => {
                setCurrentTab(tab);
              }}
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px] mt-2">
        <div className="">{renderTabContainer()}</div>
      </div>
    </div>
  );
};

export default TabContent;
