import { gql } from '@apollo/client';

export const GET_GAME_ASSETS_GQL = gql`
  query GetGameAssets($input: GameAssetQueryInput, $page: Int, $limit: Int) {
    getGameAssets(input: $input, page: $page, limit: $limit) {
      data {
        _id
        root_game
        asset_type
        sub_asset_type
        url
        file_path
        game_type
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_GAME_ASSET_GQL = gql`
  query GetGameAsset($id: ID!) {
    getGameAsset(id: $id) {
      _id
      root_game
      asset_type
      sub_asset_type
      url
      file_path
      game_type
      is_active
      is_private
      author {
        _id
      }
    }
  }
`;

export const UPDATE_GAME_ASSET_GQL = gql`
  mutation UpdateGameAsset($id: ID!, $input: GameAssetUpdateInput) {
    updateGameAsset(id: $id, input: $input) {
      _id
    }
  }
`;
export const CREATE_GAME_ASSET_GQL = gql`
  mutation CreateGameAsset($input: GameAssetCreateInput) {
    createGameAsset(input: $input) {
      _id
    }
  }
`;
