import { reduxAction } from '../base';

import {
  USER_OTP_PENDING,
  USER_INFO_GET_PENDING,
  USER_INFO,
  USER_OTP_VERIFICATION_PENDING,
  USER_LOGOUT,
  USER_REGISTER_STATUS,
  USER_LOGIN_MODAL,
  GET_USER_NFT,
  USER_NFT,
  GET_USER_DISCORD,
  USER_DISCORD,
  GET_USER_WALLET,
  USER_WALLET,
  GET_WALLETS,
  GET_WALLETS_PENDING,
  USER_SEND_REWARD_PENDING,
} from './type';

import { toastAction } from '../toastAction';
import {
  GET_DISCORD_BY_USER,
  GET_NFT_BY_USER,
  GET_USER_BY_ID_GQL,
  GET_USER_GQL,
  GET_USER_WALLET_GQL,
  GET_WALLETS_GQL,
  LOGIN_GQL,
  SEND_USER_REWARD_GQL,
  UPDATE_USER_GQL,
  VERIFY_ADMIN_GQL,
} from '../../query/user';
import { apolloClient } from '../../../service/apolloClient';

export const verifyAdminAction = (value, cb) => {
  const mutation = VERIFY_ADMIN_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_OTP_VERIFICATION_PENDING, true));

    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { verifyAdminOtp: res } = data;
        if (!res) return;
        dispatch(reduxAction(USER_INFO, res));
        localStorage.setItem('jwtToken', res.token);
        if (cb) {
          cb();
        }
        toastAction.success('Verified');
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(USER_OTP_VERIFICATION_PENDING, false));
      });
  };
};
export const userGetNft = (page, limit, value) => {
  const query = GET_NFT_BY_USER;
  return dispatch => {
    dispatch(reduxAction(GET_USER_NFT, true));
    apolloClient
      .query({ query, variables: { page, limit, input: value } })
      .then(({ data }) => {
        const { getUserToNfts: res } = data;

        dispatch(reduxAction(USER_NFT, res));
        dispatch(reduxAction(GET_USER_NFT, false));
      })
      .catch(err => {
        dispatch(reduxAction(GET_USER_NFT, false));
        toastAction.error(err);
      });
  };
};

export const userGetDiscord = (page, limit, value) => {
  const query = GET_DISCORD_BY_USER;
  return dispatch => {
    dispatch(reduxAction(GET_USER_DISCORD, true));
    apolloClient
      .query({ query, variables: { page, limit, input: value } })
      .then(({ data }) => {
        const { getSocialLinks: res } = data;
        dispatch(reduxAction(USER_DISCORD, res));
        dispatch(reduxAction(GET_USER_DISCORD, false));
      })
      .catch(err => {
        dispatch(reduxAction(GET_USER_DISCORD, false));
        toastAction.error(err);
      });
  };
};
export const userGetWallet = value => {
  const query = GET_USER_WALLET_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_USER_WALLET, true));
    apolloClient
      .query({ query, variables: value })
      .then(({ data }) => {
        const { getUserWallet: res } = data;
        dispatch(reduxAction(USER_WALLET, res));
        dispatch(reduxAction(GET_USER_WALLET, false));
      })
      .catch(err => {
        dispatch(reduxAction(GET_USER_WALLET, false));
        toastAction.error(err);
      });
  };
};
export const userGetAction = () => {
  const query = GET_USER_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_INFO_GET_PENDING, true));
    apolloClient
      .query({ query })
      .then(({ data }) => {
        const { getUser: res } = data;
        dispatch(reduxAction(USER_INFO, res));
        if (res === null) {
          dispatch(reduxAction(USER_LOGOUT));
        } else {
          if (res.username) {
            dispatch(reduxAction(USER_REGISTER_STATUS, true));
          } else {
            dispatch(reduxAction(USER_REGISTER_STATUS, false));
          }
        }
        dispatch(reduxAction(USER_INFO_GET_PENDING, false));
      })
      .catch(err => {
        if (err.response && err.response.statusText === 'Unauthorized') {
          dispatch(reduxAction(USER_LOGOUT));
        }
        dispatch(reduxAction(USER_INFO_GET_PENDING, false));
      });
  };
};
export const userGetByIdAction = (id, cb) => {
  const query = GET_USER_BY_ID_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_INFO_GET_PENDING, true));
    apolloClient.query({ query, variables: { id } }).then(({ data }) => {
      const { getUserById: res } = data;
      if (cb) cb(res);
    });
  };
};
export const userLogout = callback => {
  return dispatch => {
    localStorage.clear();
    dispatch(reduxAction(USER_LOGOUT));
    if (callback) {
      callback();
    }
  };
};

export const userSendOtpAction = (data, callback) => {
  const mutation = LOGIN_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_OTP_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: data } })
      .then(() => {
        dispatch(reduxAction(USER_OTP_PENDING, false));
        toastAction.success('Otp has been sent');
        if (callback) {
          callback(data);
        }
      })
      .catch(err => {
        toastAction.error(err);

        dispatch(reduxAction(USER_OTP_PENDING, false));
      });
  };
};

export const userLoginModalOpenAction = value => {
  return dispatch => {
    dispatch(reduxAction(USER_LOGIN_MODAL, value));
  };
};
export const getWalletsAction = (page, limit, value) => {
  const query = GET_WALLETS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_WALLETS_PENDING, true));
    apolloClient
      .query({ query, variables: { input: value, page, limit } })
      .then(({ data }) => {
        const { getWallets: res } = data;
        dispatch(reduxAction(GET_WALLETS, res));
        dispatch(reduxAction(GET_WALLETS_PENDING, false));
      })
      .catch(err => {
        dispatch(reduxAction(GET_WALLETS_PENDING, false));
        toastAction.error(err);
      });
  };
};

export const userSendRewardAction = (data, callback) => {
  const mutation = SEND_USER_REWARD_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_SEND_REWARD_PENDING, true));

    apolloClient
      .mutate({ mutation, variables: { input: data } })
      .then(() => {
        toastAction.success('Updated Successfully');
        if (callback) {
          callback();
        }
        dispatch(reduxAction(USER_SEND_REWARD_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);

        dispatch(reduxAction(USER_SEND_REWARD_PENDING, false));
      });
  };
};

export const userUpdateAction = (id, data, callback) => {
  const mutation = UPDATE_USER_GQL;
  return dispatch => {
    dispatch(reduxAction(USER_SEND_REWARD_PENDING, true));

    apolloClient
      .mutate({ mutation, variables: { id, input: data } })
      .then(() => {
        toastAction.success('Updated Successfully');
        if (callback) {
          callback();
        }
        dispatch(reduxAction(USER_SEND_REWARD_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);

        dispatch(reduxAction(USER_SEND_REWARD_PENDING, false));
      });
  };
};
