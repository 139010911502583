import React from 'react';
import { columnSocial } from '../common/table/TableColumns/social';
import PropType from 'prop-types';
import { Styles } from '../../css/table';
import TableHeader from '../common/table/TableHeader';
import { TablePagination } from '../common/table/tablePagination';

const SocialTable = ({
  socials,
  featureState,
  addButtonState,
  pageCount,
  filterValue,
  fetchData,
}) => {
  return (
    <div>
      <div className="w-full">
        <div className="flex justify-end mx-20 mt-10"></div>
        <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader
            FileName={'Discord'}
            addButtonEnable={addButtonState}
            featureEnable={featureState}
          />

          <Styles>
            <TablePagination
              pageCount={pageCount}
              columns={columnSocial}
              total_item={socials?.total_items}
              filterValue={filterValue}
              fetchData={fetchData}
              data={socials?.data || []}
            />
          </Styles>
        </div>
      </div>
    </div>
  );
};

export default SocialTable;
SocialTable.propTypes = {
  socials: PropType.object,
  fetchData: PropType.func,
  filterValue: PropType.object,
  pageCount: PropType.number,
  featureState: PropType.bool,
  addButtonState: PropType.bool,
};
