import React from 'react';
import ReactModal from 'react-modal';
import DeleteIcon from '../../../../assets/svg/Delete.svg';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import * as yup from 'yup';
import {
  creatorCreateAction,
  creatorsGetAction,
  creatorUpdateAction,
} from '../../../../redux/action/creator/index';
import { useDispatch } from 'react-redux';
import CrossIcon from '../../../../assets/svg/close.svg';
import PropType from 'prop-types';

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  banner: yup.string().required(),
  thumbnail: yup.string().required(),
});

const CreatorModal = ({ isModalOpen, setIsModalOpen, creator }) => {
  const dispatch = useDispatch();
  const callback = value => {
    dispatch(creatorCreateAction(value));
    setIsModalOpen(!isModalOpen);
    dispatch(creatorsGetAction());
  };
  const onUpdate = () => {
    dispatch(creatorsGetAction());
    setIsModalOpen(!isModalOpen);
  };
  creator?.social_links.forEach(object => {
    delete object['__typename'];
  });
  const formik = useFormik({
    initialValues: {
      name: creator?.name || '',
      description: creator?.description || '',
      banner: creator?.banner || '',
      thumbnail: creator?.thumbnail || '',
      type: 'CREATOR',
      social_links: creator?.social_links || '',
    },
    validationSchema: schema,
    onSubmit: value => {
      const { social_links } = value;
      const creatorInfo = { ...value };
      social_links.length === 0
        ? delete creatorInfo.social_links
        : creatorInfo.social_links;
      if (creator) {
        dispatch(creatorUpdateAction(creator._id, creatorInfo, onUpdate));
      } else {
        callback(creatorInfo);
      }
    },
  });

  return (
    <ReactModal
      isOpen={isModalOpen}
      ariaHideApp={false}
      overlayClassName="f-modal-overlay"
      shouldCloseOnOverlayClick={true}
      className="creator-modal overflow-y-scroll"
    >
      <div>
        <div>
          <img
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="w-5 ml-auto mt-1 cursor-pointer"
            src={CrossIcon}
            alt="cross"
          />
        </div>
        <div className="mt-3 text-center">
          <h4 className="h6-thin h6-sm-thin text-[#000000]">
            {creator ? 'Edit Creator' : 'Add New Creator'}
          </h4>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-4 mb-4 w-full text-left">
              <label htmlFor="name" className="text-[#9FA2B4] mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                onChange={formik.handleChange}
                defaultValue={formik.values.name || ''}
                className="border-[#9FA2B4] px-2 focus:ring-0 outline-none rounded-lg pb-0.5 lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] text-[#9fa2b4]"
              />
              {formik.errors.name && (
                <p className="text-left mt-3 text-[#F12B2C] capitalize">
                  {formik.errors.name}
                </p>
              )}
            </div>
            <div className="mt-4 mb-4 text-left">
              <label htmlFor="thumbnail" className="text-[#9FA2B4] mb-2">
                Thumbnail URL
              </label>
              <input
                type="text"
                name="thumbnail"
                id="thumbnail"
                onChange={formik.handleChange}
                defaultValue={formik.values.thumbnail || ''}
                className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5 lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
              />
              {formik.errors.thumbnail && (
                <p className="text-left mt-3 text-[#F12B2C] capitalize">
                  {formik.errors.thumbnail}
                </p>
              )}
            </div>
            <div className="mt-4 mb-4 text-left">
              <label htmlFor="banner" className="text-[#9FA2B4] mb-2">
                Banner URL
              </label>
              <input
                type="text"
                name="banner"
                id="banner"
                onChange={formik.handleChange}
                defaultValue={formik.values.banner || ''}
                className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5 lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
              />
              {formik.errors.banner && (
                <p className="text-left mt-3 text-[#F12B2C] capitalize">
                  {formik.errors.banner}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center mb-2">
              <div>
                <label className="text-[#9FA2B4]">Type</label>
              </div>
              <div>
                <select
                  onChange={formik.handleChange}
                  value={formik.values.type || ''}
                  name="type"
                  className="rounded-lg border-[#9FA2B4] border-2 text-[#9fa2b4]"
                >
                  <option name="CREATOR" value="CREATOR">
                    Creator
                  </option>
                  <option name="RHYM" value="RHYM">
                    Rhym
                  </option>
                </select>
              </div>
            </div>
            <div>
              <p className="text-[#9FA2B4] mb-2 text-left ">Social Media</p>
              <FormikProvider value={formik}>
                <FieldArray name="social_links">
                  {({ push, remove }) => (
                    <div>
                      {formik.values.social_links.length > 0 &&
                        formik.values.social_links.map((_, idx) => (
                          <div
                            className="mt-4 mb-4 flex items-center justify-between w-full"
                            key={idx}
                          >
                            <div className="w-full">
                              <select
                                onChange={e => {
                                  formik.setFieldValue(
                                    `social_links.${idx}.type`,
                                    e.target.value
                                  );
                                }}
                                value={
                                  formik.values.social_links[idx].type ||
                                  'defaultOpt'
                                }
                                name={`social_links.${idx}.type`}
                                className="w-full rounded-lg border-[#9FA2B4] border-2 text-[#9fa2b4]"
                              >
                                <option value="defaultOpt">
                                  Select Social Media
                                </option>
                                <option value="INSTAGRAM" name="INSTAGRAM">
                                  instagram
                                </option>
                                <option value="YOUTUBE" name="YOUTUBE">
                                  youtube
                                </option>
                                <option value="FACEBOOK" name="FACEBOOK">
                                  Facebook
                                </option>
                                <option value="TWITTER" name="TWITTER">
                                  Twitter
                                </option>
                              </select>
                            </div>
                            <span className="m-1"></span>
                            <div className="w-full">
                              <input
                                type="text"
                                placeholder="URL"
                                name={`social_links.${idx}.link`}
                                value={
                                  formik.values.social_links[idx].link || ''
                                }
                                onChange={e => {
                                  formik.setFieldValue(
                                    `social_links.${idx}.link`,
                                    e.target.value
                                  );
                                }}
                                className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                              />
                            </div>
                            <div className="" onClick={() => remove(idx)}>
                              <img
                                src={DeleteIcon}
                                alt=""
                                className="w-20 bg-[#F4F5FF] hover:bg-[#dadbe6] rounded-full p-3 ml-2 cursor-pointer"
                              />
                            </div>
                          </div>
                        ))}
                      <div className="w-fit ml-auto">
                        <button
                          type="button"
                          onClick={() => push({})}
                          className="ml-auto w-fit rounded-lg bg-[#D9D9D9] text-[#9fa2b4] px-3 py-2"
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </FormikProvider>
            </div>
            <div className="mt-4 mb-4 text-left">
              <label htmlFor="description" className="text-[#9FA2B4] mb-2">
                Description
              </label>
              <textarea
                name="description"
                id="description"
                onChange={formik.handleChange}
                value={formik.values.description || ''}
                className="border-[#9FA2B4] px-2 focus:ring-0 outline-none rounded-lg pb-0.5 lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] resize-none text-[#9fa2b4]"
              ></textarea>
              {formik.errors.description && (
                <p className="text-left mt-3 text-[#F12B2C] capitalize">
                  {formik.errors.description}
                </p>
              )}
            </div>
            <div className="mx-auto w-full cursor-pointer mt-6 2xl:mt-10">
              <button
                type="submit"
                className="w-full bg-[#3751FF] py-3 rounded-lg px-5 h6-thin h6-sm-thin"
              >
                {creator ? 'Update Creator' : 'Add Creator'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
};

CreatorModal.propTypes = {
  isModalOpen: PropType.bool,
  setIsModalOpen: PropType.func,
  creator: PropType.object,
};

export default CreatorModal;
