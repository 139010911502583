import { apolloClient } from '../../../service/apolloClient';
import {
  CREATE_GAME_GQL,
  EXPORT_GAME_LEADS_GQL,
  GET_GAMES_GQL,
  GET_GAME_GQL,
  GET_GAME_LEADS_GQL,
  UPDATE_GAME_GQL,
} from '../../query/game';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import {
  CREATE_GAME_PENDING,
  EXPORT_GAME_LEADS_PENDING,
  GAMES,
  GAME_LEADS,
  GET_GAMES_PENDING,
  GET_GAME_LEAD_PENDING,
  UPDATE_GAME_PENDING,
} from './type';

export const gamesGetAction = (value, page, limit) => {
  value.platform = 'ADMIN';
  return dispatch => {
    dispatch(reduxAction(GET_GAMES_PENDING, true));
    apolloClient
      .query({
        query: GET_GAMES_GQL,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getAllGames: res } = data;

        dispatch(reduxAction(GAMES, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_GAMES_PENDING, false));
      });
  };
};
export const gameGetAction = (gameId, cb) => {
  const query = GET_GAME_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAMES_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: gameId },
      })
      .then(({ data }) => {
        const { getGameById: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      });
  };
};
export const gameLeadsGetAction = (value, page, limit) => {
  return (dispatch, getState) => {
    dispatch(reduxAction(GET_GAME_LEAD_PENDING, true));
    apolloClient
      .query({
        query: GET_GAME_LEADS_GQL,
        variables: { input: value, page, limit },
      })
      .then(({ data }) => {
        const { getGameLeads: res } = data;
        let leads = getState().game.gameLeads;
        if (page > 0 && leads && leads?.data?.length > 0) {
          leads.data = [...leads.data, ...res.data];
        } else {
          leads = res;
        }
        leads.currentData = res?.data;
        dispatch(reduxAction(GAME_LEADS, leads));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_GAME_LEAD_PENDING, false));
      });
  };
};

export const gameUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_GAME_GQL;

  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: value } })
      .then(({ data }) => {
        const { updateGame: res } = data;
        if (!res) return;
        toastAction.success('Game Updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_PENDING, false)));
  };
};
export const gameCreateAction = (value, cb) => {
  const mutation = CREATE_GAME_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createGame: res } = data;
        if (!res) return;
        toastAction.success('Game created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_PENDING, false)));
  };
};
export const exportGameLeadsAction = input => {
  const query = EXPORT_GAME_LEADS_GQL;
  return dispatch => {
    dispatch(reduxAction(EXPORT_GAME_LEADS_PENDING, true));
    apolloClient
      .query({ query, variables: { input } })
      .then(({ data }) => {
        const { exportGameLeads: res } = data;
        if (res && res.data) {
          const url = `data:image/${res.type};base64,${res.data}`;
          let el = document.createElement('a');
          el.href = url;
          el.download = `game_leads.${res.type}`;
          el.click();
          el.remove();
        } else {
          toastAction.error('Data not found');
        }
        dispatch(reduxAction(EXPORT_GAME_LEADS_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(EXPORT_GAME_LEADS_PENDING, false));
      });
  };
};
