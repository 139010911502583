import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LeftArrow from '../../../assets/svg/LeftArrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../../redux/action/user';
import RhymFull from '../../../assets/svg/RhymFull.svg';
import RhymSmall from '../../../assets/svg/RhymSmall.svg';
import CreatorsLogo from '../../../assets/svg/CreatorsLogo.svg';
import NFTLogo from '../../../assets/svg/NFTLogo.svg';
import DiscordRoleLogo from '../../../assets/svg/DiscordRoleLogo.svg';
import User from '../../../assets/svg/User.svg';
import SocialLogo from '../../../assets/svg/socialLogo.svg';
import ShutDownLogo from '../../../assets/svg/shutdown.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [tabs, setTabs] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);

  const onToggleModal = () => {
    setIsModal(!isModal);
  };
  const refreshPage = () => {
    window.location.href = '/';
  };
  const onlogout = () => {
    dispatch(userLogout(refreshPage));
  };
  useEffect(() => {
    let menu_ = [
      { title: 'Dashboard', image: CreatorsLogo, path: '/dashboard' },
      { title: 'Game', image: CreatorsLogo, path: '/games' },
    ];
    if (
      user &&
      user.mobile_number === process.env.REACT_APP_ADMIN_MOBILE_NUMBER
    ) {
      let newMenu = [
        { title: 'Game Config', image: CreatorsLogo, path: '/game-config' },
        { title: 'Game assets', image: CreatorsLogo, path: '/game-assets' },

        { title: 'Plan', image: CreatorsLogo, path: '/plans' },
        { title: 'User Plan', image: CreatorsLogo, path: '/user-plans' },
        { title: 'Users', image: User, path: '/users' },
        { title: 'Wallet', image: NFTLogo, path: '/wallet' },
        { title: 'Creators', image: CreatorsLogo, path: '/creator' },
        { title: 'NFTs', image: NFTLogo, path: '/nft' },
        { title: 'Tournaments', image: NFTLogo, path: '/tournament' },
        { title: 'NFT Utility', image: NFTLogo, path: '/nftUtility' },
        { title: 'Assets', image: NFTLogo, path: '/assets' },
        { title: 'Transactions', image: NFTLogo, path: '/transactions' },
        { title: 'Discord Role', image: DiscordRoleLogo, path: '/discord' },

        { title: 'Social', image: SocialLogo, path: '/social' },
      ];
      menu_ = [...menu_, ...newMenu];
    }
    setTabs(menu_);
  }, [user]);
  return (
    <div className="flex h-full">
      <div
        className={`${
          isOpen ? 'w-64' : 'w-20'
        } duration-500 h-screen bg-[#363740] relative`}
      >
        <img
          src={LeftArrow}
          alt="Left Arrow"
          onClick={() => setIsOpen(!isOpen)}
          className={`bg-[#ffffff] absolute cursor-pointer rounded-full -right-5 top-10 w-10 ${
            isOpen && 'rotate-180'
          }`}
        />
        <div
          className={`flex justify-center ${isOpen ? 'mt-[20%]' : 'mt-[100%]'}`}
        >
          <h1 className="duration-200 w-fit">
            {isOpen ? (
              <img src={RhymFull} alt="Rhym" className={`w-full`} />
            ) : (
              <img src={RhymSmall} alt="Rhym" className="w-[50%] mx-auto" />
            )}
          </h1>
        </div>
        <div className="h-[80%] overflow-scroll side-navbar-menu-container">
          <ul className="mt-10">
            {tabs.map((menuItem, idx) => (
              <Link
                key={idx}
                to={menuItem.path}
                className={`flex cursor-pointer text-[#ffffff] items-center border-l-4 border-transparent hover:bg-[#3e4049] hover:border-[#d7dbf8] ${
                  !isOpen ? 'px-5' : 'px-10'
                } gap-x-4
                ${menuItem.gap ? 'mt-10' : 'mt-0'}
                `}
              >
                <li key={idx} className=" flex gap-x-4 py-4">
                  <img
                    src={menuItem.image}
                    alt=""
                    className={`${!isOpen ? 'w-40' : 'w-5'}`}
                  />
                  <span
                    className={`ml-5 body-1 ${
                      !isOpen && 'scale-0'
                    } duration-300`}
                  >
                    {menuItem.title}
                  </span>
                </li>
              </Link>
            ))}
          </ul>
          <div
            className={`justify-center items-center bottom-0 left-[20%] ${
              !isOpen && 'flex'
            }`}
          >
            {user != null ? (
              <div className="p-2 text-center text-gray-300 cursor-pointer">
                {isOpen ? (
                  <div
                    className="mt-4 hover:border-blue-500 border-2 border-transparent p-2 rounded-md body-1 flex items-center gap-x-4"
                    onClick={onlogout}
                  >
                    <img
                      src={ShutDownLogo}
                      alt=""
                      onClick={onlogout}
                      className="w-5 ml-7"
                    />
                    <p className="ml-5 text-[#ffffff]">Logout</p>
                  </div>
                ) : (
                  <img
                    src={ShutDownLogo}
                    alt=""
                    onClick={onlogout}
                    className={`${!isOpen && 'w-7'} mt-5`}
                  />
                )}
              </div>
            ) : (
              <div
                onClick={onToggleModal}
                className="p-2 text-center mt-10 text-gray-300 cursor-pointer"
              >
                Login
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
