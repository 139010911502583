/* eslint-disable react/prop-types */
import EditIcon from '../../../../assets/svg/Edit.svg';
import { useState } from 'react';
import TransactionEditModal from '../../Modal/TransactionModal/TransactionEditModal';
import TransactionDetailsModal from '../../Modal/TransactionModal/TransactionDetailsModal';
import { formatDate } from '../../../../utils/DateFormat';
export const TransactionColumn = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Trans Id',
        accessor: '_id',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);
          const onToggleModal = () => {
            setIsModal(!isModal);
          };
          return (
            <>
              <div
                className="text-[#11B954] cursor-pointer"
                onClick={() => setIsModal(true)}
              >
                {row.values._id}
              </div>
              <TransactionDetailsModal
                isModal={isModal}
                onToggleModal={onToggleModal}
                transaction={row.original}
              />
            </>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },

      {
        Header: 'amount',
        accessor: 'amount',
      },
      {
        Header: 'nft',
        accessor: 'nft',
      },
      {
        Header: 'status',
        accessor: 'status',
        Cell: ({ row }) => (
          <button
            className={`rounded-xl px-5 py-1 ${
              row.values.status == 'INPROGRESS'
                ? 'bg-[#867e02]'
                : row.values.status === 'COMPLETED'
                ? 'bg-[#2cad4d]'
                : row.values.status === 'PENDING'
                ? 'bg-[#167bf6]'
                : 'bg-[#F12B2C]'
            } text-[#ffffff] uppercase`}
          >
            {row.values.status}
          </button>
        ),
      },
      {
        Header: 'Create At',
        accessor: 'createdAt',
        Cell: ({ row }) => {
          return (
            <>
              <div>{formatDate(row.values.createdAt)}</div>
            </>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const [isModal, setIsModal] = useState(false);
          const onToggleModal = () => {
            setIsModal(!isModal);
          };
          return (
            <div className="">
              <img
                src={EditIcon}
                onClick={() => setIsModal(true)}
                alt=""
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full  cursor-pointer"
              />
              {isModal && (
                <TransactionEditModal
                  isModal={isModal}
                  onToggleModal={onToggleModal}
                  transactions={row.original}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
];
