import PropTypes from 'prop-types';

const GameLeadTable = ({ leads, onGameLeadLoadMore, onExportGameLeads }) => {
  return (
    <div className="shadow w-[88%] mx-auto rounded">
      <div className=" w-[100%]  overflow-y-scroll">
        <div className="h-[400px] w-full ">
          <div className="my-4">
            <p className="h6-thin text-gray-500">Game Leads</p>
          </div>
          <div className=" pb-8">
            <div className="grid grid-cols-8 border-b-2 px-4 py-4 gap-4">
              <div className="col-span-2 caption text-gray-500">Name</div>
              <div className="col-span-2 caption text-gray-500">Email</div>
              <div className="col-span-2 text-center caption text-gray-500">
                Mobile Number
              </div>
              <div className="col-span-1 caption text-gray-500">Score</div>
              <div className="col-span-1 caption flex justify-end">
                <div
                  className="cursor-pointer bg-[#537FE7] text-white w-1/2 text-center rounded p-1"
                  onClick={onExportGameLeads}
                >
                  Export
                </div>
              </div>
            </div>
            <div className="">
              {leads &&
                leads?.data?.map((ele, i) => (
                  <div
                    className="grid grid-cols-8 py-4 border-b-2 px-2 gap-4"
                    key={i}
                  >
                    <div className="col-span-2 body-2">{ele?.name}</div>
                    <div className="col-span-2 body-2 ">{ele?.email}</div>
                    <div className="col-span-2 body-2 text-center">
                      {ele?.mobile_number}
                    </div>
                    <div className="col-span-2 body-2">{ele?.score}</div>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-full flex justify-end text-gray-600 p-2">
            {leads && leads?.total_items} results
          </div>
          <div className="text-center my-4">
            {leads && leads?.data?.length < leads?.total_items && (
              <div
                className="cursor-pointer btn-text"
                onClick={onGameLeadLoadMore}
              >
                Load More
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

GameLeadTable.propTypes = {
  leads: PropTypes.array,
  onGameLeadLoadMore: PropTypes.func,
  onExportGameLeads: PropTypes.func,
};
export default GameLeadTable;
