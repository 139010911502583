import React from 'react';
import PropTypes from 'prop-types';

const NameStartEndDateContainer = ({ formik, formikErrors }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center mt-10">
      <div className="w-full flex justify-evenly mt-4 pt-4">
        <div className="w-1/3 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Name</div>
          </div>
          <input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Enter Name"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="name"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors.name}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/3 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Start Date</div>
          </div>
          <input
            type="datetime-local"
            name="start_date"
            value={formik.values.start_date}
            onChange={formik.handleChange}
            placeholder="Enter Start Date"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="start_date"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors.start_date}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/3 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">End Date</div>
          </div>
          <input
            type="datetime-local"
            name="end_date"
            value={formik.values.end_date}
            onChange={formik.handleChange}
            placeholder="Enter End Date"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="end_date"
          />
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors.end_date}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
        <div className="w-1/3 mt-2 mx-4">
          <div className="flex justify-between items-center">
            <div className="text-slate-500 h7-thin">Is Private</div>
          </div>
          <select
            type="date"
            name="is_private"
            value={formik.values.is_private}
            onChange={formik.handleChange}
            placeholder="Enter End Date"
            className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
            id="is_private"
          >
            <option value={true} selected>
              Yes
            </option>
            <option value={false}>No</option>
          </select>
          <p className="text-sm text-[#FF0000] capitalize my-1">
            {formikErrors.is_private}
          </p>
          <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
        </div>
      </div>
    </div>
  );
};

NameStartEndDateContainer.propTypes = {
  formik: PropTypes.object,
  formikErrors: PropTypes.object,
};

export default NameStartEndDateContainer;
