import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import UserPlansContent from '../../component/userPlans/UserPlansContent';
import { userPlansGetAction } from '../../redux/action/userPlan';

const UserPlansContainer = () => {
  const [filterValue, setFilter] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const dispatch = useDispatch();
  const isUserPlansGetPending = useSelector(
    state => state.userPlans.isUserPlansGetPending
  );

  const [searchParam] = useSearchParams();

  const { userPlans } = useSelector(state => state.userPlans);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(userPlansGetAction(filterValue, pageIndex, pageSize));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (userPlans) setPageCount(Math.ceil(userPlans?.total_items / pageSize1));
  }, [userPlans, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    setFilter(filter);
  }, [searchParam]);
  return (
    <>
      <UserPlansContent pageCount={pageCount} fetchData={fetchData} />
      <RingSpinner isLoading={isUserPlansGetPending} />
    </>
  );
};

export default UserPlansContainer;
