export const NFTS_INFO = 'NFTS_INFO';
export const NFTS_GET_PENDING = 'NFTS_GET_PENDING';

export const ALL_NFT_UTILITY = 'NFT_ALL_NFTS_UTILITY';
export const NFTS_CREATE_PENDING = 'NFT_CREATE_PENDING';
export const NFT_UPDATE_PENDING = 'NFT_UPDATE_PENDING';

export const CREATOR_NFTS = 'CREATOR_NFTS';
export const CREATOR_NFTS_GET_PENDING = 'CREATOR_NFTS_GET_PENDING';

export const USER_NFTS = 'USER_NFTS';
export const USER_NFTS_GET_PENDING = 'USER_NFTS_GET_PENDING';

export const NFT_DETAIL = 'NFT_DETAIL';
export const NFT_DETAIL_GET_PENDING = 'NFT_DETAIL_GET_PENDING';
export const NFT_FANCLUB = 'NFT_FANCLUB';
export const NFT_FANCLUB_GET_PENDING = 'NFT_FANCLUB_GET_PENDING';
export const NFT_UTILITIES = 'NFT_UTILITIES';
export const NFT_UTILITIES_GET_PENDING = 'NFT_UTILITIES_GET_PENDING';

export const NFT_PURCHASE_STATUS = 'NFT_PURCHASE_STATUS';
export const NFT_PURCHASE_STATUS_GET_PENDING =
  'NFT_PURCHASE_STATUS_GET_PENDING';

export const DOWNLOAD_NFT_UTILITY_AVATAR_PENDING =
  'DOWNLOAD_NFT_UTILITY_AVATAR_PENDING';

export const NFT_PRIVILEGE = 'NFT_PRIVILEGE';
export const EXPORT_NFT_BUYER_GQL_PENDING = 'EXPORT_NFT_BUYER_GQL_PENDING';
