import React, { useCallback, useEffect, useState } from 'react';
import WalletContent from '../../component/Wallet/WalletContent';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getWalletsAction } from '../../redux/action/user';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';

const WalletContainer = () => {
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const wallets = useSelector(state => state.user.wallets);
  const [filterValue, setFilterValue] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageSizeCurrent, setPageSize] = useState(1);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      if (filterValue?.is_regex != undefined) {
        dispatch(getWalletsAction(pageIndex, pageSize, filterValue));
      }
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    setPageCount(Math.ceil(wallets?.total_items / pageSizeCurrent));
  }, [wallets, pageSizeCurrent]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;

    setFilterValue(filter);
  }, [searchParam]);

  return (
    <>
      <HeaderTitle pageTitle={'Wallet'} />{' '}
      <WalletContent
        wallet={wallets}
        pageCount={pageCount}
        fetchData={fetchData}
      />
    </>
  );
};

export default WalletContainer;
