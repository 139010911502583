import { apolloClient } from '../../../service/apolloClient';
import {
  GET_GAMES_ANALYTICS_GQL,
  GET_GAME_ENGAGEMENT_GQL,
} from '../../query/gameAnalytics';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import {
  GAMES_ANALYTICS,
  GAMES_ANALYTICS_GET_PENDING,
  GAME_ENGAGEMENT,
  GET_GAME_ENGAGEMENT_PENDING,
} from './type';

export const gameEngagementGetAction = value => {
  return dispatch => {
    dispatch(reduxAction(GET_GAME_ENGAGEMENT_PENDING, true));
    apolloClient
      .query({
        query: GET_GAME_ENGAGEMENT_GQL,
        variables: { input: value },
      })
      .then(({ data }) => {
        const { getGameEngagement: res } = data;
        dispatch(reduxAction(GAME_ENGAGEMENT, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_GAME_ENGAGEMENT_PENDING, false));
      });
  };
};

export const gamesAnalyticsGetAction = value => {
  return dispatch => {
    dispatch(reduxAction(GAMES_ANALYTICS_GET_PENDING, true));
    apolloClient
      .query({
        query: GET_GAMES_ANALYTICS_GQL,
        variables: { input: value },
      })
      .then(({ data }) => {
        const { getGamesAnalytics: res } = data;
        dispatch(reduxAction(GAMES_ANALYTICS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GAMES_ANALYTICS_GET_PENDING, false));
      });
  };
};
