import { reduxAction } from '../base';
import {
  NFTS_INFO,
  NFTS_GET_PENDING,
  NFTS_CREATE_PENDING,
  ALL_NFT_UTILITY,
  NFT_UTILITIES,
  NFT_UTILITIES_GET_PENDING,
  NFT_FANCLUB_GET_PENDING,
  NFT_FANCLUB,
  NFT_UPDATE_PENDING,
  EXPORT_NFT_BUYER_GQL_PENDING,
} from './type';
import {
  GET_ALL_NFT_GQL,
  CREATE_NFT_GQL,
  CREATE_NFT_UTILITIES_GQL,
  GET_NFT_UTILITY_GQL,
  GET_NFT_UTILITIES,
  GET_NFT_FANCLUB_GQL,
  UPDATE_NFT_GQL,
  DELETE_NFT_GQL,
  EXPORT_NFT_BUYER_GQL,
} from '../../query/nft';
import { apolloClient } from '../../../service/apolloClient';
import { toastAction } from '../toastAction';

export const nftGetAllAction = (page, limit, value) => {
  return dispatch => {
    dispatch(reduxAction(NFTS_GET_PENDING, true));
    apolloClient
      .query({
        query: GET_ALL_NFT_GQL,
        variables: { page, limit, input: value },
      })
      .then(({ data }) => {
        const { getNfts: res } = data;

        dispatch(reduxAction(NFTS_INFO, res));
        dispatch(reduxAction(NFTS_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFTS_GET_PENDING, false));
      });
  };
};

export const nftGetAllNftUtiliy = (page, limit, value) => {
  return dispatch => {
    dispatch(reduxAction(NFT_UTILITIES_GET_PENDING, true));
    apolloClient
      .query({
        query: GET_NFT_UTILITY_GQL,
        variables: { page, limit, input: value },
      })
      .then(({ data }) => {
        const { getAllNftUtilities: res } = data;
        dispatch(reduxAction(ALL_NFT_UTILITY, res));
        dispatch(reduxAction(NFT_UTILITIES_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFT_UTILITIES_GET_PENDING, false));
      });
  };
};
export const nftCreateNftUtility = value => {
  const mutation = CREATE_NFT_UTILITIES_GQL;
  return dispatch => {
    dispatch(reduxAction(NFTS_CREATE_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createNftUtility: res } = data;
        dispatch(reduxAction(NFTS_INFO, res));
        dispatch(reduxAction(NFTS_CREATE_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFTS_CREATE_PENDING, false));
      });
  };
};
export const nftCreateAction = (value, cb) => {
  const mutation = CREATE_NFT_GQL;
  return dispatch => {
    dispatch(reduxAction(NFTS_CREATE_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        if (data) {
          if (cb) {
            cb();
          }
        }
        dispatch(reduxAction(NFTS_CREATE_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFTS_CREATE_PENDING, false));
      });
  };
};
export const nftUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_NFT_GQL;

  return dispatch => {
    dispatch(reduxAction(NFT_UPDATE_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: value } })
      .then(({ data }) => {
        if (data) {
          if (cb) {
            cb();
          }
        }
        dispatch(reduxAction(NFT_UPDATE_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFT_UPDATE_PENDING, false));
      });
  };
};

export const nftUtilitiesGetAction = nftId => {
  return dispatch => {
    const query = GET_NFT_UTILITIES;
    dispatch(reduxAction(NFT_UTILITIES_GET_PENDING, true));
    apolloClient
      .query({ query, variables: { nft: nftId } })
      .then(({ data }) => {
        const { getNftUtilities: res } = data;
        dispatch(reduxAction(NFT_UTILITIES, res));
        dispatch(reduxAction(NFT_UTILITIES_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFT_UTILITIES_GET_PENDING, false));
      });
  };
};

export const nftFanclubGetAction = (nftId, page, limit) => {
  return dispatch => {
    const query = GET_NFT_FANCLUB_GQL;
    dispatch(reduxAction(NFT_FANCLUB_GET_PENDING, true));

    apolloClient
      .query({ query, variables: { nftId, page, limit } })
      .then(({ data }) => {
        const { getNftFanClub: res } = data;

        dispatch(reduxAction(NFT_FANCLUB, res));
        dispatch(reduxAction(NFT_FANCLUB_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(NFT_FANCLUB_GET_PENDING, false));
      });
  };
};

export const onNftDeleteAction = (id, cb) => {
  return () => {
    const mutation = DELETE_NFT_GQL;
    apolloClient
      .mutate({ mutation, variables: { id: id } })
      .then(({ data }) => {
        if (data) {
          if (cb) {
            cb();
          }
        }
      })
      .catch(() => {
        //some catch block code
      });
  };
};

export const exportNftBuyersAction = input => {
  return dispatch => {
    const query = EXPORT_NFT_BUYER_GQL;
    dispatch(reduxAction(EXPORT_NFT_BUYER_GQL_PENDING, true));
    apolloClient
      .query({ query, variables: { input } })
      .then(({ data }) => {
        const { exportUserToNft: res } = data;
        if (res && res.data) {
          const url = `data:image/${res.type};base64,${res.data}`;
          let el = document.createElement('a');
          el.href = url;
          el.download = `NftHolder.${res.type}`;
          el.click();
          el.remove();
        } else {
          toastAction.error('Data not found');
        }
        dispatch(reduxAction(EXPORT_NFT_BUYER_GQL_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(EXPORT_NFT_BUYER_GQL_PENDING, false));
      });
  };
};
