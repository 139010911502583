import styled from 'styled-components';
export const Styles = styled.div`
  padding: 0 1rem;

  table {
    display: block;
    height: 58vh;
  }

  table {
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      width: 35vw;
      text-align: start;
      padding: 0.1rem 0;
    }
    td {
      width: 15vw;
      border-bottom: 1px solid #bfbcbc;
      padding: 0;
      margin: 0;
      height: 4.5rem;
    }
  }
`;
