/* eslint-disable react/prop-types */
import EditIcon from '../../../../assets/svg/Edit.svg';
import CopyIcon from '../../../../assets/copyicon.png';
import { formateTournamentDate } from '../../../../utils/DateFormat';
import { useNavigate } from 'react-router-dom';
import LeaderboardModal from '../../Modal/LeaderboardModal/LeaderboardModal';
import { useState } from 'react';

const showStatusColor = status => {
  if (status === 'UPCOMING') return 'bg-[#3944f7]';
  else if (status === 'COMPLETED') return 'bg-[#29CC97]';
  else return 'bg-red-400';
};

export const columnTournament = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Id',
        accessor: '_id',
        Cell: ({ row }) => {
          const [leaderboardOpen, setLeaderboardOpen] = useState(false);

          const toggleLeaderboardModal = () => {
            setLeaderboardOpen(!leaderboardOpen);
          };
          return (
            <>
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setLeaderboardOpen(true)}
              >
                <p className="body-1 ml-3 text-green-500 text-center">
                  {row.values._id}
                </p>
              </div>

              {leaderboardOpen && (
                <LeaderboardModal
                  tournament={row.values}
                  isModalOpen={leaderboardOpen}
                  onToggleModal={toggleLeaderboardModal}
                />
              )}
            </>
          );
        },
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        Cell: ({ row }) => <p>{formateTournamentDate(row.values.end_date)}</p>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <button
            className={`rounded-xl px-5 py-1 ${showStatusColor(
              row.values.status
            )} text-[#ffffff] uppercase`}
          >
            {row.values.status}
          </button>
        ),
      },
      {
        Header: 'Slots',
        accessor: 'slots',
        Cell: ({ row }) => <p>{row.values.slots}</p>,
      },
      {
        Header: 'Filled Slots',
        accessor: 'filled_slot',
        Cell: ({ row }) => <p>{row.values.filled_slot}</p>,
      },
      {
        Header: 'Paid',
        accessor: 'is_paid',
        Cell: ({ row }) => (
          <span>
            <p>{row.values.is_paid ? 'Paid' : 'Free'}</p>
          </span>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          const navigate = useNavigate();

          const editCallback = () => {
            navigate({
              pathname: '/tournament/add',
              search: `?id=${row.values._id}&creationType=update_tournament`,
            });
          };

          return (
            <div className="">
              <img
                src={EditIcon}
                alt=""
                onClick={editCallback}
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
              />
            </div>
          );
        },
      },
      {
        Header: 'Duplicate',
        accessor: 'duplicate',
        Cell: ({ row }) => {
          const navigate = useNavigate();

          const duplicateCallback = () => {
            navigate({
              pathname: '/tournament/add',
              search: `?id=${row.values._id}&creationType=add_tournament`,
            });
          };

          return (
            <div className="">
              <img
                src={CopyIcon}
                alt=""
                onClick={duplicateCallback}
                className="w-7 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
              />
            </div>
          );
        },
      },
    ],
  },
];
