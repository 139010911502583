import { apolloClient } from '../../../service/apolloClient';
import { WALLET_UPDATE } from '../../query/wallet';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import { WALLET_UPDATE_PENDING } from './type';

export const updateWalletAction = (value, id, callback) => {
  const mutation = WALLET_UPDATE;
  return dispatch => {
    dispatch(reduxAction(WALLET_UPDATE_PENDING, true));
    apolloClient
      .mutate({
        mutation,
        variables: { input: value, updateWalletId: id },
      })
      .then(() => {
        toastAction.success('Updated Successfully');
        if (callback) {
          callback();
        }
        dispatch(reduxAction(WALLET_UPDATE_PENDING, false));
      })
      .catch(err => {
        toastAction.error(err);
        dispatch(reduxAction(WALLET_UPDATE_PENDING, false));
      });
  };
};
