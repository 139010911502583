import { USERS_INFO, USERS_GET_PENDING } from '../action/users/type';

const initialPendingState = {
  isUsersGetPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  users: null,
};

export const usersReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case USERS_INFO:
      return {
        ...state,
        users: payload,
      };

    case USERS_GET_PENDING:
      return {
        ...state,
        isUsersGetPending: payload,
      };

    default:
      return state;
  }
};
