export const TOURNAMENTS_PENDING = 'TOURNAMENTS_PENDING';
export const TOURNAMENTS_INFO = 'TOURNAMENTS_INFO';
export const TOURNAMENT_CREATE_PENDING = 'TOURNAMENT_CREATE_PENDING';
export const TOURNAMENT_CREATE_INFO = 'TOURNAMENT_CREATE_INFO';

export const TOURNAMENT_GAMES = 'TOURNAMENT_GAMES';
export const TOURNAMENT_GAMES_PENDING = 'TOURNAMENT_GAMES_PENDING';

export const SINGLE_TOURNAMENT_INFO_PENDING = 'SINGLE_TOURNAMENT_INFO_PENDING';
export const SINGLE_TOURNAMENT_INFO = 'SINGLE_TOURNAMENT_INFO';

export const TOURNAMENT_UPDATE_PENDING = 'TOURNAMENT_UPDATE_PENDING';
export const TOURNAMENT_UPDATE = 'TOURNAMENT_UPDATE';

export const CLEAR_SINGLE_TOURNAMENT = 'CLEAR_SINGLE_TOURNAMENT';

export const USER_TO_TOURNAMENT = 'USER_TO_TOURNAMENT';
export const USER_TO_TOURNAMENT_PENDING = 'USER_TO_TOURNAMENT_PENDING';

export const EXPORT_USER_TO_TOURNAMENT_GQL_PENDING =
  'EXPORT_USER_TO_TOURNAMENT_GQL_PENDING';
