import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userGetAction } from '../redux/action/user';
import { useEffect } from 'react';
import { routes } from './route';
import ProtectedRoute from './ProtectedRoute';
import HomeView from '../view/HomeView/HomeView';

const Router = () => {
  const dispatch = useDispatch();

  const onGetUser = async () => {
    await dispatch(userGetAction());
  };
  useEffect(() => {
    onGetUser();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomeView />} />
          {routes.map((route, i) => (
            <Route
              exact
              path={route.path}
              element={<ProtectedRoute type={route.routeType} />}
              key={i}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
