import { apolloClient } from '../../../service/apolloClient';
import { reduxAction } from '../base';
import { GET_ALL_SOCIALS_PENDING, SOCIALS_INFO } from './type';
import { GET_ALL_SOCIALS } from '../../query/socials';

export const socialsGetAction = (page, limit, value) => {
  return dispatch => {
    dispatch(reduxAction(GET_ALL_SOCIALS_PENDING, true));
    apolloClient
      .query({
        query: GET_ALL_SOCIALS,
        variables: { page, limit, input: value },
      })
      .then(({ data }) => {
        const { getSocialLinks: res } = data;
        dispatch(reduxAction(SOCIALS_INFO, res));
        dispatch(reduxAction(GET_ALL_SOCIALS_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(GET_ALL_SOCIALS_PENDING, false));
      });
  };
};
