import {
  GET_ASSET_HOLDER,
  GET_INACTIVE_ASSET,
  GET_PENDING_WITHDRAWAL,
  GET_NFT_HOLDER,
} from '../action/dashboard/type';

const initialPendingState = {
  isTotalNftHolderGetPending: false,
  isTotalAssetHolderGetPending: false,
  isTotalInactiveHolderGetPending: false,
  isTotalPendingWithdrawal: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  totalNftHolder: null,
  totalAssetHolder: null,
  totalInactiveAsset: null,
  totalPendingWithdrawal: null,
};

export const dashboardReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case GET_NFT_HOLDER:
      return {
        ...state,
        totalNftHolder: payload,
      };
    case GET_INACTIVE_ASSET:
      return {
        ...state,
        totalInactiveAsset: payload,
      };
    case GET_PENDING_WITHDRAWAL:
      return {
        ...state,
        totalPendingWithdrawal: payload,
      };
    case GET_ASSET_HOLDER:
      return {
        ...state,
        totalAssetHolder: payload,
      };
    default: {
      return state;
    }
  }
};
