/* eslint-disable react/prop-types */
export const columnDiscord = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Role Name',
        accessor: 'role_name',
      },
      {
        Header: 'Server Id',
        accessor: 'server_id',
      },
      { Header: 'Role Id', accessor: 'role_id' },
      {
        Header: 'Discord Link',
        accessor: 'link',
        Cell: ({ row }) => (
          <a
            href={row.values.link}
            className="text-[#11B954] body-2 cursor-pointer"
            target="_target"
          >
            Click
          </a>
        ),
      },
    ],
  },
];
