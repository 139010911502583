import {
  NFTS_INFO,
  NFT_DETAIL,
  NFTS_GET_PENDING,
  NFT_DETAIL_GET_PENDING,
  CREATOR_NFTS,
  USER_NFTS,
  NFT_FANCLUB,
  NFT_UTILITIES,
  NFT_PURCHASE_STATUS,
  USER_NFTS_GET_PENDING,
  CREATOR_NFTS_GET_PENDING,
  NFT_FANCLUB_GET_PENDING,
  NFT_UTILITIES_GET_PENDING,
  NFT_PURCHASE_STATUS_GET_PENDING,
  DOWNLOAD_NFT_UTILITY_AVATAR_PENDING,
  NFT_PRIVILEGE,
  ALL_NFT_UTILITY,
  NFTS_CREATE_PENDING,
  NFT_UPDATE_PENDING,
} from '../action/nft/type';

const initialPendingState = {
  isNftsGetPending: false,
  isNftDetailGetPending: false,
  isUserNftsGetPending: false,
  isCreatorNftsGetPending: false,
  isNftFanClubGetPending: false,
  isNftUtilityGetPending: false,
  isNftPurchaseStatusGetPending: false,
  isDownloadNftUtilityAvatarPending: false,
  isNftCreatePending: false,
  isNftUpdatePending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  nfts: null,
  nftDetail: null,
  creatorNfts: null,
  userNfts: null,
  nftFanclub: null,
  nftUtilities: null,
  isPurchase: false,
  nftPrivilege: null,
};

export const nftReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case NFTS_INFO:
      return {
        ...state,
        nfts: payload,
      };
    case CREATOR_NFTS:
      return {
        ...state,
        creatorNfts: payload,
      };
    case USER_NFTS: {
      return {
        ...state,
        userNfts: payload,
      };
    }

    case NFT_DETAIL:
      return {
        ...state,
        nftDetail: payload,
      };
    case NFTS_GET_PENDING:
      return {
        ...state,
        isNftsGetPending: payload,
      };
    case NFT_DETAIL_GET_PENDING:
      return {
        ...state,
        isNftDetailGetPending: payload,
      };
    case NFT_FANCLUB:
      return {
        ...state,
        nftFanclub: payload,
      };
    case NFT_UTILITIES:
      return {
        ...state,
        nftUtilities: payload,
      };

    case NFT_PURCHASE_STATUS:
      return {
        ...state,
        isPurchase: payload,
      };
    case USER_NFTS_GET_PENDING:
      return {
        ...state,
        isUserNftsGetPending: payload,
      };
    case CREATOR_NFTS_GET_PENDING:
      return {
        ...state,
        isCreatorNftsGetPending: payload,
      };
    case NFT_FANCLUB_GET_PENDING:
      return {
        ...state,
        isNftFanClubGetPending: payload,
      };
    case NFT_UTILITIES_GET_PENDING:
      return {
        ...state,
        isNftUtilityGetPending: payload,
      };
    case NFT_PURCHASE_STATUS_GET_PENDING:
      return {
        ...state,
        isNftPurchaseStatusGetPending: payload,
      };
    case DOWNLOAD_NFT_UTILITY_AVATAR_PENDING:
      return {
        ...state,
        isDownloadNftUtilityAvatarPending: payload,
      };
    case NFT_PRIVILEGE:
      return {
        ...state,
        nftPrivilege: payload,
      };
    case ALL_NFT_UTILITY:
      return {
        ...state,
        nftAllUtility: payload,
      };
    case NFTS_CREATE_PENDING:
      return {
        ...state,
        isNftCreatePending: payload,
      };
    case NFT_UPDATE_PENDING:
      return {
        ...state,
        isNftUpdatePending: payload,
      };
    default: {
      return state;
    }
  }
};
