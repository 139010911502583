export const columnSocial = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Social ID',
        accessor: '_id',
      },
      {
        Header: 'User',
        accessor: 'user',
      },
      {
        Header: 'Discord User ID',
        accessor: 'discord.discord_userid',
      },
      {
        Header: 'Discord Username',
        accessor: 'discord.discord_username',
      },
    ],
  },
];
