import {
  USER_INFO,
  USER_OTP_PENDING,
  USER_OTP_VERIFICATION_PENDING,
  USER_REGISTER_STATUS,
  USER_REGISTER_PENDING,
  USER_BOUGHT_EXPERIENCE_GET_PENDING,
  USER_BOUGHT_EXPERIENCE_INFO,
  DEFAULT_PROFILE_PICS,
  USER_INFO_GET_PENDING,
  USER_LOGIN_MODAL,
  USER_NFT,
  USER_DISCORD,
  USER_WALLET,
  GET_USER_WALLET,
  GET_USER_DISCORD,
  GET_USER_NFT,
  GET_WALLETS,
  GET_WALLETS_PENDING,
} from '../action/user/type';

const initialPendingState = {
  isOtpPending: false,
  isOtpVerificationPending: null,
  isUserRegistered: null,
  isUserRegisterPending: null,
  isBoughtExperiencesGetPending: null,
  isUserGetPending: null,
  isUserWalletPending: null,
  isUserNftPending: null,
  isUserDiscordPending: null,
  isWalletsGetPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  user: null,
  isAuthenticated: null,
  isUserLoginModal: null,
  profile: null,
  boughtExperiences: null,
  defaultProfilePics: [],
  wallets: null,
};

export const userReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case USER_NFT:
      return {
        ...state,
        UserNft: action.payload,
      };
    case USER_DISCORD:
      return {
        ...state,
        UserDiscord: action.payload,
      };
    case USER_WALLET:
      return {
        ...state,
        userWallet: action.payload,
      };
    case GET_USER_WALLET:
      return {
        ...state,
        isUserWalletPending: action.payload,
      };
    case GET_USER_DISCORD:
      return {
        ...state,
        isUserDiscordPending: action.payload,
      };
    case GET_USER_NFT:
      return {
        ...state,
        isUserNftPending: action.payload,
      };
    case USER_REGISTER_STATUS:
      return {
        ...state,
        isUserRegistered: action.payload,
      };
    case USER_REGISTER_PENDING:
      return {
        ...state,
        isUserRegisterPending: action.payload,
      };
    case USER_OTP_PENDING:
      return {
        ...state,
        isOtpPending: action.payload,
      };
    case USER_OTP_VERIFICATION_PENDING:
      return {
        ...state,
        isOtpVerificationPending: action.payload,
      };

    case USER_BOUGHT_EXPERIENCE_INFO:
      return {
        ...state,
        boughtExperiences: action.payload,
      };
    case USER_BOUGHT_EXPERIENCE_GET_PENDING:
      return {
        ...state,
        isBoughtExperiencesGetPending: action.payload,
      };

    case USER_INFO_GET_PENDING:
      return {
        ...state,
        isUserGetPending: action.payload,
      };
    case USER_LOGIN_MODAL:
      return {
        ...state,
        isUserLoginModal: action.payload,
      };
    case DEFAULT_PROFILE_PICS:
      return {
        ...state,
        defaultProfilePics: action.payload,
      };
    case GET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      };
    case GET_WALLETS_PENDING:
      return {
        ...state,
        isWalletsGetPending: action.payload,
      };
    default: {
      return state;
    }
  }
};
