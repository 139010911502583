import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { tournamentGetGameAction } from '../../../redux/action/tournament';
import { TournamentType } from '../../../constants/tournament_constant';

const GameTypeContainer = ({ formik, formikErrors }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tournamentGetGameAction());
  }, []);
  const games = useSelector(state => state.tournaments.games);

  return (
    <div className="w-full flex justify-evenly mt-4 pt-4">
      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Is Paid</div>
        </div>
        <select
          type="select"
          name="is_paid"
          defaultValue={formik.values.is_paid}
          value={formik.values.is_paid}
          onChange={formik.handleChange}
          placeholder="Select Paid Status"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="is_paid"
        >
          <option value={false}>Free</option>
          <option value={true}>Paid</option>
        </select>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Game</div>
        </div>
        <select
          type="select"
          name="game"
          defaultValue={formik.values.game}
          value={formik.values.game || 'defaultOpt'}
          onChange={formik.handleChange}
          placeholder="Enter Game"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="game"
        >
          <option value="undefined">Select Game</option>
          {TournamentType.map((data, index) => (
            <option value={data} name={data} key={index}>
              {data}
            </option>
          ))}
        </select>
        <p className="text-sm text-[#FF0000] capitalize my-1">
          {formikErrors.game}
        </p>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Type</div>
        </div>
        <select
          type="select"
          name="type"
          defaultValue={formik.values.type}
          value={formik.values.type || 'defaultOpt'}
          onChange={formik.handleChange}
          placeholder="Enter Game Type"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="type"
        >
          <option value="undefined">Select Game Type</option>
          <option value="SOLO">SOLO</option>
          <option value="SQUAD">SQUAD</option>
        </select>
        <p className="text-sm text-[#FF0000] capitalize my-1">
          {formikErrors.type}
        </p>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>

      <div className="w-1/3 mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Casual Game</div>
        </div>
        <select
          type="select"
          name="casual_game"
          value={formik.values.casual_game}
          onChange={formik.handleChange}
          placeholder="Enter Casual Game"
          className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
          id="casual_game"
        >
          <option value="undefined">Select Casual Game</option>
          {games &&
            games.map((data, index) => (
              <option value={data._id} key={index}>
                {data.name}
              </option>
            ))}
        </select>
        <p className="text-sm text-[#FF0000] capitalize my-1">
          {formik.errors.casual_game}
        </p>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
    </div>
  );
};

GameTypeContainer.propTypes = {
  formik: PropTypes.object,
  formikErrors: PropTypes.object,
};

export default GameTypeContainer;
