import { gql } from '@apollo/client';

export const GET_DISCORD_ROLES = gql`
  query Query {
    getDiscordRoles {
      data {
        _id
        server_id
        role_id
        role_name
        link
      }
      total_items
      page
      limit
    }
  }
`;
