import {
  GET_USER_TO_ASSETS,
  GET_USER_TO_ASSETS_PENDING,
} from '../action/assets/type';

const DEFAULT_STATE = {
  userToAssets: null,
  userToAssetsPending: null,
};

export const userToAssetsReducer = (
  state = DEFAULT_STATE,
  { type, payload }
) => {
  switch (type) {
    case GET_USER_TO_ASSETS:
      return {
        ...state,
        userToAssets: payload,
      };
    case GET_USER_TO_ASSETS_PENDING:
      return {
        ...state,
        userToAssetsPending: payload,
      };
    default: {
      return state;
    }
  }
};
