export const TRANSACTION_CREATE_PENDING = 'TRANSACTION_CREATE_PENDING';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';

export const CASHFREE_PAYMENT_VERIFICATION_PENDING =
  'CASHFREE_PAYMENT_VERIFICATION_PENDING';
export const TRANSACTION_DETAILS = 'TRANSACTION_DETAILS';

export const USER_TRANSACTION_GET_PENDING = 'USER_TRANSACTION_GET_PENDING';

export const ALL_TRANSACTIONS = 'ALL_TRANSACTIONS';

export const CREATE_TRANSACTION_PENDING = 'CREATE_TRANSACTION_PENDING';
export const UPDATE_TRANSACTION_PENDING = 'UPDATE_TRANSACTION_PENDING';
