import React, { useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { inlineStyle } from '../../css/ModalCss/ModalCss';
import { CrossIcon } from '../common/svg/Icons';
import { createTransaction } from '../../redux/action/transaction';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { nftGetAllAction } from '../../redux/action/nft';
const schema = yup.object().shape({
  nft: yup.string().required('Nft id is required'),
  cashfreeRedirectUrl: yup.string().required('cash free url is required'),
});
const AddTransModal = ({ isModal, onToggleModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(nftGetAllAction());
  }, []);
  const nfts = useSelector(state => state.nft.nfts);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nft: undefined,
      cashfreeRedirectUrl: undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch(createTransaction(values));
    },
  });

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={onToggleModal}
      ariaHideApp={false}
      style={inlineStyle}
      shouldCloseOnOverlayClick={true}
      nfts={nfts}
    >
      <div>
        <div className="relative w-full">
          <div
            className="absolute right-0 cursor-pointer p-2 "
            onClick={onToggleModal}
          >
            <CrossIcon />
          </div>
          <div className="flex justify-center items-center text-black">
            <form onSubmit={formik.handleSubmit} className="w-full text-black">
              <div className="w-full text-center h5-thin">
                Create Transaction
              </div>
              <div className="w-full flex flex-col justify-center items-center mt-10">
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Nft Id</div>
                    </div>
                    <select
                      type="select"
                      name="nft"
                      placeholder="Type Here"
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 text-black rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                      id="nft"
                    >
                      {nfts &&
                        nfts.map((value, index) => {
                          return (
                            <option
                              value={value?._id}
                              id={index?._id}
                              key={index}
                              className="text-[#FE6125] h6-thin-sm"
                            >
                              {value._id}
                            </option>
                          );
                        })}
                    </select>
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.nftId && formik.touched.nftId && (
                      <div className="text-slate-500 h7-thin">
                        {formik.errors.nftId}
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 mt-6 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">
                        cashfreeRedirectUrl
                      </div>
                    </div>
                    <input
                      type="text"
                      name="cashfreeRedirectUrl"
                      placeholder="Type Here"
                      value={formik.values.cashfreeRedirectUrl}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="cashfreeRedirectUrl"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.cashfreeRedirectUrl &&
                      formik.touched.cashfreeRedirectUrl && (
                        <div className="text-red-500 caption my-2">
                          {formik.errors.cashfreeRedirectUrl}
                        </div>
                      )}
                  </div>
                </div>
                {/* total_editions */}
              </div>
              <div className="m-4 h-auto w-full flex justify-center items-center mt-10 ">
                <div className="mx-40 w-[80%] p-0.5 bg-gradient-to-r from-[#C537AE] via-[#8217DE] to-[#ff5800] rounded-lg cursor-pointer">
                  <button
                    className="bg-blue-400 hover:bg-blue-500 w-full p-2 md:p-3 rounded-lg px-5 md:px-10  btn-text"
                    type="submit"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddTransModal.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
};
export default AddTransModal;
