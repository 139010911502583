import React, { useState } from 'react';
import { Styles } from '../../css/table';
import TableHeader from '../common/table/TableHeader';
import PropType from 'prop-types';
import { TablePagination } from '../common/table/tablePagination';
import WalletFilter from './WalletFilter';
import { columnUpdateWallet } from '../common/table/TableColumns/walletUpdate';
const WalletContent = ({
  wallet,
  addButtonState,
  featureState,
  fetchData,
  pageCount,
}) => {
  const [filter, setFilter] = useState(false);
  const onToggleFilter = () => {
    setFilter(!filter);
  };
  return (
    <div className="w-full">
      <div className="flex justify-end mx-20 mt-2"></div>
      <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px] h-[85vh]">
        <TableHeader
          FileName={'Wallet'}
          onToggleFilter={onToggleFilter}
          addButtonEnable={addButtonState}
          featureEnable={featureState}
        />
        <div
          className={`absolute w-[20rem] p-2 bg-slate-400 rounded-md right-0 z-10 mr-20 ${
            filter ? 'block' : 'hidden'
          }`}
        >
          <WalletFilter onToggleFilter={onToggleFilter} />
        </div>
        <Styles>
          <TablePagination
            columns={columnUpdateWallet}
            data={wallet?.data || []}
            total_item={wallet?.total_items}
            pageCount={pageCount}
            fetchData={fetchData}
            className="rounded"
          />
        </Styles>
      </div>
    </div>
  );
};

WalletContent.propTypes = {
  wallet: PropType.object,
  addButtonState: PropType.bool,
  featureState: PropType.bool,
  fetchData: PropType.func,
  pageCount: PropType.number,
};

export default WalletContent;
