import moment from 'moment';

export const formatDate = date => {
  if (date) {
    return moment(new Date(parseInt(date))).format('DD MMM YYYY');
  }
};

export const formatBidDate = date => {
  if (date) {
    return moment(new Date(date)).format('DD MMM, YYYY | h:mm A');
  }
};

export const formateTournamentDate = date => {
  if (date) {
    return moment(new Date(date)).format('DD MMM , h:mm A');
  }
};

export const formatSingleTournamentDate = date => {
  if (date) {
    return moment(new Date(parseInt(date))).format('YYYY-MM-DD HH:mm');
  }
};

export const getPreviousMonthDate = () => {
  return moment().subtract(1, 'months').format('YYYY-MM-DD');
};
export const getCurrentDate = () => {
  return moment().format('YYYY-MM-DD');
};
