import { userProfileRoute } from '../../../../route/navigate';

/* eslint-disable react/prop-types */
export const columnAssetsBuyer = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Asset',
        accessor: 'asset',
      },
      {
        Header: 'Tournaments remaining',
        accessor: 'detail.tournament_remaining',
      },
      {
        Header: 'User ID',
        accessor: 'user',
        Cell: ({ row }) => (
          <div
            className="text-orange-600 cursor-pointer"
            onClick={() =>
              window.open(userProfileRoute(row.values.user._id), '_blank')
            }
          >
            {row.values.user._id}
          </div>
        ),
      },
      {
        Header: 'Username',
        accessor: 'user.username',
      },
      {
        Header: 'Mobile',
        accessor: 'user.mobile_number',
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        Cell: ({ row }) => (
          <div className="px-3 pt-1.5 pb-1 bg-[#11B954] w-fit rounded-full text-white">
            {row.values.is_active ? 'ACTIVE' : 'NOT ACTIVE'}
          </div>
        ),
      },
    ],
  },
];
