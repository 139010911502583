export const makeQueryString = values => {
  let queryString = Object.keys(values)
    .filter(key => {
      return values[key] != undefined;
    })
    .map(key =>
      typeof values[key] == 'object'
        ? key + '=' + values[key]._id
        : key + '=' + values[key]
    )
    .join('&');
  return queryString;
};
