import TabContent from './TabContent';

const GameConfigContent = () => {
  return (
    <div className="">
      <TabContent />
    </div>
  );
};

export default GameConfigContent;
