export const reduxAction = (type, data) => {
  return {
    type,
    payload: data,
  };
};
export const errorPayload = (type, error) => {
  return {
    type,
    error,
  };
};
