import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { transaction_type } from '../../constants/transaction_constants';
import { makeQueryString } from '../../utils/MakeQueryString';

const schema = yup.object().shape({
  nftId: yup.string(),
  status: yup.string(),
  Transaction: yup.string(),
  date: yup.date(),
});

const TransactionFilter = ({ onToggleFilter, userId }) => {
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);
  const navigate = useNavigate();
  const status = ['INPROGRESS', 'COMPLETED', 'PENDING', 'FAILED'];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: filter?.status || undefined,
      nft: filter?.nft || undefined,
      transaction_type: filter?.transaction_type || undefined,
      author: userId || filter?.author || undefined,
      date: undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values.status === '') {
        values.status = undefined;
      }
      if (values.transaction_type === '') {
        values.transaction_type = undefined;
      }
      if (values.nft === '') {
        values.nft = undefined;
      }
      if (
        (values.author && values.author._id === '') ||
        values.author === userId
      ) {
        values.author = undefined;
      }
      navigate({
        search: makeQueryString(values),
      });

      onToggleFilter();
    },
  });
  const onClearSearchQuery = () => {
    formik.resetForm();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black">
        <div className="flex">
          <select
            type="text"
            name="status"
            placeholder="Status"
            value={formik.values.status}
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="status"
          >
            <option name="" value="">
              select status
            </option>
            {status.map((value, index) => {
              return (
                <option
                  value={value}
                  id={index}
                  key={index}
                  className="text-[#FE6125] h6-thin-sm"
                >
                  {value}
                </option>
              );
            })}
          </select>
          <select
            type="select"
            name="transaction_type"
            placeholder="Transaction"
            defaultValue={formik.values.transaction_type}
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="Transaction"
          >
            <option name="" value="">
              Select Transaction type
            </option>
            {transaction_type.map((value, index) => {
              return (
                <option
                  value={value}
                  id={index}
                  key={index}
                  className="text-[#FE6125] h6-thin-sm"
                >
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex">
          <input
            type="text"
            name="nft"
            defaultValue={formik.values.nft}
            placeholder="Nft Id"
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="nftid"
          />

          <input
            type="text"
            name="author._id"
            placeholder="user ID"
            readOnly={userId ? true : false}
            defaultValue={formik.values.author}
            onChange={formik.handleChange}
            className={` w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm ${
              userId ? 'cursor-not-allowed' : ''
            }`}
            id="userID"
          />
        </div>

        <div className="w-full flex justify-end items-end">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

TransactionFilter.propTypes = {
  onToggleFilter: PropTypes.func,
  userId: PropTypes.string,
};
export default TransactionFilter;
