import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderTitle } from '../../common/HeaderTitle/HeaderTitle';
import TournamentForm from './TournamentForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  singleTournamentGetAction,
  clearTournament,
} from '../../../redux/action/tournament';

const TournamentFormContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tournament = useSelector(state => state.tournaments.tournament);
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    navigate('/tournament');
    dispatch(clearTournament());
  };

  const tournament_id = searchParams.get('id');
  const creationType = searchParams.get('creationType');

  useEffect(() => {
    dispatch(singleTournamentGetAction(tournament_id));
  }, [tournament_id]);

  return (
    <div className="w-full">
      <HeaderTitle pageTitle={'Tournaments'} />
      <div className="mt-5">
        <div className="w-fit ml-auto mr-5 my-1">
          <button
            onClick={handleClick}
            className="bg-[#3944f7] text-[#ffffff] px-5 py-3 rounded-lg"
          >
            Go Back
          </button>
        </div>
        <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px] h-[80vh] overflow-y-scroll">
          <TournamentForm tournament={tournament} creationType={creationType} />
        </div>
      </div>
    </div>
  );
};

export default TournamentFormContainer;
