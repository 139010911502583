import React, { useState } from 'react';
import TableHeader from '../common/table/TableHeader';
import { useNavigate } from 'react-router-dom';
import { TablePagination } from '../common/table/tablePagination';
import { Styles } from '../../css/table';
import TournamentFilter from './TournamentFilter';
import PropTypes from 'prop-types';

const TournamentsContent = ({
  tournaments,
  fetchData,
  pageCount,
  filterValue,
  columnData,
  addButtonState,
  featureState,
}) => {
  const navigate = useNavigate();

  const cb = () => {
    navigate('/tournament/add?creationType=add_tournament');
  };

  const [filter, setFilter] = useState(false);
  const onToggleFilter = () => {
    setFilter(!filter);
  };

  return (
    <div className="w-full">
      <div className="mt-10">
        <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader
            FileName="Tournaments"
            addButtonEnable={addButtonState}
            featureEnable={featureState}
            modalOpenfunc={cb}
            onToggleFilter={onToggleFilter}
          />
          <div
            className={`absolute w-[30rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
              filter ? 'block' : 'hidden'
            }`}
          >
            <TournamentFilter onToggleFilter={onToggleFilter} />
          </div>
          <Styles>
            <TablePagination
              columns={columnData}
              data={tournaments?.data || []}
              total_item={tournaments?.total_items}
              fetchData={fetchData}
              pageCount={pageCount}
              filterValue={filterValue}
              className="rounded"
            />
            <div className="w-full flex justify-center items-center  cursor-pointer"></div>
          </Styles>
        </div>
      </div>
    </div>
  );
};

TournamentsContent.propTypes = {
  fetchData: PropTypes.func,
  tournaments: PropTypes.object,
  pageCount: PropTypes.number,
  filterValue: PropTypes.object,
  columnData: PropTypes.array,
  addButtonState: PropTypes.bool,
  featureState: PropTypes.bool,
};

export default TournamentsContent;
