import React from 'react';
import PropTypes from 'prop-types';

const OtpForm = ({ onHandleChange, formikValues }) => {
  const inputFocus = elmnt => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 8) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  return (
    <>
      <div>
        <div className=" mt-12">
          <div className="flex justify-start mt-8">
            <input
              type="tel"
              className=" bg-transparent outline-0 focus:ring-0 px-0 lg:w-[52px] w-[40px] lg:h-[52px] h-[40px] py-2.5 text-center border-1 rounded-lg text-lg lg:text-xl"
              placeholder="-"
              maxLength="1"
              onKeyUp={inputFocus}
              tabIndex={3}
              value={formikValues.otp1 || ''}
              name="otp1"
              onChange={onHandleChange}
              autoComplete="off"
              required
            />
            <input
              type="tel"
              className=" bg-transparent outline-0 focus:ring-0  px-0 lg:w-[52px] w-[40px] lg:h-[52px] h-[40px]  py-1 text-center border-1 rounded-lg text-lg lg:text-xl ml-4"
              placeholder="-"
              maxLength="1"
              onKeyUp={inputFocus}
              tabIndex={4}
              value={formikValues.otp2 || ''}
              name="otp2"
              onChange={onHandleChange}
              autoComplete="off"
              required
            />
            <input
              type="tel"
              className=" bg-transparent outline-0 focus:ring-0  px-0 lg:w-[52px] w-[40px] lg:h-[52px] h-[40px]  py-1 text-center border-1 rounded-lg text-lg lg:text-xl ml-4"
              placeholder="-"
              maxLength="1"
              onKeyUp={inputFocus}
              tabIndex={5}
              value={formikValues.otp3 || ''}
              name="otp3"
              onChange={onHandleChange}
              autoComplete="off"
              required
            />
            <input
              type="tel"
              className=" bg-transparent outline-0 focus:ring-0  px-0 lg:w-[52px] w-[40px] lg:h-[52px] h-[40px]  py-1 text-center border-1 rounded-lg text-lg lg:text-xl ml-4"
              placeholder="-"
              maxLength="1"
              onKeyUp={inputFocus}
              tabIndex={6}
              value={formikValues.otp4 || ''}
              name="otp4"
              onChange={onHandleChange}
              autoComplete="off"
            />
            <input
              type="tel"
              className=" bg-transparent outline-0 focus:ring-0  px-0 lg:w-[52px] w-[40px] lg:h-[52px] h-[40px]  py-1 text-center border-1 rounded-lg text-lg lg:text-xl ml-4"
              placeholder="-"
              maxLength="1"
              onKeyUp={inputFocus}
              tabIndex={7}
              value={formikValues.otp5 || ''}
              name="otp5"
              onChange={onHandleChange}
              autoComplete="off"
              required
            />
            <input
              type="tel"
              className=" bg-transparent outline-0 focus:ring-0  px-0 lg:w-[52px] w-[40px] lg:h-[52px] h-[40px]  py-1 text-center border-1 rounded-lg text-lg lg:text-xl ml-4"
              placeholder="-"
              maxLength="1"
              onKeyUp={inputFocus}
              tabIndex={8}
              value={formikValues.otp6 || ''}
              name="otp6"
              onChange={onHandleChange}
              autoComplete="off"
              required
            />
          </div>
          <div
            className={
              'mt-8 w-fit p-0.5 bg-gradient-to-r from-[#C537AE] via-[#8217DE] to-[#ff5800] rounded-full cursor-pointer'
            }
          >
            <button
              type="submit"
              className="bg-slate-400 p-2 rounded-full px-5 h6-thin h6-sm-thin"
            >
              Verify & Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
OtpForm.propTypes = {
  onHandleChange: PropTypes.func,
  formikValues: PropTypes.object,
};

export default OtpForm;
