import React, { useEffect, useState } from 'react';
import { Table } from '../../component/common/table/table';
import { Styles } from '../../css/table';
import { useDispatch, useSelector } from 'react-redux';
import { creatorsGetAction } from '../../redux/action/creator';
import { nftGetAllAction } from '../../redux/action/nft';
import { columnCreator } from '../common/table/TableColumns/creator';
export const CreatorTable = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(creatorsGetAction());
    dispatch(nftGetAllAction());
  }, []);
  const creators = useSelector(state => state.creators.creators);
  const [creatorData, setCreatorData] = useState(null);

  useEffect(() => {
    if (creators != null) {
      setCreatorData(creators);
    }
  }, [creators]);

  return (
    <div className="w-full">
      {creatorData && (
        <Styles>
          <Table
            columns={columnCreator}
            data={creatorData}
            className="rounded"
          />
        </Styles>
      )}
    </div>
  );
};
