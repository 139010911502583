import { apolloClient } from '../../../service/apolloClient';
import { reduxAction } from '../base';
import { USERS_GET_PENDING, USERS_INFO } from './type';
import { GET_ALL_USERS } from '../../query/users';

export const usersGetAction = (input, page, limit) => {
  input = { ...input, plan: true };
  return dispatch => {
    dispatch(reduxAction(USERS_GET_PENDING, true));
    apolloClient
      .query({ query: GET_ALL_USERS, variables: { input, page, limit } })
      .then(({ data }) => {
        const { getUsers: res } = data;

        dispatch(reduxAction(USERS_INFO, res));
        dispatch(reduxAction(USERS_GET_PENDING, false));
      })
      .catch(() => {
        dispatch(reduxAction(USERS_GET_PENDING, false));
      });
  };
};
