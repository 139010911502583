import React from 'react';
import DeleteIcon from '../../../assets/svg/Delete.svg';
import PropTypes from 'prop-types';

const PrizeDistribution = ({ FormikProvider, formik, FieldArray }) => {
  return (
    <div className="w-full flex justify-between mt-4 pt-4">
      <div className="w-full mt-2 mx-4">
        <div className="flex justify-between items-center">
          <div className="text-slate-500 h7-thin">Prize Distribution</div>
        </div>
        <FormikProvider value={formik}>
          <FieldArray name="prize_distribution">
            {({ push, remove }) => (
              <div>
                {formik.values.prize_distribution &&
                  formik.values.prize_distribution.length > 0 &&
                  formik.values.prize_distribution.map((_, idx) => (
                    <div
                      className="mt-4 mb-4 flex items-center gap-2 justify-between w-full"
                      key={idx}
                    >
                      <div className="w-full flex items-center gap-2">
                        <input
                          type="number"
                          placeholder="Amount"
                          name={`prize_distribution.${idx}.reward.amount`}
                          value={
                            formik.values.prize_distribution[idx]?.reward
                              ?.amount || ''
                          }
                          onChange={e => {
                            formik.setFieldValue(
                              `prize_distribution.${idx}.reward.amount`,
                              parseInt(e.target.value)
                            );
                          }}
                          className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                        />
                        <select
                          onChange={e => {
                            formik.setFieldValue(
                              `prize_distribution.${idx}.reward.currency`,
                              e.target.value
                            );
                          }}
                          value={
                            formik.values.prize_distribution[idx]?.reward
                              ?.currency
                          }
                          name={`prize_distribution.${idx}.reward.currency`}
                          className="w-full rounded-lg border-[#9FA2B4] border-2 text-[#9fa2b4]"
                        >
                          <option value="undefined">Select Currency</option>
                          <option value="INR" name="INR">
                            INR
                          </option>
                          <option value="USDC" name="USDC">
                            USDC
                          </option>
                        </select>
                      </div>
                      <span className="m-1"></span>
                      <div className="w-full">
                        <input
                          type="text"
                          placeholder="Rank"
                          name={`prize_distribution.${idx}.rank`}
                          value={
                            formik.values.prize_distribution[idx].rank || ''
                          }
                          onChange={e => {
                            formik.setFieldValue(
                              `prize_distribution.${idx}.rank`,
                              e.target.value
                            );
                          }}
                          className="border-[#9FA2B4] px-2 focus:ring-0 outline-none pb-0.5  lg:pb-2 w-full border-2 text-left text-base lg:text-lg font-['aribau-regular'] rounded-lg text-[#9fa2b4]"
                        />
                      </div>
                      <div className="" onClick={() => remove(idx)}>
                        <img
                          src={DeleteIcon}
                          alt=""
                          className="w-20 bg-[#F4F5FF] hover:bg-[#dadbe6] rounded-full p-3 ml-2 cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
                <div className="w-fit ml-auto">
                  <button
                    type="button"
                    onClick={() =>
                      push({
                        rank: '',
                        reward: {
                          currency: undefined,
                          amount: 0,
                        },
                      })
                    }
                    className="ml-auto w-fit rounded-lg bg-[#D9D9D9] text-[#9fa2b4] px-3 py-2"
                  >
                    ADD
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
        </FormikProvider>
        <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
      </div>
    </div>
  );
};

PrizeDistribution.propTypes = {
  FormikProvider: PropTypes.object,
  formik: PropTypes.object,
  FieldArray: PropTypes.func,
};

export default PrizeDistribution;
