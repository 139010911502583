export const removeNullValueObject = obj => {
  let newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === Object(newObj[key]) && !Array.isArray(newObj[key]))
      newObj[key] = removeNullValueObject(newObj[key]);
    else if ([undefined, NaN, 'undefined'].includes(newObj[key])) {
      delete newObj[key];
    }
  });

  return newObj;
};

export const removeEmptyObject = obj => {
  let newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (
      Object.keys(newObj[key]).length === 0 &&
      newObj[key] === Object(newObj[key])
    ) {
      delete newObj[key];
    }
  });

  return newObj;
};
