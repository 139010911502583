import { routeType } from '../constants/route_constants';

export const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    routeType: routeType.DASHBOARD_VIEW,
  },
  {
    path: '/creator',
    name: 'creator',
    routeType: routeType.CREATOR_VIEW,
  },
  {
    path: '/nft',
    name: 'nft',
    routeType: routeType.NFT_VIEW,
  },
  {
    path: '/nftUtility',
    name: 'nftUtility',
    routeType: routeType.NFT_UTILITY_VIEW,
  },
  {
    path: '/tournament',
    name: 'tournaments',
    routeType: routeType.TOURNAMENT_VIEW,
  },
  {
    path: '/tournament/add',
    name: 'add_tournaments',
    routeType: routeType.TOURNAMENT_FORM,
  },
  {
    path: '/users',
    name: 'users',
    routeType: routeType.USER_VIEW,
  },
  {
    path: '/social',
    name: 'social',
    routeType: routeType.SOCIAL_VIEW,
  },
  {
    path: '/buyer/:nftId',
    name: 'nftBuyer',
    routeType: routeType.NFT_BUYER_VIEW,
  },
  {
    path: '/assets',
    name: 'assets',
    routeType: routeType.ASSET_VIEW,
  },
  {
    path: '/assets/:asset',
    name: 'assetBuyer',
    routeType: routeType.ASSET_BUYER_VIEW,
  },
  {
    path: '/Transactions',
    name: 'Transactions',
    routeType: routeType.TRANSACTION_VIEW,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    routeType: routeType.WALLET_VIEW,
  },
  {
    path: '/discord',
    name: 'discord',
    routeType: routeType.DISCORD_ROLE_VIEW,
  },
  {
    path: '/assets/:asset',
    name: 'assetBuyer',
    routeType: routeType.ASSET_BUYER_VIEW,
  },
  {
    path: '/users/:userId',
    name: 'userDetail',
    routeType: routeType.USER_DETAIL_VIEW,
  },
  {
    path: '/games',
    name: 'games',
    routeType: routeType.GAME_VIEW,
  },
  {
    path: '/game/analytics/:gameId',
    name: 'gameAnalytics',
    routeType: routeType.GAME_ANALYTICS_VIEW,
  },
  {
    path: '/game-config',
    name: 'gameConfig',
    routeType: routeType.GAME_CONFIG,
  },
  {
    path: '/game-assets',
    name: 'gameAssets',
    routeType: routeType.GAME_ASSETS,
  },
  {
    path: '/plans',
    name: 'plans',
    routeType: routeType.PLAN_VIEW,
  },
  {
    path: '/user-plans',
    name: 'user-plans',
    routeType: routeType.USER_PLAN_VIEW,
  },
];
