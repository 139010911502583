import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import AssetBuyerContent from '../../component/AssetBuyer/AssetBuyerContent';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import {
  exportAssetBuyersAction,
  userToAssetsGetAction,
} from '../../redux/action/assets';

const AssetsbuyerContainer = () => {
  const dispatch = useDispatch();

  const [searchParam] = useSearchParams();
  const { asset } = useParams();
  const userToAssetsPending = useSelector(
    state => state.assets.userToAssetsPending
  );
  const [pageSizeCurrent, setCurrentPageSize] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [filterField, setFilterField] = useState(null);

  const usersAssets = useSelector(state => state.assets.userToAssets);

  const onExportUserToAsset = () => {
    dispatch(exportAssetBuyersAction({ asset: asset }));
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterField?.is_regex != undefined)
        dispatch(userToAssetsGetAction(pageIndex, pageSize, filterField));
      setCurrentPageSize(pageSize);
    },
    [filterField]
  );

  useEffect(() => {
    setPageCount(Math.ceil(usersAssets?.total_items / pageSizeCurrent));
  }, [usersAssets, pageSizeCurrent]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    filter.asset = asset;

    setFilterField(filter);
  }, [searchParam]);
  return (
    <>
      <AssetBuyerContent
        onExportUserToAsset={onExportUserToAsset}
        fetchData={fetchData}
        pageCount={pageCount}
        filterValue={filterField}
        usersAssets={usersAssets}
      />
      <RingSpinner isLoading={userToAssetsPending} />
    </>
  );
};

export default AssetsbuyerContainer;
