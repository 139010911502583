import React from 'react';
import { columnUser } from '../common/table/TableColumns/user';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Styles } from '../../css/table';
import { TablePagination } from '../common/table/tablePagination';

const UserTable = ({ pageCount, fetchData }) => {
  const users = useSelector(state => state.users.users);

  return (
    <div className="w-full">
      <Styles>
        <TablePagination
          columns={columnUser}
          data={users?.data || []}
          total_item={users?.total_items}
          fetchData={fetchData}
          pageCount={pageCount}
          className="rounded"
        />
      </Styles>
    </div>
  );
};
UserTable.propTypes = {
  onPageLimitChange: PropTypes.func,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
};
export default UserTable;
