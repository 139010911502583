/* eslint-disable react/prop-types */
import EditIcon from '../../../../assets/svg/Edit.svg';
import { Link } from 'react-router-dom';
export const columnAssets = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Assets ID',
        accessor: '_id',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },

      {
        Header: 'price',
        accessor: 'price.amount',
      },
      {
        Header: 'Media',
        accessor: 'media',
        Cell: ({ row }) => (
          <a
            href={row.values.media.url}
            className="text-[#11B954] body-2"
            target="_blank"
            rel="noreferrer"
          >
            Click
          </a>
        ),
      },
      {
        Header: 'cover',
        accessor: 'cover',
        Cell: ({ row }) => (
          <a
            href={row.values.cover.url}
            className="text-[#11B954] body-2"
            target="_blank"
            rel="noreferrer"
          >
            Click
          </a>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <button
            className={`rounded-full px-5 pt-1.5 pb-1 ${
              row.values.status == 'PAUSED'
                ? 'bg-[#F12B2C]'
                : row.values.status === 'ACTIVE'
                ? 'bg-[#2cad4d]'
                : 'bg-[#167bf6]'
            } text-[#ffffff] uppercase`}
          >
            {row.values.status}
          </button>
        ),
      },
      {
        Header: 'Buyers',
        accessor: 'buyers',
        Cell: ({ row }) => (
          <Link
            to={`/assets/${row.values._id}`}
            className="text-[#11B954] body-2 cursor-pointer"
            target="_self"
          >
            View
          </Link>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: () => {
          return (
            <div className="flex">
              <img
                src={EditIcon}
                alt=""
                className="w-8 p-1 hover:bg-[#dadbe6] bg-[#F4F5FF] rounded-full cursor-pointer"
              />
            </div>
          );
        },
      },
    ],
  },
];
