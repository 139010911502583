import React from 'react';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import {
  tournamentCreateAction,
  tournamentUpdateAction,
} from '../../../redux/action/tournament';
import { formatSingleTournamentDate } from '../../../utils/DateFormat';
import PrizeDistribution from './PrizeDistribution';
import WinnersContainer from './WinnersContainer';
import ThumbnailContainer from './ThumbnailContainer';
import PrizePoolContainer from './PrizePoolContainer';
import NameStartEndDateContainer from './NameStartEndDateContainer';
import LevelsSlotContainer from './LevelsSlotContainer';
import GameTypeContainer from './GameTypeContainer';
import EntryFeesGameContainer from './EntryFeesGameStatusContainer';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  removeEmptyObject,
  removeNullValueObject,
} from '../../../utils/objUtil';

const schema = yup.object().shape({
  name: yup.string().required(),
  start_date: yup.date(),
  end_date: yup.date(),
  prize_pool: yup.object().shape({
    amount: yup.number(),
    currency: yup.string(),
  }),
  entry_fees: yup.object().shape({
    amount: yup.number(),
    currency: yup.string(),
  }),
  slots: yup.number(),
  filled_slot: yup.number(),
  thumbnail: yup.object().shape({
    banner: yup
      .object()
      .shape({
        url: yup.string(),
        type: yup.string(),
      })
      .nullable(),
    banner_sm: yup
      .object()
      .shape({
        url: yup.string(),
        type: yup.string(),
      })
      .nullable(),
    completed_banner: yup
      .object()
      .shape({
        url: yup.string(),
        type: yup.string(),
      })
      .nullable(),
    completed_banner_sm: yup
      .object()
      .shape({
        url: yup.string(),
        type: yup.string(),
      })
      .nullable(),
  }),
  type: yup.string(),
  game: yup.string().required('Game Type is required'),
  status: yup.string().required('Status is required'),
  casual_game: yup.string(),
});

const TournamentForm = ({ tournament, creationType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onNavigateTournamentPage = () => {
    navigate('/tournament');
  };
  const formik = useFormik({
    initialValues: {
      name: tournament?.name || undefined,
      start_date:
        formatSingleTournamentDate(tournament?.start_date) || undefined,
      end_date: formatSingleTournamentDate(tournament?.end_date) || undefined,
      slots: tournament?.slots || undefined,
      filled_slot: tournament?.filled_slot || undefined,
      prize_distribution: tournament?.prize_distribution || undefined,
      prize_pool: tournament?.prize_pool || undefined,
      levels: tournament?.levels || undefined,
      is_paid: tournament?.is_paid || false,
      game: tournament?.game || undefined,
      type: tournament?.type || undefined,
      status: tournament?.status || undefined,
      casual_game: tournament?.casual_game?._id || undefined,
      is_private: tournament?.detail?.is_private || false,
      entry_fees: tournament?.entry_fees || undefined,
      thumbnail: tournament?.thumbnail || {
        banner: undefined,
        banner_sm: undefined,
        completed_banner: undefined,
        completed_banner_sm: undefined,
      },
      winners: tournament?.winners || undefined,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: values => {
      const { start_date, end_date } = values;
      let tournamentObj = {
        ...values,
        start_date: new Date(start_date).getTime(),
        end_date: new Date(end_date).getTime(),
        detail: { is_private: values.is_private === 'true' },
      };
      delete tournamentObj['is_private'];
      tournamentObj = removeNullValueObject(tournamentObj);

      tournamentObj = removeEmptyObject(tournamentObj);

      if (creationType === 'add_tournament') {
        dispatch(
          tournamentCreateAction(tournamentObj, onNavigateTournamentPage)
        );
      } else {
        dispatch(
          tournamentUpdateAction(
            { id: tournament?._id, values: tournamentObj },
            onNavigateTournamentPage
          )
        );
      }
    },
  });
  return (
    <div>
      <div className="overflow-y-scroll w-full">
        <div className="flex justify-center items-center text-black">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="w-full text-center h5-thin">
              {creationType === 'add_tournament'
                ? 'Add Tournament'
                : 'Edit Tournament'}
            </div>
            <NameStartEndDateContainer
              formik={formik}
              formikErrors={formik.errors}
            />
            <LevelsSlotContainer formik={formik} formikErrors={formik.errors} />

            <GameTypeContainer formik={formik} formikErrors={formik.errors} />
            <PrizePoolContainer formik={formik} formikErrors={formik.errors} />
            <EntryFeesGameContainer
              formik={formik}
              formikErrors={formik.errors}
            />
            <ThumbnailContainer formik={formik} formikErrors={formik.errors} />
            <span>
              <hr className="my-3 border-2 border-[#E6E8E6]" />
            </span>
            <PrizeDistribution
              FieldArray={FieldArray}
              formik={formik}
              FormikProvider={FormikProvider}
            />
            <span>
              <hr className="my-3 border-2 border-[#E6E8E6]" />
            </span>
            <WinnersContainer
              FieldArray={FieldArray}
              formik={formik}
              FormikProvider={FormikProvider}
            />
            <div className="m-4 h-auto w-full flex justify-center items-center mt-10 ">
              <div className="mx-40 w-[80%] p-0.5 rounded-lg cursor-pointer">
                <button
                  className="bg-[#3944f7] text-[#ffffff] w-full p-2 md:p-3 rounded-lg px-5 md:px-10 btn-text text-lg"
                  type="submit"
                >
                  {creationType === 'add_tournament' ? 'Add' : 'Update'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

TournamentForm.propTypes = {
  tournament: PropTypes.object,
  creationType: PropTypes.string,
};

export default TournamentForm;
