import React from 'react';
import { assets } from '../../constants/assets_constants';
import { Styles } from '../../css/table';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import { Table } from '../common/table/table';
import { columnAssets } from '../common/table/TableColumns/assets';
import TableHeader from '../common/table/TableHeader';

const AssetsContent = () => {
  const column = React.useMemo(() => columnAssets, []);
  return (
    <div>
      <div className="w-full">
        <HeaderTitle pageTitle={'Assets'} />
        <div className="flex justify-end mx-20 mt-10"></div>
        <div className="shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px] h-[80vh]">
          <TableHeader FileName={'Assets'} />
          {assets && (
            <Styles>
              <Table columns={column} data={assets} className="rounded" />
            </Styles>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetsContent;
