import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { HeaderTitle } from '../../component/common/HeaderTitle/HeaderTitle';
import RingSpinner from '../../component/common/Spinner/RingSpinner';
import { TransactionContent } from '../../component/Transaction/TransactionContent';
import { getAllTransaction } from '../../redux/action/transaction';

const TransactionContainer = () => {
  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const isUserTransactionGetPending = useSelector(
    state => state.transaction.isUserTransactionGetPending
  );
  const isCreateTransactionPending = useSelector(
    state => state.transaction.isCreateTransactionPending
  );
  const isUpdateTransactionPending = useSelector(
    state => state.transaction.isUpdateTransactionPending
  );
  const [searchParam] = useSearchParams();

  const [filterValue, setFilter] = useState(null);
  const dispatch = useDispatch();

  const transactions = useSelector(state => state.transaction?.allTransactions);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(getAllTransaction(pageIndex, pageSize, filterValue));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    if (transactions) {
      setPageCount(Math.ceil(transactions?.total_items / pageSize1));
    }
  }, [transactions, pageSize1]);
  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    if (filter.author) {
      filter.author = {
        _id: filter.author,
      };
    }

    setFilter(filter);
  }, [searchParam]);

  return (
    <>
      <div className="">
        <HeaderTitle pageTitle={'Transactions'} />
        <TransactionContent
          transactions={transactions}
          fetchData={fetchData}
          pageCount={pageCount}
          filterValue={filterValue}
        />
      </div>

      <RingSpinner
        isLoading={
          isUserTransactionGetPending ||
          isCreateTransactionPending ||
          isUpdateTransactionPending
        }
      />
    </>
  );
};

export default TransactionContainer;
