import React from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { CrossIcon } from '../common/svg/Icons';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { inlineStyle } from '../../css/ModalCss/ModalCss';
import {
  nftCreateAction,
  nftGetAllAction,
  nftUpdateAction,
} from '../../redux/action/nft';

const schema = yup.object().shape({
  title: yup.string().required('Nft Title is reuired'),
  description: yup.string().required('Description is required'),
  total_editions: yup
    .number()
    .test('len', 'total_editions is required', val => {
      if (val) return val.toString().length > 0;
    }),
  status: yup.string().required('status is required'),
  currency: yup.string().required('currency is required'),
  creator: yup.string().required('creator is required'),
  price: yup.number().test('len', 'price is required', val => {
    if (val) return val.toString().length > 0;
  }),
});

const NftAddModal = ({ isModal, onToggleModal, nft }) => {
  const creators = useSelector(state => state.creators.creators);
  const dispatch = useDispatch();
  const onUpdate = () => {
    dispatch(nftGetAllAction());
    onToggleModal();
  };
  const onFormSubmit = values => {
    const nftCreate = {
      title: values.title,
      description: values.description,
      creator: values.creator,
      price: {
        currency: 'INR',
        amount: parseInt(values.price),
      },
      media: {
        url: values.url,
        type: values.type,
      },
      status: values.status,
      total_editions: parseInt(values.total_editions),
    };
    if (nft) {
      dispatch(nftUpdateAction(nft._id, nftCreate, onUpdate));
    } else {
      dispatch(nftCreateAction(nftCreate, onUpdate));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: nft?.title || undefined,
      description: nft?.description || undefined,
      total_editions: nft?.total_editions || undefined,
      currency: 'INR',
      creator: nft?.creator || undefined,
      price: nft?.price?.amount || undefined,
      status: nft?.status || 'ACTIVE',
      url: nft?.media?.url || undefined,
      type: nft?.media?.type || 'IMAGE',
    },
    validationSchema: schema,
    onSubmit: values => {
      onFormSubmit(values);
    },
  });

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={onToggleModal}
      ariaHideApp={false}
      style={inlineStyle}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <div className="relative w-full">
          <div
            className="absolute right-0 cursor-pointer p-2 "
            onClick={onToggleModal}
          >
            <CrossIcon />
          </div>

          <div className="flex justify-center items-center text-black">
            <form onSubmit={formik.handleSubmit} className="w-full">
              <div className="w-full text-center h5-thin">
                {nft ? 'EDIT NFT' : 'ADD NFT'}
              </div>
              <div className="w-full flex flex-col justify-center items-center mt-10">
                <div className="w-full flex justify-evenly">
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Title</div>
                    </div>
                    <input
                      type="text"
                      name="title"
                      placeholder="Type Here"
                      defaultValue={formik.values.title}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="title"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.title && formik.touched.title && (
                      <div className="text-red-500 caption my-2">
                        {formik.errors.title}
                      </div>
                    )}
                  </div>
                  {/* price */}
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">PRICE</div>
                    </div>
                    <input
                      type="text"
                      name="price"
                      placeholder="Type Here"
                      defaultValue={formik.values.price}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="price"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.price && formik.touched.price && (
                      <div className="text-red-500 caption my-2">
                        {formik.errors.price}
                      </div>
                    )}
                  </div>
                </div>
                {/* total_editions */}
                <div className="w-full flex justify-evenly mt-4 pt-4">
                  <div className="w-1/3 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">
                        Total Editions
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="Type Here"
                      name="total_editions"
                      defaultValue={formik.values.total_editions}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                      id="total_editions"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.total_editions &&
                      formik.touched.total_editions && (
                        <div className="text-red-500 caption my-2">
                          {formik.errors.total_editions}
                        </div>
                      )}
                  </div>
                  {/* Media_url */}
                  <div className="w-2/3 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Media url</div>
                    </div>
                    <div className="flex">
                      <div className="w-1/5 mr-3">
                        <select
                          type="select"
                          name="type"
                          defaultValue={formik.values.type}
                          onChange={formik.handleChange}
                          className="w-full border-[1px] border-slate-300 text-black rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                          id="type"
                        >
                          <option value="IMAGE">IMAGE</option>
                          <option value="VIDEO">VIDEO</option>
                        </select>
                      </div>
                      <input
                        type="text"
                        name="url"
                        placeholder="Type Here"
                        defaultValue={formik.values.url}
                        onChange={formik.handleChange}
                        className="w-4/5 border-[1px] border-slate-300 rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm"
                        id="imageUrl"
                      />
                    </div>
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.imageUrl && formik.touched.imageUrl && (
                      <div className="text-slate-500 h7-thin">
                        {formik.errors.imageUrl}
                      </div>
                    )}
                  </div>
                </div>
                {/* creator */}
                <div className="w-full flex justify-evenly mt-4 pt-4">
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Creator</div>
                    </div>
                    <select
                      type="select"
                      name="creator"
                      placeholder="Type Here"
                      defaultValue={formik.values.creator}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 text-black rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                      id="creator"
                    >
                      <option value="select value">select</option>
                      {creators &&
                        creators.map((value, index) => {
                          return (
                            <option
                              key={index}
                              value={value._id}
                              id={index}
                              className="text-[#FE6125] h6-thin-sm"
                            >
                              {value.name}
                            </option>
                          );
                        })}
                    </select>
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.creator && formik.touched.creator && (
                      <div className="text-slate-500 h7-thin">
                        {formik.errors.creator}
                      </div>
                    )}
                  </div>
                  {/* status */}
                  <div className="w-1/2 mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">STATUS</div>
                    </div>
                    <select
                      type="select"
                      name="status"
                      placeholder="Type Here"
                      defaultValue={formik.values.status}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 text-black rounded-lg px-4 focus:ring-0 outline-0 body-1 body-1-sm nft-modal  overflow-y-scroll scrollbar-thin scrollbar-thumb-orange-700 scrollbar-track-orange-300 dark:scrollbar-thumb-orange-700 dark:scrollbar-track-gray"
                      id="status"
                    >
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="COMING_SOON">COMING_SOON</option>
                      <option value="SOLD_OUT">SOLD_OUT</option>
                      <option value="PAUSED">PAUSED</option>
                      <option value="BIDDDING">BIDDING</option>
                    </select>
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.status && formik.touched.status && (
                      <div className="text-slate-500 h7-thin">
                        {formik.errors.status}
                      </div>
                    )}
                  </div>
                </div>
                {/* desc */}
                <div className="w-full flex justify-evenly mt-4 pt-4">
                  <div className="w-full mt-2 mx-4">
                    <div className="flex justify-between items-center">
                      <div className="text-slate-500 h7-thin">Description</div>
                    </div>
                    <input
                      type="text"
                      name="description"
                      placeholder="Type Here"
                      defaultValue={formik.values.description}
                      onChange={formik.handleChange}
                      className="w-full border-[1px] border-slate-300 rounded-lg px-4  focus:ring-0 outline-0 body-1 body-1-sm"
                      id="description"
                    />
                    <div className="w-full h-[1px] bg-[#FFFFFF4D]"></div>
                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className="text-red-500 caption my-2">
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="m-4 h-auto w-full flex justify-center items-center mt-10 ">
                <div className="mx-40 w-[80%] p-0.5 bg-gradient-to-r from-[#C537AE] via-[#8217DE] to-[#ff5800] rounded-lg cursor-pointer">
                  <button
                    className="bg-blue-400 hover:bg-blue-500 w-full p-2 md:p-3 rounded-lg px-5 md:px-10  btn-text"
                    type="submit"
                  >
                    {nft ? 'Update' : 'Send'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

NftAddModal.propTypes = {
  isModal: PropTypes.bool,
  onToggleModal: PropTypes.func,
  nft: PropTypes.object,
};
export default NftAddModal;
