import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import BuyersContent from '../../component/buyers/BuyersContent';
import { exportNftBuyersAction } from '../../redux/action/nft';
import { userGetNft } from '../../redux/action/user';

const BuyerContainer = () => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [pageSize1, setPageSize] = useState(1);
  const [filterValue, setFilter] = useState(null);

  let { nftId } = useParams();
  const [searchParam] = useSearchParams();

  const nftBuyer = useSelector(state => state.user.UserNft);

  const onClickExport = () => {
    dispatch(exportNftBuyersAction({ nft: nftId }));
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (filterValue?.is_regex != undefined)
        dispatch(userGetNft(pageIndex, pageSize, filterValue));
      setPageSize(pageSize);
    },
    [filterValue]
  );

  useEffect(() => {
    setPageCount(Math.ceil(nftBuyer?.total_items / pageSize1));
  }, [nftBuyer, pageSize1]);

  useEffect(() => {
    const filter = Object.fromEntries([...searchParam]);
    if (filter.is_regex == null) filter.is_regex = true;
    filter.nft = nftId;
    setFilter(filter);
  }, [searchParam]);

  return (
    <BuyersContent
      nftId={nftId}
      pageCount={pageCount ? pageCount : 1}
      fetchData={fetchData}
      filterValue={filterValue}
      fanClubFilter={setFilter}
      onClickExport={onClickExport}
    />
  );
};

export default BuyerContainer;
