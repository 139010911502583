import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { makeQueryString } from '../../utils/MakeQueryString';
const schema = yup.object().shape({
  user: yup.string(),
});

const WalletFilter = ({ onToggleFilter }) => {
  const [searchParam] = useSearchParams();
  const filter = Object.fromEntries([...searchParam]);
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user: filter?.user || undefined,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values.user === '') {
        values.user = undefined;
      }
      navigate({
        search: makeQueryString(values),
      });
      onToggleFilter();
    },
  });

  const onClearSearchQuery = () => {
    formik.resetform();
    navigate({
      search: '',
    });
    onToggleFilter();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="w-full text-black">
        <div className="flex">
          <input
            type="text"
            name="user"
            defaultValue={formik.values.nft}
            placeholder="user Id"
            onChange={formik.handleChange}
            className="w-full rounded-lg px-2 m-1 focus:ring-0 outline-0 body-1 body-1-sm"
            id="userid"
          />
        </div>

        <div className="w-full flex justify-end items-end">
          <button
            type="reset"
            onClick={onClearSearchQuery}
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Clear
          </button>
          <button
            type="submit"
            className="text-white m-2 cursor-pointer bg-[#3751FF] px-5 py-2 rounded-lg hover:bg-blue-500"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

WalletFilter.propTypes = {
  onToggleFilter: PropTypes.func,
};
export default WalletFilter;
