import { apolloClient } from '../../../service/apolloClient';
import {
  GET_PLANS_GQL,
  GET_PLAN_BY_ID_GQL,
  UPDATE_PLAN_GQL,
  CREATE_PLAN_GQL,
} from '../../query/plan';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import {
  GET_PLANS_PENDING,
  PLANS,
  GET_PLAN_PENDING,
  UPDATE_PLAN_PENDING,
  CREATE_PLAN_PENDING,
} from './type';

export const plansGetAction = (value, page, limit) => {
  return dispatch => {
    dispatch(reduxAction(GET_PLANS_PENDING, true));
    apolloClient
      .query({
        query: GET_PLANS_GQL,
        variables: { input: { _id: value._id, name: value.name }, page, limit },
      })
      .then(({ data }) => {
        const { getPlans: res } = data;
        dispatch(reduxAction(PLANS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_PLANS_PENDING, false));
      });
  };
};

export const planGetAction = (planId, cb) => {
  return dispatch => {
    dispatch(reduxAction(GET_PLAN_PENDING, true));
    apolloClient
      .query({
        query: GET_PLAN_BY_ID_GQL,
        variables: { id: planId },
      })
      .then(({ data }) => {
        const { getPlan: res } = data;
        dispatch(reduxAction(GET_PLAN_PENDING, res));
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_PLAN_PENDING, false));
      });
  };
};

export const planUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_PLAN_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_PLAN_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: value } })
      .then(({ data }) => {
        const { updatePlan: res } = data;
        if (!res) return;
        toastAction.success('Plan Updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_PLAN_PENDING, false)));
  };
};

export const planCreateAction = (value, cb) => {
  const mutation = CREATE_PLAN_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_PLAN_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createPlan: res } = data;
        if (!res) return;
        toastAction.success('Plan created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_PLAN_PENDING, false)));
  };
};
