export const TournamentType = [
  'BGMI',
  'FREE_FIRE',
  'VALORANT',
  'BOTTLE_FLIP',
  '2048',
  'FLAPPY_BIRD',
  'DOODLE_JUMP',
  'KNIFE_NINJA',
  'THE_HILL',
  'STACK_FALL',
  'STACK',
  'DROP_BOX',
  'COLOR_JUMP',
  'CRAZY_BOUNCING',
  'BASKETBALL',
  'ZIG_ZAG',
];
