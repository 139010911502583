import { apolloClient } from '../../../service/apolloClient';
import {
  CREATE_GAME_ASSET_GQL,
  GET_GAME_ASSETS_GQL,
  GET_GAME_ASSET_GQL,
  UPDATE_GAME_ASSET_GQL,
} from '../../query/gameAsset';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import {
  CREATE_GAME_ASSET_PENDING,
  GAME_ASSETS,
  GET_GAME_ASSETS_PENDING,
  GET_GAME_ASSET_PENDING,
  UPDATE_GAME_ASSET_PENDING,
} from './type';

export const gameAssetsGetAction = (value, page, limit) => {
  const query = GET_GAME_ASSETS_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_ASSETS_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { input: { ...value, platform: 'ADMIN' }, page, limit },
      })
      .then(({ data }) => {
        const { getGameAssets: res } = data;
        dispatch(reduxAction(GAME_ASSETS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_ASSETS_PENDING, false)));
  };
};
export const gameAssetGetAction = (configId, cb) => {
  const query = GET_GAME_ASSET_GQL;
  return dispatch => {
    dispatch(reduxAction(GET_GAME_ASSET_PENDING, true));
    apolloClient
      .query({
        query,
        variables: { id: configId },
      })
      .then(({ data }) => {
        const { getGameAsset: res } = data;
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(GET_GAME_ASSET_PENDING, false)));
  };
};

export const gameAssetCreateAction = (value, cb) => {
  const mutation = CREATE_GAME_ASSET_GQL;
  return dispatch => {
    dispatch(reduxAction(CREATE_GAME_ASSET_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(({ data }) => {
        const { createGameAsset: res } = data;
        if (!res) return;
        toastAction.success('Asset created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_GAME_ASSET_PENDING, false)));
  };
};
export const gameAssetUpdateAction = (id, data, cb) => {
  const mutation = UPDATE_GAME_ASSET_GQL;
  return dispatch => {
    dispatch(reduxAction(UPDATE_GAME_ASSET_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: data } })
      .then(({ data }) => {
        const { updateGameAsset: res } = data;
        if (!res) return;
        toastAction.success('Asset updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_GAME_ASSET_PENDING, false)));
  };
};
