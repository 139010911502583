import {
  TOURNAMENTS_INFO,
  TOURNAMENTS_PENDING,
  TOURNAMENT_CREATE_INFO,
  TOURNAMENT_CREATE_PENDING,
  SINGLE_TOURNAMENT_INFO_PENDING,
  SINGLE_TOURNAMENT_INFO,
  TOURNAMENT_UPDATE,
  TOURNAMENT_UPDATE_PENDING,
  CLEAR_SINGLE_TOURNAMENT,
  TOURNAMENT_GAMES,
  TOURNAMENT_GAMES_PENDING,
  USER_TO_TOURNAMENT,
  USER_TO_TOURNAMENT_PENDING,
  EXPORT_USER_TO_TOURNAMENT_GQL_PENDING,
} from '../action/tournament/type';

const initialPendingState = {
  isTournamentsGetPending: false,
  isTournamentGetGamePending: false,
  isUserToTournamentsPending: false,
  isExportUserToTournamentPending: false,
};

const DEFAULT_STATE = {
  ...initialPendingState,
  tournaments: null,
  tournament: null,
  games: null,
  userToTournaments: null,
};

export const tournamentsReducer = (
  state = DEFAULT_STATE,
  { type, payload }
) => {
  switch (type) {
    case TOURNAMENTS_INFO:
      return {
        ...state,
        tournaments: payload,
      };
    case USER_TO_TOURNAMENT:
      return {
        ...state,
        userToTournaments: payload,
      };
    case USER_TO_TOURNAMENT_PENDING:
      return {
        ...state,
        isUserToTournamentsPending: payload,
      };
    case EXPORT_USER_TO_TOURNAMENT_GQL_PENDING:
      return {
        ...state,
        isExportUserToTournamentPending: payload,
      };
    case TOURNAMENT_GAMES:
      return {
        ...state,
        games: payload,
      };
    case TOURNAMENT_GAMES_PENDING:
      return {
        ...state,
        isTournamentGetGamePending: payload,
      };
    case TOURNAMENTS_PENDING:
      return {
        ...state,
        isTournamentsGetPending: payload,
      };

    case TOURNAMENT_CREATE_INFO:
      return {
        ...state,
        isTournamentsGetPending: payload,
      };

    case TOURNAMENT_CREATE_PENDING:
      return {
        ...state,
        isTournamentsGetPending: payload,
      };

    case SINGLE_TOURNAMENT_INFO_PENDING:
      return {
        ...state,
        isTournamentsGetPending: payload,
      };

    case SINGLE_TOURNAMENT_INFO:
      return {
        ...state,
        tournament: payload,
      };

    case CLEAR_SINGLE_TOURNAMENT:
      return {
        ...state,
        tournament: null,
      };

    case TOURNAMENT_UPDATE:
      return {
        ...state,
        isTournamentsGetPending: payload,
      };

    case TOURNAMENT_UPDATE_PENDING:
      return {
        ...state,
        isTournamentsGetPending: payload,
      };

    default:
      return state;
  }
};
