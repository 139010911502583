import React from 'react';
import Modal from 'react-modal';
import { inlineStyle } from '../../css/ModalCss/ModalCss';
import MobileForm from './MobileForm';
import PropType from 'prop-types';

export const LoginModal = ({ isModal, onVerifyAdmin }) => {
  return (
    <Modal
      isOpen={isModal}
      ariaHideApp={false}
      style={inlineStyle}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <div className="relative text-black">
          <div className="flex justify-center items-center flex-col">
            <MobileForm onVerifyAdmin={onVerifyAdmin} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

LoginModal.propTypes = {
  isModal: PropType.bool,
  onToggleModal: PropType.func,
  onVerifyAdmin: PropType.func,
  onSendOtp: PropType.func,
};
