import { gql } from '@apollo/client';

export const GET_GAME_ENGAGEMENT_GQL = gql`
  query getGameEngagement($input: GameAnalyticsQueryInput) {
    getGameEngagement(input: $input) {
      type
      size
      time
    }
  }
`;

export const GET_GAMES_ANALYTICS_GQL = gql`
  query getGamesAnalytics($input: GamesAnalyticsQueryInput) {
    getGamesAnalytics(input: $input) {
      type
      size
      time
    }
  }
`;
