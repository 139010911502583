import { gql } from '@apollo/client';

export const GET_USER_TO_PLANS = gql`
  query getUserToPlans($input: UserToPlansQueryInput, $page: Int, $limit: Int) {
    getUserToPlans(input: $input, page: $page, limit: $limit) {
      data {
        _id
        plan
        user
        uuid
        status
        is_active
        createdAt
        updatedAt
        detail {
          invoice_ids
        }
      }
      page
      limit
      total_items
    }
  }
`;
export const GET_USER_TO_PLAN = gql`
  query GetUserToPlan($id: ID!) {
    getUserToPlan(id: $id) {
      _id
      plan
      user
      uuid
      status
      is_active
      detail {
        invoice_ids
      }
    }
  }
`;
export const UPDATE_USER_PLAN = gql`
  mutation updateUserToPlan($id: ID!, $input: UserToPlanUpdateInput!) {
    updateUserToPlan(id: $id, input: $input) {
      _id
    }
  }
`;

export const CREATE_USER_PLAN = gql`
  mutation createUserToPlan($input: UserToPlanCreateInput!) {
    createUserToPlan(input: $input) {
      _id
    }
  }
`;
