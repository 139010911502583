import { gql } from '@apollo/client';

export const GET_USER_GQL = gql`
  query getUser {
    getUser {
      _id
      name
      username
      thumbnail
      country_code
      mobile_number
      is_verified
      email
    }
  }
`;
export const GET_USER_BY_ID_GQL = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      _id
      name
      username
      thumbnail
      country_code
      mobile_number
      is_verified
      email
      type
      otp
    }
  }
`;
export const UPDATE_USER_GQL = gql`
  mutation updateUser($input: UpdateAdminUserInput, $id: ID!) {
    updateUser(input: $input, id: $id) {
      _id
      name
      username
      thumbnail
      country_code
      mobile_number
      is_verified
      email
    }
  }
`;
export const LOGIN_GQL = gql`
  mutation login($input: loginInput) {
    login(input: $input) {
      country_code
      mobile_number
    }
  }
`;

export const VERIFY_ADMIN_GQL = gql`
  mutation verifyAdminOtp($input: verifyInput!) {
    verifyAdminOtp(input: $input) {
      _id
      name
      username
      thumbnail
      country_code
      mobile_number
      is_verified
      email
      token
    }
  }
`;

export const DEFAULT_PROFILE_PICS_GQL = gql`
  query getDefaultPP {
    getDefaultProfilePics
  }
`;

export const GET_NFT_BY_USER = gql`
  query getUserToNfts($input: UserToNftQueryInput, $page: Int, $limit: Int) {
    getUserToNfts(input: $input, page: $page, limit: $limit) {
      data {
        _id
        nft {
          _id
          title
        }
        user {
          _id
          email
          username
          mobile_number
        }
      }
      total_items
      page
      limit
    }
  }
`;

export const GET_TRANSACTION_BY_USER = gql`
  query getWallets($input: WalletQueryInput) {
    getWallets(input: $input) {
      _id
      user
      balance {
        currency
        amount
      }
      earnings {
        currency
        amount
      }
      is_active
      createdAt
    }
  }
`;

export const GET_DISCORD_BY_USER = gql`
  query getSocialLinks($input: SocialLinkInputQuery) {
    getSocialLinks(input: $input) {
      data {
        _id
        user
        discord {
          discord_username
          is_linked
          discord_userid
        }
      }
      total_items
      page
      limit
    }
  }
`;
export const GET_USER_WALLET_GQL = gql`
  query GetUserWallet {
    getUserWallet {
      _id
      balance {
        currency
        amount
      }
      user
      earnings {
        currency
        amount
      }
      is_active
      createdAt
    }
  }
`;
export const GET_WALLETS_GQL = gql`
  query GetWallets($input: WalletQueryInput, $page: Int, $limit: Int) {
    getWallets(input: $input, page: $page, limit: $limit) {
      data {
        _id
        balance {
          currency
          amount
        }
        user {
          name
          _id
          username
          thumbnail
          mobile_number
        }
        deposit {
          amount
          currency
        }
        crypto {
          balance {
            amount
            currency
          }
          earnings {
            currency
            amount
          }
          address {
            address
          }
        }
        earnings {
          currency
          amount
        }
        is_active
        createdAt
      }
      total_items
      page
      limit
    }
  }
`;

export const SEND_USER_REWARD_GQL = gql`
  mutation sendUserReward($input: SendUserRewardInput!) {
    sendUserReward(input: $input) {
      amount
      _id
    }
  }
`;
