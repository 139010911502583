import React, { useState } from 'react';
import PropType from 'prop-types';
import { Styles } from '../../css/table';
import { TransactionColumn } from '../common/table/TableColumns/transaction';
import AddTransModal from './AddTransModal';
import TableHeader from '../common/table/TableHeader';
import TransactionFilter from './TransactionFilter';
import { TablePagination } from '../common/table/tablePagination';

export const TransactionContent = ({
  transactions,
  addButtonState,
  featureState,
  userId,
  filterValue,
  setPageLimit,
  fetchData,
  pageCount,
}) => {
  const [isModal, setModal] = useState(false);

  const onToggleModal = () => {
    setModal(!isModal);
  };

  const [filter, setFilter] = useState(false);
  const onToggleFilter = () => {
    setFilter(!filter);
  };

  return (
    <div className="w-full ">
      <div className="flex justify-end mx-20 mt-2"></div>
      <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]  h-[85vh]">
        <TableHeader
          FileName={'Transactions'}
          modalOpenfunc={onToggleModal}
          onToggleFilter={onToggleFilter}
          addButtonEnable={addButtonState}
          featureEnable={featureState}
          setPageLimit={setPageLimit}
        />
        <div
          className={`absolute w-[30rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
            filter ? 'block' : 'hidden'
          }`}
        >
          <TransactionFilter
            transactions={transactions}
            onToggleFilter={onToggleFilter}
            userId={userId}
          />
        </div>

        <Styles>
          <TablePagination
            columns={TransactionColumn}
            data={transactions?.data || []}
            total_item={transactions?.total_items}
            fetchData={fetchData}
            pageCount={pageCount}
            filterValue={filterValue}
            className="rounded"
          />
          <div className="w-full flex justify-center items-center  cursor-pointer"></div>
        </Styles>
      </div>
      {isModal && (
        <AddTransModal isModal={isModal} onToggleModal={onToggleModal} />
      )}
    </div>
  );
};

TransactionContent.propTypes = {
  transactions: PropType.object,
  FilterTransaction: PropType.func,
  featureState: PropType.bool,
  addButtonState: PropType.bool,
  userId: PropType.string,
  setPageLimit: PropType.func,
  filterValue: PropType.object,
  fetchData: PropType.func,
  pageCount: PropType.number,
};
