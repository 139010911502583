import { apolloClient } from '../../../service/apolloClient';
import {
  GET_USER_TO_PLANS,
  GET_USER_TO_PLAN,
  UPDATE_USER_PLAN,
  CREATE_USER_PLAN,
} from '../../query/userPlans';
import { reduxAction } from '../base';
import { toastAction } from '../toastAction';
import {
  GET_USER_PLANS_PENDING,
  USER_PLANS,
  GET_USER_PLAN_PENDING,
  USER_PLAN,
  UPDATE_USER_PLAN_PENDING,
  CREATE_USER_PLAN_PENDING,
} from './type';

export const userPlansGetAction = (input, page, limit) => {
  return dispatch => {
    dispatch(reduxAction(GET_USER_PLANS_PENDING, true));
    apolloClient
      .query({
        query: GET_USER_TO_PLANS,
        variables: { input, page, limit },
      })
      .then(({ data }) => {
        const { getUserToPlans: res } = data;
        dispatch(reduxAction(USER_PLANS, res));
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_USER_PLANS_PENDING, false));
      });
  };
};
export const userPlanGetAction = (planId, cb) => {
  return dispatch => {
    dispatch(reduxAction(GET_USER_PLAN_PENDING, true));
    apolloClient
      .query({
        query: GET_USER_TO_PLAN,
        variables: { id: planId },
      })
      .then(({ data }) => {
        const { getUserToPlan: res } = data;
        dispatch(reduxAction(USER_PLAN, res));
        if (cb) cb(res);
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => {
        dispatch(reduxAction(GET_USER_PLAN_PENDING, false));
      });
  };
};

export const userPlanUpdateAction = (id, value, cb) => {
  const mutation = UPDATE_USER_PLAN;
  const sanitizedInput = {
    plan: value.plan,
    user: value.user,
    uuid: value.uuid,
    status: value.status,
    detail: value.detail,
  };
  return dispatch => {
    dispatch(reduxAction(UPDATE_USER_PLAN_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { id: id, input: sanitizedInput } })
      .then(() => {
        //const { updatePlan: res } = data;
        toastAction.success('User Plan Updated successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(UPDATE_USER_PLAN_PENDING, false)));
  };
};

export const userPlanCreateAction = (value, cb) => {
  const mutation = CREATE_USER_PLAN;
  return dispatch => {
    dispatch(reduxAction(CREATE_USER_PLAN_PENDING, true));
    apolloClient
      .mutate({ mutation, variables: { input: value } })
      .then(() => {
        //const { createPlan: res } = data;
        toastAction.success('User Plan created successfully');
        if (cb) cb();
      })
      .catch(err => {
        toastAction.error(err);
      })
      .finally(() => dispatch(reduxAction(CREATE_USER_PLAN_PENDING, false)));
  };
};
