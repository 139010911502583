import { gql } from '@apollo/client';

export const GET_ALL_SOCIALS = gql`
  query GetSocialLinks {
    getSocialLinks {
      data {
        _id
        user
        discord {
          discord_userid
          discord_username
        }
      }
      total_items
      page
      limit
    }
  }
`;
