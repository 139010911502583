import React from 'react';
import { useTable, useRowSelect } from 'react-table';
import PropType from 'prop-types';

export function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [...columns]);
      }
    );

  return (
    <>
      <div className="">
        <table {...getTableProps()} className="w-[75vw]">
          <thead>
            {headerGroups.map((headerGroups, idx) => (
              <tr
                className="w-full"
                key={idx}
                {...headerGroups.getHeaderGroupProps()}
              >
                {idx != 0 && (
                  <th
                    className={`
                    text-[#a0a2b4] body-2 text-center
                  `}
                  >
                    No.
                  </th>
                )}
                {headerGroups.headers.map((column, idx) => (
                  <th
                    key={idx}
                    {...column.getHeaderProps()}
                    className={`
                    text-[#a0a2b4] body-2 text-center
                  `}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="relative overflow-y">
            {rows.map((row, idx) => {
              prepareRow(row);
              return (
                <tr
                  key={idx}
                  {...row.getRowProps()}
                  className="border-t-2 border-b-2 w-full text-center"
                >
                  <td className={'text-start body-2'}>{idx + 1}</td>
                  {row.cells.map((cell, idx) => {
                    return (
                      <td
                        key={idx}
                        {...cell.getCellProps()}
                        className={`
                        text-start
                        ${cell.column.id === 'status' && 'text-center'}
                        body-2
                      `}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

Table.propTypes = {
  columns: PropType.array,
  data: PropType.array,
  modalOpenfunc: PropType.func,
  creatorSetFunc: PropType.func,
};
