import PropTypes from 'prop-types';
import { useState } from 'react';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import CreateGameModal from '../common/Modal/GameModal/CreateGameModal';
import TableHeader from '../common/table/TableHeader';
import GameFilters from './GameFilters';
import GamesTable from './GamesTable';

const GamesContent = ({
  games,
  fetchData,
  onCreateGame,
  pageCount,
  addBtnEnable = true,
  headerEnable = true,
}) => {
  const [isFilter, setFilter] = useState(false);
  const [isCreateModal, setCreateModal] = useState(false);

  const onToggleFilter = () => {
    setFilter(!isFilter);
  };

  const onToggleCreateModal = () => {
    setCreateModal(!isCreateModal);
  };
  return (
    <div className="w-full">
      {headerEnable && <HeaderTitle pageTitle={'Games'} />}
      <div className="mt-10">
        <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader
            FileName="Games"
            addButtonEnable={addBtnEnable}
            onToggleFilter={onToggleFilter}
            modalOpenfunc={onToggleCreateModal}
          />
          <div
            className={`absolute w-[23rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
              isFilter ? 'block' : 'hidden'
            }`}
          >
            <GameFilters onToggleFilter={onToggleFilter} />
          </div>

          <GamesTable
            games={games}
            fetchData={fetchData}
            pageCount={pageCount}
          />
        </div>
      </div>
      {isCreateModal && (
        <CreateGameModal
          isModalOpen={isCreateModal}
          onToggleModal={onToggleCreateModal}
          onSubmit={onCreateGame}
        />
      )}
    </div>
  );
};
GamesContent.propTypes = {
  games: PropTypes.object,
  fetchData: PropTypes.func,
  onCreateGame: PropTypes.func,
  pageCount: PropTypes.number,
  addBtnEnable: PropTypes.bool,
  headerEnable: PropTypes.bool,
};
export default GamesContent;
