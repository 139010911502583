import { gql } from '@apollo/client';

export const GET_ALL_TOURNAMENTS = gql`
  query GetTournaments($page: Int, $limit: Int, $input: TournamentQueryInput) {
    getTournaments(page: $page, limit: $limit, input: $input) {
      page
      limit
      total_items
      data {
        _id
        name
        start_date
        end_date
        slots
        filled_slot
        levels
        is_paid
        status
      }
    }
  }
`;

export const GET_USER_TO_TOURNAMENTS_GQL = gql`
  query GetUserToTournaments(
    $page: Int
    $limit: Int
    $input: UserToTournamentQueryInput
  ) {
    getUserToTournaments(page: $page, limit: $limit, input: $input) {
      data {
        _id
        game
        mobile_number
        score
        tournament
        user
        createdAt
        updatedAt
      }
      limit
      page
      total_items
    }
  }
`;
export const GET_GAME_GQL = gql`
  query getGames {
    getGames {
      _id
      name
      description
      path
      banner {
        url
        type
        __typename
      }
      type
      __typename
    }
  }
`;

export const GET_SINGLE_TOURNAMENT = gql`
  query GetTournament($id: ID!) {
    getTournament(id: $id) {
      _id
      name
      start_date
      end_date
      slots
      filled_slot
      levels
      is_paid
      type
      entry_fees {
        currency
        amount
      }
      createdAt
      game
      status
      is_active
      winners {
        thumbnail {
          url
          type
        }
        team_name
        rank
        reward {
          currency
          amount
        }
      }
      prize_pool {
        amount
        currency
        text
      }
      thumbnail {
        banner {
          url
          type
        }
        banner_sm {
          url
          type
        }
        completed_banner {
          url
          type
        }
        completed_banner_sm {
          url
          type
        }
      }
      prize_distribution {
        reward {
          currency
          amount
        }
        rank
      }
      casual_game {
        _id
        name
      }
      detail {
        is_private
      }
    }
  }
`;

export const UPDATE_TOURNAMENT = gql`
  mutation UpdateTournament($id: ID!, $input: TournamentUpdateInput) {
    updateTournament(id: $id, input: $input) {
      name
      start_date
      end_date
      slots
      filled_slot
      levels
      is_paid
      type
      entry_fees {
        currency
        amount
      }
      prize_pool {
        amount
        currency
        text
      }
      game
      status
      winners {
        thumbnail {
          url
          type
        }
        team_name
        rank
        reward {
          currency
          amount
        }
      }
      thumbnail {
        banner {
          url
          type
        }
        banner_sm {
          url
          type
        }
        completed_banner {
          url
          type
        }
        completed_banner_sm {
          url
          type
        }
      }
      prize_distribution {
        reward {
          currency
          amount
        }
        rank
      }
    }
  }
`;

export const ADD_TOURNAMENT = gql`
  mutation CreateTournament($input: TournamentCreateInput!) {
    createTournament(input: $input) {
      name
      start_date
      end_date
      slots
      filled_slot
      levels
      is_paid
      type
      entry_fees {
        currency
        amount
      }
      prize_pool {
        amount
        currency
        text
      }
      prize_distribution {
        reward {
          currency
          amount
        }
        rank
      }
      thumbnail {
        banner {
          url
          type
        }
        banner_sm {
          url
          type
        }
        completed_banner {
          url
          type
        }
        completed_banner_sm {
          url
          type
        }
      }
      game
      status
      winners {
        thumbnail {
          url
          type
        }
        team_name
        rank
        reward {
          currency
          amount
        }
      }
    }
  }
`;

export const EXPORT_USER_TO_TOURNAMENT_GQL = gql`
  query ExportUserToTournament($input: ExportUserToTournamanetQueryInput) {
    exportUserToTournament(input: $input) {
      type
      data
    }
  }
`;
