import PropTypes from 'prop-types';
import { useState } from 'react';
import { HeaderTitle } from '../common/HeaderTitle/HeaderTitle';
import CreateUserPlanModal from '../common/Modal/UserPlanModal/CreateUserPlanModal';
import TableHeader from '../common/table/TableHeader';
import UserPlanFliters from './UserPlanFilter';
import UserPlansTable from './UserPlansTable';

const UserPlansContent = ({ fetchData, onCreateGame, pageCount }) => {
  const [isFilter, setFilter] = useState(false);
  const [isCreateModal, setCreateModal] = useState(false);

  const onToggleFilter = () => {
    setFilter(!isFilter);
  };

  const onToggleCreateModal = () => {
    setCreateModal(!isCreateModal);
  };
  return (
    <div className="w-full">
      <HeaderTitle pageTitle={'User Plans'} />
      <div className="mt-10">
        <div className="relative shadow-lg p-5 bg-[#ffffff] rounded-xl w-[97%] mx-auto border-[1px]">
          <TableHeader
            FileName="User Plans"
            addButtonEnable={true}
            onToggleFilter={onToggleFilter}
            modalOpenfunc={onToggleCreateModal}
          />
          <div
            className={`absolute w-[23rem] mt-2 p-2 bg-slate-400 rounded-md right-0 z-10 ${
              isFilter ? 'block' : 'hidden'
            }`}
          >
            <UserPlanFliters onToggleFilter={onToggleFilter} />
          </div>
          <UserPlansTable fetchData={fetchData} pageCount={pageCount} />
        </div>
      </div>
      {isCreateModal && (
        <CreateUserPlanModal
          isModalOpen={isCreateModal}
          onToggleModal={onToggleCreateModal}
          onSubmit={onCreateGame}
        />
      )}
    </div>
  );
};
UserPlansContent.propTypes = {
  fetchData: PropTypes.func,
  onCreateGame: PropTypes.func,
  pageCount: PropTypes.number,
};
export default UserPlansContent;
