import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Styles } from '../../css/table';
import { TablePagination } from '../common/table/tablePagination';
import { planColumn } from '../common/table/TableColumns/plan';

const PlansTable = ({ pageCount, fetchData }) => {
  const { plans } = useSelector(state => state.plans);

  return (
    <div className="w-full h-full">
      <Styles>
        <TablePagination
          columns={planColumn}
          data={plans?.data || []}
          total_item={plans?.total_items}
          fetchData={fetchData}
          pageCount={pageCount}
          className="rounded"
        />
      </Styles>
    </div>
  );
};
PlansTable.propTypes = {
  onPageLimitChange: PropTypes.func,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
};
export default PlansTable;
