import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { routeType } from '../constants/route_constants';
import AssetsBuyerView from '../view/AssetsBuyerView/AssetsBuyerView';
import AssetsView from '../view/AssetsView/AssetsView';
import BuyerView from '../view/BuyerView/BuyerView';
import CreatorView from '../view/CreatorView/CreatorView';
import DiscordView from '../view/DiscordView/DiscordView';
import { NftUtilityView } from '../view/NftUtility/NftUtilityView';
import NFTView from '../view/NFTView/NFTView';
import SocialView from '../view/SocialView/SocialView';
import TransactionView from '../view/TransactionView/TransactionView';
import UserDetailView from '../view/UserDetailView.js/UserDetailView';
import UserView from '../view/UserView/UserView';
import Navbar from '../component/common/LandingPage/Navbar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TournamentView from '../view/TournamentView/TournamentView';
import TournamentForm from '../component/tournaments/TournamentForm/TournamentFormContainer';
import WalletView from '../view/WalletView/WalletView';
import DashboardView from '../view/DashboardView/DashboardView';
import GamesView from '../view/GamesView/GamesView';
import GameAnalyticsView from '../view/GameAnalytics/GameAnalyticsView';
import GameConfigView from '../view/GameConfig/GameConfigView';
import GameAssetsView from '../view/GameAssets/GameAssetsView';
import PlansView from '../view/PlanView/PlansView';
import UserPlansView from '../view/UserPlanView/UserPlansView';

const ProtectedRoute = ({ type }) => {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isUserGetPending = useSelector(state => state.user.isUserGetPending);
  const navigate = useNavigate();
  const onCheckUserLoggedIn = () => {
    if (isUserGetPending === false && isAuthenticated === null) {
      navigate('/');
    }
  };
  const onRenderComponent = () => {
    switch (type) {
      case routeType.CREATOR_VIEW:
        return <CreatorView />;
      case routeType.NFT_VIEW:
        return <NFTView />;
      case routeType.TOURNAMENT_VIEW:
        return <TournamentView />;
      case routeType.TOURNAMENT_FORM:
        return <TournamentForm />;
      case routeType.NFT_UTILITY_VIEW:
        return <NftUtilityView />;
      case routeType.ASSET_VIEW:
        return <AssetsView />;
      case routeType.TRANSACTION_VIEW:
        return <TransactionView />;
      case routeType.DISCORD_ROLE_VIEW:
        return <DiscordView />;
      case routeType.USER_VIEW:
        return <UserView />;
      case routeType.USER_DETAIL_VIEW:
        return <UserDetailView />;
      case routeType.NFT_BUYER_VIEW:
        return <BuyerView />;
      case routeType.ASSET_BUYER_VIEW:
        return <AssetsBuyerView />;
      case routeType.SOCIAL_VIEW:
        return <SocialView />;
      case routeType.WALLET_VIEW:
        return <WalletView />;
      case routeType.DASHBOARD_VIEW:
        return <DashboardView />;
      case routeType.GAME_VIEW:
        return <GamesView />;
      case routeType.GAME_ANALYTICS_VIEW:
        return <GameAnalyticsView />;
      case routeType.GAME_CONFIG:
        return <GameConfigView />;
      case routeType.GAME_ASSETS:
        return <GameAssetsView />;
      case routeType.PLAN_VIEW:
        return <PlansView />;
      case routeType.USER_PLAN_VIEW:
        return <UserPlansView />;
    }
  };
  useEffect(() => {
    onCheckUserLoggedIn();
  }, [isUserGetPending]);
  return (
    <div>
      <div className="w-[100vw] flex">
        <Navbar />
        <div className="w-full">{onRenderComponent()}</div>
      </div>
    </div>
  );
};
ProtectedRoute.propTypes = {
  type: PropTypes.string,
};
export default ProtectedRoute;
